import React from 'react';
import {ExamType, TopicsItemFragment, useCategoriesQuery, useCreateOrUpdateTopicMutation} from '@generated/graphql';
import CustomModal from 'components/modals/CustomModal';
import {Form, FormAutocomplete, FormTextField} from 'components/form';
import {useForm} from 'react-hook-form';
import {Stack} from '@mui/material';
import {array, InferType, object, string} from 'yup';
import {NotificationType, useNotify} from 'hooks';
import {yupResolver} from '@hookform/resolvers/yup';

const schema = object({
    name: string().required(' '),
    categoryIds: array().default([]).min(1, ' ')
});

export const CreateOrEditTopicModal = ({handleClose, topic}: {handleClose: () => void; topic: TopicsItemFragment}) => {
    const {data: categories} = useCategoriesQuery();
    const [createOrUpdateTopic, {loading}] = useCreateOrUpdateTopicMutation();
    const notify = useNotify();
    const categoryOptions = categories?.categories?.items || [];

    const form = useForm({
        defaultValues: schema.cast(
            {
                ...topic,
                categoryIds: topic?.categories.map((i) => i.id)
            },
            {stripUnknown: true}
        ),
        resolver: yupResolver(schema)
    });
    const {handleSubmit} = form;

    const onSubmit = (data: InferType<typeof schema>) => {
        createOrUpdateTopic({
            variables: {
                topicModel: {
                    ...data,
                    questions: [],
                    testType: topic.testType,
                    type: ExamType.Psychology,
                    id: topic.id
                }
            }
        })
            .then(() => {
                notify({type: NotificationType.SUCCESS});
                handleClose();
            })
            .catch(() => {
                notify({type: NotificationType.ERROR});
            });
    };

    return (
        <CustomModal
            title={topic ? 'Редактирование темы' : 'Добавление темы'}
            loading={loading}
            open={!!topic}
            handleClose={() => {
                handleClose();
            }}
            handleSubmit={handleSubmit(onSubmit)}>
            <Form form={form}>
                <Stack spacing={2}>
                    <FormTextField name={'name'} label={'Название темы'} disabled />
                    <FormAutocomplete
                        label={'Категории аттестуемых лиц'}
                        name={'categoryIds'}
                        optionText={'name'}
                        multiple
                        options={categoryOptions}
                    />
                </Stack>
            </Form>
        </CustomModal>
    );
};
