import React, {PropsWithChildren} from 'react';
import {alpha, Box, colors, SxProps} from '@mui/material';

export const Label = ({color = colors.grey[500], children, sx}: PropsWithChildren<{color: string; sx?: SxProps}>) => {
    return (
        <Box
            sx={{
                paddingX: 2,
                paddingY: 1,
                borderRadius: '5px',
                fontWeight: 500,
                display: 'inline-flex',
                color: color,
                alignSelf: 'flex-start',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: alpha(color, 0.08),
                ...sx
            }}>
            {children}
        </Box>
    );
};
