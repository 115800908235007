import {Checkbox, FormControlLabel, FormControlLabelProps} from '@mui/material';
import {useController, UseControllerProps} from 'react-hook-form';
import React from 'react';

export const FormCheckbox = ({
    name,
    label = '',
    sx,
    disabled,
    rules
}: UseControllerProps & Partial<FormControlLabelProps>) => {
    const {field} = useController({name, rules, defaultValue: false});
    return (
        <FormControlLabel
            onChange={(e: any) => field.onChange(e.target.checked)}
            checked={field.value}
            sx={{...sx, ml: '-11px !important'}}
            control={<Checkbox />}
            label={label}
            disabled={disabled}
        />
    );
};
