import React, {useState} from 'react';
import {Button, Card, Stack, Typography} from '@mui/material';
import {tableConfig} from './config';
import TableLayout from 'components/table/TableLayout';
import AddParticipantModal from './components/AddParticipantModal';
import {useExamContext} from '../../Exam';
import {NotificationType, useModal, useNotify} from 'hooks';
import {ViewResultsModal} from './components/ViewResultsModal';
import {useAuthContext} from 'context/authContext';
import {
    ExamType,
    PermissionName,
    useExportParticipantExamsBlanksMutation,
    useExportParticipantExamsProtocolsMutation
} from '@generated/graphql';
import {LoadingButton} from '@mui/lab';
import {saveAs} from 'file-saver';
import {ExamTypeShortMap} from 'constants/index';

const Participants = () => {
    const {exam} = useExamContext();
    const notify = useNotify();
    const [showPassword, setShowPassword] = useState(true);
    const {open: openAddParticipantsModal, handleToggle: handleToggleAddParticipantsModal} = useModal();
    const [participantExamId, setParticipantExamId] = useState<string | null>(null);
    const [openResultsModal, setOpenResultsModal] = useState<boolean>(false);
    const [exportProtocols, {loading: exportProtocolsLoading}] = useExportParticipantExamsProtocolsMutation();
    const [exportBlanks, {loading: exportBlanksLoading}] = useExportParticipantExamsBlanksMutation();
    const {hasPermission} = useAuthContext();

    const handleOpenResultsModal = (id: string | null) => {
        setOpenResultsModal(true);
        setParticipantExamId(id);
    };
    const handleCloseResultsModal = () => {
        setOpenResultsModal(false);
        setParticipantExamId(null);
    };

    const handleExportProtocols = () => {
        exportProtocols({variables: {examId: exam?.id}})
            .then((res) => {
                res.data?.exportParticipantExamsProtocols &&
                    saveAs(
                        res.data?.exportParticipantExamsProtocols,
                        `Протоколы_${ExamTypeShortMap[exam?.type as ExamType]}_${exam?.number}.zip`
                    );
                notify({type: NotificationType.SUCCESS});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };
    const handleExportBlanks = () => {
        exportBlanks({variables: {examId: exam?.id}})
            .then((res) => {
                res.data?.exportParticipantExamsBlanks &&
                    saveAs(
                        res.data?.exportParticipantExamsBlanks,
                        `Бланки_допуска_${ExamTypeShortMap[exam?.type as ExamType]}_${exam?.number}.zip`
                    );
                notify({type: NotificationType.SUCCESS});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };
    return (
        <Card sx={{mt: 2}}>
            <Stack direction={{xs: 'column', md: 'row'}} justifyContent={'space-between'} alignItems={{md: 'center'}}>
                <Typography variant={'h3'}>Список аттестуемых</Typography>
                <Stack direction={{xs: 'column', md: 'row'}} spacing={1}>
                    {hasPermission(PermissionName.EditExams) && (
                        <Button variant={'contained'} onClick={handleToggleAddParticipantsModal}>
                            Добавить аттестуемого
                        </Button>
                    )}
                    <LoadingButton loading={exportBlanksLoading} variant={'contained'} onClick={handleExportBlanks}>
                        Печать бланков допуска
                    </LoadingButton>
                    <LoadingButton
                        loading={exportProtocolsLoading}
                        variant={'contained'}
                        onClick={handleExportProtocols}>
                        Печать протоколов
                    </LoadingButton>
                    <Button variant={'contained'} onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'Показать пароль' : 'Скрыть пароль'}
                    </Button>
                </Stack>
            </Stack>
            <TableLayout
                data={{
                    items: exam?.participantExams
                }}
                pagination={false}
                columns={tableConfig(showPassword, handleOpenResultsModal)}
            />
            {openAddParticipantsModal && (
                <AddParticipantModal open={openAddParticipantsModal} handleToggle={handleToggleAddParticipantsModal} />
            )}
            {openResultsModal && (
                <ViewResultsModal
                    open={openResultsModal}
                    handleClose={handleCloseResultsModal}
                    participantExamId={participantExamId!}
                />
            )}
        </Card>
    );
};

export default Participants;
