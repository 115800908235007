import {Container, Stack, Typography} from '@mui/material';
import TableLayout from 'components/table/TableLayout';
import {tableConfig} from './config';
import {useCategoriesQuery} from '@generated/graphql';

export const Categories = () => {
    const {data, loading, error} = useCategoriesQuery();

    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'} mb={5}>
                <Typography variant={'h2'}>Список категорий аттестуемых лиц</Typography>
            </Stack>
            <TableLayout
                loading={loading || !!error}
                data={data?.categories}
                columns={tableConfig()}
                pagination={false}
            />
        </Container>
    );
};
