import {TableCell, TableRow} from '@mui/material';
import {FormAutocomplete, FormTextField} from 'components/form';
import {Label} from 'components/Label';
import {green, red} from '@mui/material/colors';
import {Sex} from '@generated/graphql';
import moment from 'moment';
import {useFormContext, useWatch} from 'react-hook-form';
import {useEffect} from 'react';
import {useParticipantExamContext} from '../index';

enum Exercise {
    PullUps = 'PULL_UPS',
    PushUps = 'PUSH_UPS',
    BenchKettlebells = 'BENCH_KETTLEBELLS',
    ShuttleRun10x10 = 'SHUTTLE_RUN_10X10',
    ShuttleRun4x20 = 'SHUTTLE_RUN_4X20',
    PushUpsAndDowns = 'PUSH_UPS_AND_DOWNS',
    Slope = 'SLOPE'
}

export enum ExerciseStatus {
    Passed = 'PASSED',
    Failed = 'FAILED'
}

const ExerciseStatusMap = {
    [ExerciseStatus.Passed]: {text: 'Пройдено', color: green[500]},
    [ExerciseStatus.Failed]: {text: 'Не пройдено', color: red[500]}
};
const ExerciseMap = {
    [Exercise.PullUps]: 'Подтягивание на перекладине (кол-во раз)',
    [Exercise.PushUps]: 'Сгибание рук в упоре лежа (кол-во раз)',
    [Exercise.BenchKettlebells]: 'Толчок (жим) гири весом 16 кг (кол-во раз)',
    [Exercise.ShuttleRun10x10]: 'Челночный бег 10 x 10 м (сек.)',
    [Exercise.ShuttleRun4x20]: 'Челночный бег 4 x 20 м (сек.)',
    [Exercise.PushUpsAndDowns]: 'Сгибание и разгибание рук в упоре лежа (кол-во раз)',
    [Exercise.Slope]: 'Наклоны вперед из положения лежа на спине в течение 1 мин. (кол-во раз)'
};
const STANDARDS = {
    [Sex.Male]: {
        [Exercise.PullUps]: [15, 10, 6, 5, 4, 3, 2, 0],
        [Exercise.PushUps]: [45, 40, 35, 30, 24, 19, 14, 9],
        [Exercise.BenchKettlebells]: [15, 13, 11, 9, 6, 4, 2, 0],
        [Exercise.ShuttleRun10x10]: [28.8, 29.6, 30.3, 30.8, 31.6, 34.2, 38.2, 41.2],
        [Exercise.ShuttleRun4x20]: [19.2, 19.5, 19.9, 20.6, 21.4, 22.3, 26.1, 26.8]
    },
    [Sex.Female]: {
        [Exercise.PushUpsAndDowns]: [10, 8, 6, 5, 4, 3],
        [Exercise.Slope]: [20, 18, 16, 14, 10, 6],
        [Exercise.ShuttleRun10x10]: [36.5, 37.0, 39.0, 43.0, 48.0, 51.0],
        [Exercise.ShuttleRun4x20]: [20.0, 21.5, 22.0, 24.0, 29.0, 31.0]
    }
};

export const ExerciseTableRow = ({index}: {index: number}) => {
    const {participantExam} = useParticipantExamContext();
    const {setValue} = useFormContext();

    const participant = participantExam?.participant;
    const age = moment().diff(participant?.birthdayDate, 'years');
    const sex = participant?.sex || Sex.Male;

    const firstGroup =
        sex === Sex.Male
            ? [Exercise.PullUps, Exercise.PushUps, Exercise.BenchKettlebells]
            : [Exercise.PushUpsAndDowns, Exercise.Slope];

    const firstGroupOptions = firstGroup.map((item) => ({
        id: item,
        displayName: ExerciseMap[item]
    }));
    const secondGroupOptions = [Exercise.ShuttleRun10x10, Exercise.ShuttleRun4x20].map((item) => ({
        id: item,
        displayName: ExerciseMap[item]
    }));

    const options = [firstGroupOptions, secondGroupOptions];

    const watchExercise = useWatch({name: `attr[${index}].exercise`}) as {id: Exercise; displayName: string};
    const watchStandard = useWatch({name: `attr[${index}].standard`});
    const watchResult = useWatch({name: `attr[${index}].result`});
    const watchStatus = useWatch({name: `attr[${index}].status`}) as ExerciseStatus;

    const getStandardIndex = () => {
        if (age <= 25) return 0;
        else if (age > 25 && age <= 30) return 1;
        else if (age > 30 && age <= 35) return 2;
        else if (age > 35 && age <= 40) return 3;
        else if (age > 40 && age <= 45) return 4;
        else if (age > 45 && age <= 50) return 5;
        else if (sex === Sex.Female && age > 50) return 5;
        else if (sex === Sex.Male && age > 50 && age <= 55) return 6;
        else if (sex === Sex.Male && age > 55) return 7;
        else return 0;
    };

    useEffect(() => {
        const value = (STANDARDS[sex] as any)[watchExercise?.id] || [];
        const standardIndex = getStandardIndex();
        setValue(`attr[${index}].standard`, (value[standardIndex]?.toString() || '').replace('.', ','));
    }, [watchExercise?.id]);

    useEffect(() => {
        const result = watchResult?.replace(',', '.');
        const standard = watchStandard?.replace(',', '.');
        if (index === 0 && parseFloat(result) >= parseFloat(standard)) {
            setValue(`attr[${index}].status`, ExerciseStatus.Passed);
        } else if (index === 1 && parseFloat(result) <= parseFloat(standard)) {
            setValue(`attr[${index}].status`, ExerciseStatus.Passed);
        } else setValue(`attr[${index}].status`, ExerciseStatus.Failed);
    }, [watchStandard, watchResult]);

    return (
        <TableRow key={index}>
            <TableCell sx={{width: 50}}>{index + 1}</TableCell>
            <TableCell>
                <FormAutocomplete
                    name={`attr[${index}].exercise`}
                    options={options[index]}
                    saveEntity
                    rules={{required: true}}
                />
            </TableCell>
            <TableCell sx={{width: 120}}>
                <FormTextField name={`attr[${index}].standard`} disabled maskProps={{mask: Number}} />
            </TableCell>
            <TableCell sx={{width: 120}}>
                <FormTextField
                    name={`attr[${index}].result`}
                    maskProps={{mask: Number, min: 0, scale: index ? 2 : 0}}
                    rules={{required: true}}
                />
            </TableCell>
            <TableCell sx={{width: 200}}>
                <Label color={ExerciseStatusMap[watchStatus]?.color}>{ExerciseStatusMap[watchStatus]?.text}</Label>
            </TableCell>
        </TableRow>
    );
};
