import {Alert, Snackbar, Typography} from '@mui/material';
import React, {createContext, ReactNode, useState} from 'react';
import {AlertColor} from '@mui/material/Alert/Alert';

export type ToastContentType = {text?: string; type: AlertColor};
export const ToastContext = createContext(
    {} as {
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setToast: React.Dispatch<React.SetStateAction<ToastContentType | null>>;
    }
);

export const ToastProvider = ({children}: {children: ReactNode}) => {
    const [open, setOpen] = useState(false);
    const [toast, setToast] = useState<ToastContentType | null>(null);

    return (
        <ToastContext.Provider value={{setOpen, setToast}}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={() => setOpen(false)}
                sx={{width: 400}}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
                <Alert severity={toast?.type}>
                    <Typography whiteSpace={'pre-wrap'}>{toast?.text}</Typography>
                </Alert>
            </Snackbar>
        </ToastContext.Provider>
    );
};
