import {Autocomplete, AutocompleteProps, Box, InputLabel, TextField} from '@mui/material';
import {useController, UseControllerProps} from 'react-hook-form';

type FormAutocompleteProps<T> = {
    label?: string;
    placeholder?: string;
    optionValue?: keyof T;
    optionText?: ((value: T) => string) | string;
    saveEntity?: boolean;
};

export const FormAutocomplete = <T,>(
    props: UseControllerProps & Omit<AutocompleteProps<T, any, any, any>, 'renderInput'> & FormAutocompleteProps<T>
) => {
    const {
        label,
        name,
        placeholder,
        options = [],
        optionText = 'displayName',
        optionValue = 'id',
        multiple,
        rules,
        saveEntity,
        ...rest
    } = props;

    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: multiple ? [] : null});

    const getValue = () => {
        if (!saveEntity) {
            if (multiple)
                return field.value.map((value: any) => options.find((i: any) => i[optionValue] === value)) || [];
            else return options.find((i: any) => field.value === i[optionValue]) || null;
        } else {
            return field.value;
        }
    };

    return (
        <Box>
            <InputLabel error={!!error}>{label}</InputLabel>
            <Autocomplete
                {...field}
                getOptionLabel={(option) => {
                    if (typeof optionText === 'function') return optionText(option as T);
                    else if (typeof optionText === 'string' && typeof option !== 'string')
                        return option?.[optionText as keyof T] as string;
                    else return option as string;
                }}
                options={options || []}
                ChipProps={{
                    size: 'small',
                    variant: 'outlined',
                    sx: {fontSize: 12, height: 20, bgcolor: 'grey.100', borderRadius: '5px'}
                }}
                value={getValue()}
                multiple={multiple}
                size={'small'}
                onChange={(_, data: any, reason) => {
                    if ((reason === 'selectOption' || reason === 'removeOption') && data) {
                        if (saveEntity) field.onChange(data);
                        else if (multiple) field.onChange(data.map((i: any) => i[optionValue]));
                        else field.onChange(data[optionValue]);
                    } else {
                        field.onChange(data);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        error={!!error}
                        placeholder={placeholder || label}
                        helperText={error?.message}
                    />
                )}
                {...rest}
            />
        </Box>
    );
};
