import CustomModal from 'components/modals/CustomModal';
import {Box, Button, Typography} from '@mui/material';
import {
    ExamType,
    ParticipantExamQuery,
    ParticipantExamStatus,
    Sex,
    useAddParticipantExamDecisionMutation,
    useExportParticipantExamDocumentMutation,
    useParticipantExamQuery
} from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import {formatDate} from 'helpers';
import {SexMap} from 'constants/index';
import {PsychologyResults} from './PsychologyResults';
import {ZUNResults} from './ZUNResults';
import {useExamContext} from '../../../../Exam';
import {TextRow} from './components/TextRow';
import {useForm} from 'react-hook-form';
import {Form} from 'components/form';
import {useApolloClient} from '@apollo/client';
import {PhysicalResults} from './PhysicalResults';
import {createContext, useContext, useRef} from 'react';
import {ExerciseStatus} from './PhysicalResults/ExerciseTableRow';
import moment from 'moment';
import {saveAs} from 'file-saver';
import {NotificationType, useNotify} from 'hooks';
import {LoadingButton} from '@mui/lab';
import {useReactToPrint} from 'react-to-print';

const COMPONENT = {
    [ExamType.KnowledgeSkillTest]: ZUNResults,
    [ExamType.Psychology]: PsychologyResults,
    [ExamType.Physical]: PhysicalResults
};

const RESULTS_TITLE = {
    [ExamType.KnowledgeSkillTest]: 'Результаты проверки соответствия знаний, умений и навыков',
    [ExamType.Psychology]: 'Результаты проверки соответствия психофизиологических качеств',
    [ExamType.Physical]: 'Результаты проверки соответствия уровня физической подготовки'
};

type ParticipantExamContextType = {
    participantExam?: ParticipantExamQuery['participantExam'] | null;
};

export const ParticipantExamContext = createContext({} as ParticipantExamContextType);

export const useParticipantExamContext = () => useContext(ParticipantExamContext);

export const ViewResultsModal = ({
    open,
    handleClose,
    participantExamId
}: {
    open: boolean;
    handleClose: () => void;
    participantExamId: string;
}) => {
    const {exam} = useExamContext();
    const client = useApolloClient();
    const notify = useNotify();

    const resultsToPrintRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => resultsToPrintRef.current
    });

    const {data, loading, error} = useParticipantExamQuery({
        variables: {where: {id: {eq: participantExamId}}}
    });
    const participantExam = data?.participantExam;
    const participant = data?.participantExam?.participant;

    const [exportDocument, {loading: loadingExportDocument}] = useExportParticipantExamDocumentMutation();
    const getValues = () => {
        const json = JSON.parse(participantExam?.decision || '{}');
        if (exam?.type === ExamType.Psychology) {
            return {
                decision:
                    participantExam?.status === ParticipantExamStatus.Approved ||
                    participantExam?.status === ParticipantExamStatus.NotApproved
                        ? participantExam?.status
                        : null,
                attr: json.data || []
            };
        } else if (exam?.type === ExamType.Physical) {
            return {
                decision:
                    participantExam?.status === ParticipantExamStatus.Approved ||
                    participantExam?.status === ParticipantExamStatus.NotApproved
                        ? participantExam?.status
                        : ParticipantExamStatus.NotApproved,
                attr:
                    json.data?.map((i: any) => ({
                        ...i,
                        status: i.status || ExerciseStatus.Failed
                    })) || []
            };
        }
    };
    const form = useForm({
        values: getValues()
    });
    const {handleSubmit} = form;

    const [addParticipantExamDecision] = useAddParticipantExamDecisionMutation();

    const Component = COMPONENT[exam?.type as ExamType] || 'div';
    const onSubmit = (data: any) => {
        addParticipantExamDecision({
            variables: {
                data: JSON.stringify({data: data.attr}),
                decision: data.decision || ParticipantExamStatus.HasResult,
                participantExamId
            }
        }).then(() => {
            client.refetchQueries({include: ['exam', 'participantExam']});
            handleClose();
        });
    };
    const handleExportDocument = () => {
        exportDocument({variables: {participantExamId}})
            .then((res) => {
                saveAs(res.data?.exportParticipantExamDocument as string, `Решение_${participant?.uin}`);
                notify({type: NotificationType.SUCCESS, text: 'Решение успешно выгружены'});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };
    
    return (
        <CustomModal
            handleClose={handleClose}
            title={RESULTS_TITLE[exam?.type as ExamType]}
            open={open}
            maxWidth={'lg'}
            actions={
                <>
                    <Button onClick={handlePrint} variant={'contained'}>
                        Печать результатов
                    </Button>
                    {(participantExam?.status === ParticipantExamStatus.Approved ||
                        participantExam?.status === ParticipantExamStatus.NotApproved) && (
                        <LoadingButton
                            loading={loadingExportDocument}
                            onClick={handleExportDocument}
                            variant={'contained'}>
                            Печать решения
                        </LoadingButton>
                    )}
                    {exam?.type !== ExamType.Physical && participantExam?.videoLink && (
                        <Button
                            onClick={() => saveAs(participantExam.videoLink!, `Прохождение_${participant?.uin}.mkv`)}
                            variant={'contained'}>
                            Выгрузить видео
                        </Button>
                    )}
                    {/*<Button onClick={handleClose}>Закрыть</Button>*/}
                    {exam?.type !== ExamType.KnowledgeSkillTest && (
                        <Button onClick={handleSubmit(onSubmit)} variant={'contained'}>
                            Сохранить
                        </Button>
                    )}
                </>
            }>
            <ParticipantExamContext.Provider value={{participantExam}}>
                <CircularLoading loading={loading || !!error}>
                    <Box ref={resultsToPrintRef}>
                        <Box>
                            <Typography variant={'h4'} mb={1}>
                                Аттестуемый
                            </Typography>
                            <TextRow text={'УИН'} value={participant?.uin} />
                            <TextRow text={'Категория'} value={participant?.category?.name} />
                            <TextRow text={'Пол'} value={SexMap[participant?.sex as Sex]} />
                            <TextRow text={'Дата рождения'} value={formatDate(participant?.birthdayDate)} />
                            <TextRow text={'Возраст'} value={moment().diff(participant?.birthdayDate, 'years')} />
                        </Box>
                        <Box mt={2}>
                            <Typography variant={'h4'} mb={1}>
                                Результаты
                            </Typography>
                            <Form form={form}>
                                <Component />
                            </Form>
                        </Box>
                    </Box>
                </CircularLoading>
            </ParticipantExamContext.Provider>
        </CustomModal>
    );
};
