import React from 'react';
import {
    ParticipantsItemFragment,
    Sex,
    useCategoriesQuery,
    useCreateParticipantMutation,
    useUpdateParticipantMutation
} from '@generated/graphql';
import CustomModal from 'components/modals/CustomModal';
import {Form, FormAutocomplete, FormDatePicker, FormTextField} from 'components/form';
import {useForm} from 'react-hook-form';
import {SexMap} from 'constants/index';
import {Stack} from '@mui/material';
import {getEnumArray} from 'helpers';
import {NotificationType, useNotify} from 'hooks';
import {ApolloError} from '@apollo/client';
import {date, InferType, object, string} from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import moment from "moment";

const schema = object({
    uin: string().required(' '),
    sex: string<Sex>().required(' '),
    categoryId: string().required(' '),
    birthdayDate: date().required(' ').max(new Date(), 'Введите правильную дату рождения')
});

const ParticipantModal = ({
    participant,
    handleClose,
    open
}: {
    participant: ParticipantsItemFragment | null;
    handleClose: () => void;
    open: boolean;
}) => {
    const notify = useNotify();
    const [updateParticipant, {loading: loadingUpdate}] = useUpdateParticipantMutation();
    const {data: categories} = useCategoriesQuery();
    const categoryOptions = categories?.categories?.items || [];
    const [createParticipant, {loading: loadingCreate}] = useCreateParticipantMutation();

    const form = useForm({
        defaultValues: participant
            ? schema.cast(
                  {
                      ...participant,
                      categoryId: participant?.category?.id || null
                  },
                  {stripUnknown: true}
              )
            : undefined,
        resolver: yupResolver(schema)
    });
    const {handleSubmit} = form;

    const onSubmit = (data: InferType<typeof schema>) => {
        const func = participant ? updateParticipant : createParticipant;
        func({
            variables: {
                participantModel: {
                    ...data,
                    birthdayDate: moment(data.birthdayDate).format('YYYY-MM-DD'),
                    id: participant?.id || undefined
                }
            }
        })
            .then(() => {
                notify({type: NotificationType.SUCCESS});
                handleClose();
            })
            .catch((error: ApolloError) => {
                const message = error.graphQLErrors?.[0]?.extensions?.message as string;
                if (message?.includes('already exists')) {
                    notify({type: NotificationType.ERROR, text: 'Участник с таким УИН уже существует'});
                } else {
                    notify({type: NotificationType.ERROR});
                }
            });
    };

    return (
        <CustomModal
            title={participant ? 'Редактирование аттестуемого' : 'Добавление аттестуемого'}
            loading={participant ? loadingUpdate : loadingCreate}
            open={open}
            handleClose={() => {
                handleClose();
            }}
            handleSubmit={handleSubmit(onSubmit)}>
            <Form form={form}>
                <Stack spacing={2}>
                    <FormTextField name={'uin'} label={'УИН'} type={'number'} />
                    <FormAutocomplete name={'sex'} options={getEnumArray(SexMap)} label={'Пол'} />
                    <FormDatePicker label={'Дата рождения'} name={'birthdayDate'} />
                    <FormAutocomplete
                        name={'categoryId'}
                        options={categoryOptions}
                        disabled={!!participant}
                        optionText={'name'}
                        label={'Категория'}
                    />
                </Stack>
            </Form>
        </CustomModal>
    );
};

export default ParticipantModal;
