import React, {createContext, ReactNode, useContext} from 'react';
import {useLocation} from 'react-router';
import {ApolloError} from '@apollo/client';
import {MeQuery, PermissionName, useMeQuery} from '@generated/graphql';

export type AuthContextType = {
    me: MeQuery['me'] | null;
    hasPermission: (permission: PermissionName) => boolean;
    loading: boolean;
    error?: ApolloError;
};
export const AuthContext = createContext({} as AuthContextType);

type AuthProviderProps = {
    children: ReactNode;
};
export const AuthProvider = ({children}: AuthProviderProps) => {
    const {pathname} = useLocation();
    const {data, loading, error} = useMeQuery({skip: pathname === '/login'});
    const permissions = data?.me?.role?.permissions?.map((p) => p.name) || [];

    const hasPermission = (permission: PermissionName) => permissions.includes(permission);

    return (
        <AuthContext.Provider value={{me: data?.me, hasPermission, loading, error}}>{children}</AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext);
