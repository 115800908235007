import {TypographyOptions} from '@mui/material/styles/createTypography';

export default {
    fontFamily: 'Roboto',
    h1: {
        fontWeight: 500,
        fontSize: '2.25rem', //36
        lineHeight: '40px',
        '@media (max-width:900px)': {
            fontSize: 28
        }
    },
    h2: {
        fontWeight: 500,
        fontSize: '2rem', //28
        lineHeight: '32px',
        '@media (max-width:900px)': {
            fontSize: 24
        }
    },
    h3: {
        fontWeight: 500,
        fontSize: '1.5rem', //24
        lineHeight: '28px',
        '@media (max-width:900px)': {
            fontSize: 20
        }
    },
    h4: {
        fontWeight: 500,
        fontSize: '1.25rem', //20
        lineHeight: '24px',
        '@media (max-width:900px)': {
            fontSize: 16
        }
    },
    h5: {
        fontWeight: 500,
        fontSize: '1rem', //16
        lineHeight: '24px'
    },
    h6: {
        fontWeight: 500,
        fontSize: '0.875rem', //14
        lineHeight: '16px'
    },
    h7: {
        fontWeight: 500,
        fontSize: '0.75rem', //12
        lineHeight: '16px'
    },
    button: {
        fontWeight: 500
    }
} as TypographyOptions;
