import React, {createContext, ReactNode, useState} from 'react';
import DeleteModal from 'components/modals/DeleteModal';
import {Breakpoint} from '@mui/material';

type ModalProps = {
    title: string;
    description?: string;
    handleClick: () => any;
    loading?: boolean;
    color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined;
    actionText?: string;
    maxWidth?: Breakpoint;
};

interface ModalContextType {
    handleOpen: (modalProps: ModalProps) => void;
}

export const ModalContext = createContext<ModalContextType>({
    handleOpen: () => {}
});

export const ModalProvider = ({children}: {children: ReactNode}) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState<ModalProps>({
        title: '',
        description: '',
        handleClick: async () => {},
        loading: false,
        color: 'error'
    });

    const {handleClick} = content;
    const handleOpen = ({title, description, handleClick, color, ...rest}: ModalProps) => {
        setContent({title, description, handleClick, color, ...rest});
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = async () => {
        try {
            setContent({...content, loading: true});
            await handleClick();
            setContent({...content, loading: false});
            handleClose();
        } catch (e) {
            setContent({...content, loading: false});
        }
    };

    return (
        <ModalContext.Provider value={{handleOpen}}>
            <DeleteModal open={open} onClose={handleClose} {...{...content, handleClick: onSubmit}} />
            {children}
        </ModalContext.Provider>
    );
};
