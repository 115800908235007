import {Skeleton, TableBody, TableCell, TableRow} from '@mui/material';
import React from 'react';

export const SkeletonTable = ({rows, columns}: {rows: number; columns: any[]}) => {
    return (
        <TableBody>
            {Array(rows)
                .fill(0)
                .map((row, i) => (
                    <TableRow key={i}>
                        {columns.map((col, i) => {
                            if (col.hide) return <></>;
                            return (
                                <TableCell align={col.align} key={i}>
                                    <Skeleton variant={'text'} height={20} />
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
        </TableBody>
    );
};
