import {Button, Container, Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router';
import TableLayout from 'components/table/TableLayout';
import {filterFields, tableConfig} from './config';
import {PermissionName, useUsersQuery} from '@generated/graphql';
import {useAuthContext} from 'context/authContext';

export const Users = () => {
    const filters = filterFields();
    const {data, loading, error, refetch} = useUsersQuery();
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();

    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'} mb={5}>
                <Typography variant={'h2'}>Список пользователей</Typography>
                {hasPermission(PermissionName.EditUserRecords) && (
                    <Button variant={'contained'} onClick={() => navigate('create')}>
                        Добавить пользователя
                    </Button>
                )}
            </Stack>
            <TableLayout
                loading={loading || !!error}
                data={data?.users}
                columns={tableConfig(refetch)}
                filters={filters}
                refetch={refetch}
            />
        </Container>
    );
};
