import {Box, Typography} from '@mui/material';
import {green, red, yellow} from '@mui/material/colors';
import {BlockType} from './types';

export interface BourdonResultsType extends BlockType {
    Answers: number[];
    Symbols: string[];
    Result: {
        S: number;
        n: number;
        t: number;
        N: number;
        C: number;
        M: number;
        P: number;
        O: number;
        A: number;
        T1: number;
        T2: number;
        T3: number;
        E: number;
        Au: number;
        K: number;
        V: number;
        Q: number;
        Ku: number;
        KText: string;
    };
}

export const BourdonResults = ({data}: {data: BourdonResultsType}) => {
    const {Answers: answers, Symbols: symbols} = data;
    const result = data.Result;

    const searchSymbols = symbols.filter((i, index) => index % 40 === 0);

    const getColor = (index: number) => {
        if (index % 40 === 0) return green[400];

        if (symbols[index] === searchSymbols[Math.floor(index / 40)]) {
            if (answers.includes(index)) return green[400];
            else return yellow[400];
        } else {
            if (answers.includes(index)) return red[400];
            else return 'transparent';
        }
    };

    return (
        <>
            <Typography mb={2}>Таблица букв, с которой вы работали:</Typography>
            <Box display={'grid'} gridTemplateColumns={'repeat(40, 15px)'} gap={'2px'}>
                {symbols.map((item, index) => (
                    <Box
                        key={index}
                        p={'2px'}
                        textAlign={'center'}
                        sx={{
                            backgroundColor: getColor(index),
                            cursor: 'default'
                        }}>
                        {item}
                    </Box>
                ))}
            </Box>
            <Typography fontWeight={700} mt={2}>
                Основные показатели
            </Typography>
            <Typography>Время, затраченное на тестирование (t): {result.t}</Typography>
            <Typography>Общее количество просмотренных букв (N): {result.N}</Typography>
            <Typography>Общее количество просмотренных строк (C): {result.C} </Typography>
            <Typography>Общее количество букв, которые нужно было выделить (n): {result.n}</Typography>
            <Typography>Общее количество выделенных букв (M): {result.M}</Typography>
            <Typography>Количество верно выделенных букв (S): {result.S}</Typography>
            <Typography>Количество пропущенных букв (P): {result.P}</Typography>
            <Typography>Количество ошибочно выбранных букв (O): {result.O}</Typography>
            <Typography fontWeight={700} mt={2}>
                Расчет результатов
            </Typography>
            <Typography>
                Показатель скорости внимания (производительности внимания) (A): {result.A} знаков в секунду
            </Typography>
            <Typography>Показатель точности работы (первый вариант) (T1): {result.T1} условных единиц</Typography>
            <Typography>Показатель точности работы (второй вариант) (T2): {result.T2} условных единиц</Typography>
            <Typography>
                Показатель точности работы (третий вариант, по Уиппу) (T3): {result.T3} условных единиц
            </Typography>
            <Typography>Коэффициент умственной продуктивности (E): {result.E} знаков</Typography>
            <Typography>Умственная работоспособность (Au): {result.Au} знаков в секунду</Typography>
            <Typography>
                Концентрация внимания (процент правильно выделенных символов от всех, что нужно было выделить) (K):{' '}
                {result.K} процентов
            </Typography>
            <Typography>Показатель устойчивости концентрации внимания (Ku): {result.Ku} баллов</Typography>
            <Typography>Объем зрительной информации (V): {result.V} знаков</Typography>
            <Typography>Скорости переработки (Q): {result.Q} знаков</Typography>
            <Typography fontWeight={700} mt={2}>
                Обобщенный результат
            </Typography>
            <Typography>
                Уровень концентрации внимания {result.K}, что соответствует значению - {result.KText}
            </Typography>
            <Typography>
                Общее количество ошибок равно {result.O + result.P}{' '}
                {result.O + result.P > 5
                    ? '– это выше нормального количества ошибок для взрослого человека.'
                    : '– это норма уровня концентрации внимания для взрослого человека.'}
            </Typography>
        </>
    );
};
