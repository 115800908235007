import {Stack, Typography} from '@mui/material';
import {ReactNode} from 'react';

export const TextRow = ({text, value}: {text: ReactNode; value: ReactNode}) => {
    return (
        <Stack direction={'row'} spacing={1} mb={0.5}>
            <Typography sx={{flexShrink: 0}}>{text}:</Typography>
            <Typography>
                <b>{value}</b>
            </Typography>
        </Stack>
    );
};
