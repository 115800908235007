import {Button, Container, Stack, Typography} from '@mui/material';
import React from 'react';
import TableLayout from 'components/table/TableLayout';
import {filterFields, tableConfig} from './config';
import {useNavigate} from 'react-router';
import {PermissionName, useExamsQuery} from '@generated/graphql';
import {useAuthContext} from 'context/authContext';

export const Exams = () => {
    const navigate = useNavigate();
    const filters = filterFields();
    const {data, loading, error, refetch} = useExamsQuery();
    const {hasPermission} = useAuthContext();

    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'} mb={5}>
                <Typography variant={'h2'}>Список аттестаций</Typography>
                <Stack direction={'row'} spacing={2}>
                    {hasPermission(PermissionName.EditExams) && (
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                navigate('create');
                            }}>
                            Добавить аттестацию
                        </Button>
                    )}
                    {/*<Button variant={'contained'} disabled>*/}
                    {/*    Выгрузить отчёт*/}
                    {/*</Button>*/}
                </Stack>
            </Stack>

            <TableLayout
                loading={loading || !!error}
                data={data?.exams}
                columns={tableConfig()}
                filters={filters}
                refetch={refetch}
            />
        </Container>
    );
};
