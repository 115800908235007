import moment from 'moment';
import cookies from 'js-cookie';
import {get} from 'lodash';

export const cleanData = (data: {[s: string]: unknown} | ArrayLike<unknown>) =>
    Object.entries(data).reduce((a, [k, v]) => (v == null || v === '' ? a : ((a[k] = v), a)), {} as {[k: string]: any});

export const getEnumArray = (enumObj: object | undefined, fieldValue?: string) => {
    return enumObj
        ? Object.entries(enumObj).map(([key, value]) => ({
              id: key,
              displayName: fieldValue ? get(value, fieldValue) : value
          }))
        : [];
};

export const formatDate = (date: string | undefined, format = 'DD.MM.YYYY') => {
    if (date && moment(date).isValid()) {
        return moment(date).format(format);
    }
};

export const formatDateTime = (date: string | undefined, format = 'DD.MM.YYYY HH:mm') => {
    if (date && moment(date).isValid()) {
        return moment(date).format(format);
    }
};

export const formatDecimal = (value: number) => {
    return value < 10 ? `0${value}` : value;
};

export const formatTime = (duration: moment.Duration) =>
    `${formatDecimal(duration.hours())}:${formatDecimal(duration.minutes())}:${formatDecimal(duration.seconds())}`;

export const formatNumber = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    if (!number) number = 0;
    return (
        number +
        ' ' +
        titles[
            Math.abs(number) % 100 > 4 && Math.abs(number) % 100 < 20
                ? 2
                : cases[Math.abs(number) % 10 < 5 ? Math.abs(number) % 10 : 5]
        ]
    );
};

export const setAuthCookies = (_cookies: any[] = []) => {
    const currentHostname = window.location.hostname;
    for (const cookie of _cookies) {
        cookies.set(cookie?.name, cookie?.value, {
            expires: moment().add(30, 'days').toDate(),
            domain: currentHostname === 'localhost' ? undefined : '.' + currentHostname,
            ...cookie?.options
        });
    }
};

export const removeAuthCookies = (_cookies: any[] = []) => {
    const currentHostname = window.location.hostname;
    for (const cookie of _cookies) {
        cookies.remove(cookie, {
            path: '/',
            domain: currentHostname === 'localhost' ? undefined : '.' + currentHostname,
            ...cookie?.options
        });
    }
};
