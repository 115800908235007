import {useDropzone} from 'react-dropzone';
import {CSSProperties, useMemo} from 'react';
import {IconButton, Stack} from '@mui/material';
import {AttachFile as AttachFileIcon, Clear as ClearIcon} from '@mui/icons-material';
import {useController, UseControllerProps} from 'react-hook-form';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export const Dropzone = (props: UseControllerProps) => {
    const {name, rules} = props;

    const {field} = useController({name, defaultValue: [], rules});
    const files = field.value as File[];
    const onDrop = (acceptedFiles: File[]) => {
        field.onChange([...field.value, ...acceptedFiles]);
    };
    const {getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject} = useDropzone({onDrop});

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused && {
                borderColor: '#2196f3'
            }),
            ...(isDragAccept && {
                borderColor: '#00e676'
            }),
            ...(isDragReject && {
                borderColor: '#ff1744'
            })
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const humanFileSize = (size: number) => {
        const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    };
    return (
        <>
            <div {...getRootProps({style: style as CSSProperties})}>
                <input {...getInputProps()} />
                {isDragActive ? <p>Перетащите файлы сюда...</p> : <p>Выберите файлы или перетащите их сюда</p>}
            </div>
            {files.map((file, index) => (
                <Stack direction={'row'} key={index} alignItems={'center'} mt={1}>
                    <AttachFileIcon fontSize={'small'} />
                    {file.name} ({humanFileSize(file.size)})
                    <IconButton size={'small'} sx={{ml: 2}} disableRipple>
                        <ClearIcon
                            sx={{color: 'grey.400'}}
                            fontSize={'small'}
                            onClick={() => field.onChange(files.filter((_, idx) => idx != index))}
                        />
                    </IconButton>
                </Stack>
            ))}
        </>
    );
};
