import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    Box,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import {ArrowForwardIosSharp as ArrowForwardIosSharpIcon} from '@mui/icons-material';
import {ReactElement, ReactNode, useState} from 'react';
import {ParticipantExamStatus, PsychologyTestType} from '@generated/graphql';
import {LandoltResults} from './LandoltResults';
import {SubjectiveLevelOfControlResults} from './SubjectiveLevelOfControlResults';
import {DifficultAssociationResults} from './DifficultAssociationResults';
import {MmpiResults} from './MmpiResults';
import {KettelResults} from './KettelResults';
import {BourdonResults} from './BourdonResults';
import {RavenResults} from './RavenResults';
import {SchulteResults} from './SchulteResults';
import {DegreeOfChronicFatigueResults} from './DegreeOfChronicFatigueResults';
import {LuscherResults} from './LuscherResults';
import {ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {ParticipantExamStatusMap, PsychologyTestTypeMap} from 'constants/index';
import {BlockType} from './types';
import {FormAutocomplete, FormTextField} from 'components/form';
import {getEnumArray} from 'helpers';
import {useParticipantExamContext} from '../index';
import {MemoryResults} from './MemoryResults';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Annotation,
    ArcElement,
    ChartDataLabels,
    Legend
);

const RESULTS: {[key: string]: (data: any) => ReactElement} = {
    [PsychologyTestType.DegreeOfChronicFatigue]: DegreeOfChronicFatigueResults,
    [PsychologyTestType.Kettel]: KettelResults,
    [PsychologyTestType.Raven]: RavenResults,
    [PsychologyTestType.Mmpi]: MmpiResults,
    [PsychologyTestType.SubjectiveLevelOfControl]: SubjectiveLevelOfControlResults,
    [PsychologyTestType.DifficultAssociation]: DifficultAssociationResults,
    [PsychologyTestType.LuscherColors]: LuscherResults,
    [PsychologyTestType.SchulteTables]: SchulteResults,
    [PsychologyTestType.LandoltCircles]: LandoltResults,
    [PsychologyTestType.Bourdon]: BourdonResults,
    [PsychologyTestType.Memory]: MemoryResults
};

enum Grade {
    BelowAverage = 'BELOW_AVERAGE',
    Average = 'AVERAGE',
    AboveAverage = 'ABOVE_AVERAGE'
}

const GradeMap = {
    [Grade.BelowAverage]: 'ниже среднего',
    [Grade.Average]: 'средняя',
    [Grade.AboveAverage]: 'выше среднего'
};
const PVC = [
    'Интеллектуальное развитие, способность к логическим суждениям и умозаключениям, к четкому изложению информации в устной и письменной формах',
    'Эмоциональная устойчивость, уравновешенность, самоконтроль поведения и внешних проявлений эмоций, эмоциональная зрелость',
    'Внутренняя организованность, исполнительность, дисциплинированность и ответственность',
    'Соблюдение правовых норм поведения, морали и нравственности',
    'Зрелость личности, способность брать на себя ответственность за принятия решения, за свои действия и поступки, включая умение определить приоритеты и последовательность решения проблем',
    'Адекватная самооценка, устойчивая мотивация к достижению успеха в конкретной профессиональной деятельности',
    'Стрессоустойчивость в экстремальных ситуациях',
    'Способность поддержания оптимального уровня работоспособности в штатных условиях монотонии и экстремальных условиях'
];
export const PsychologyResults = () => {
    const {participantExam} = useParticipantExamContext();
    const data = (JSON.parse(participantExam?.result || '{}').Data || []) as BlockType[];
    const decisionOptions = getEnumArray(ParticipantExamStatusMap, 'text').filter(
        (i) => i.id === ParticipantExamStatus.Approved || i.id === ParticipantExamStatus.NotApproved
    );
    return (
        <>
            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                <Typography fontWeight={700}>Оценка результатов обследования</Typography>
                <FormAutocomplete
                    sx={{width: 300}}
                    name={'decision'}
                    options={decisionOptions}
                    rules={{required: true}}
                />
            </Stack>
            <TableContainer component={Paper} variant={'outlined'} sx={{my: 2}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>ПВК</TableCell>
                            <TableCell>Характеристика ПВК</TableCell>
                            <TableCell>Оценка ПВК</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {PVC.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{width: 50}}>{index + 1}</TableCell>
                                <TableCell sx={{width: 300}}>{item}</TableCell>
                                <TableCell>
                                    <FormTextField name={`attr[${index}].characteristic`} multiline rows={3} />
                                </TableCell>
                                <TableCell sx={{width: 250}}>
                                    <FormAutocomplete
                                        name={`attr[${index}].grade`}
                                        options={getEnumArray(GradeMap)}
                                        saveEntity
                                        rules={{required: true}}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack spacing={1}>
                {data.map((item, index) => {
                    const Component = RESULTS[item?.PsychologyTestType] || 'div';
                    return (
                        <AccordionItem
                            key={index}
                            summary={PsychologyTestTypeMap[item?.PsychologyTestType as PsychologyTestType]}
                            details={<Component data={item} />}
                        />
                    );
                })}
            </Stack>
        </>
    );
};

const AccordionItem = ({summary, details}: {summary: string; details: ReactNode}) => {
    const [expanded, setExpanded] = useState(true);
    return (
        <Box>
            <StyledAccordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
                <StyledAccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
                    <Typography>{summary}</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Box>{details}</Box>
                </StyledAccordionDetails>
            </StyledAccordion>
        </Box>
    );
};

const StyledAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={0} square {...props} />)(
    ({theme}) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        }
    })
);

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />} {...props} />
))(({theme}) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}));

const StyledAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}));
