import {Container, Stack, Typography} from '@mui/material';
import React from 'react';
import {Form} from 'components/form';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router';
import CircularLoading from 'components/CircularLoading';
import {LoadingButton} from '@mui/lab';
import {NotificationType, useNotify} from 'hooks';
import {
    ExamType,
    PermissionName,
    QuestionType,
    useCreateOrUpdateTopicMutation,
    useTopicQuery
} from '@generated/graphql';
import {yupResolver} from '@hookform/resolvers/yup';
import {array, boolean, InferType, mixed, object, string} from 'yup';
import {MainInfo, QuestionsBank} from './components';
import {ROUTES} from 'constants/routes';
import {useAuthContext} from 'context/authContext';

const schema = object({
    name: string().required(' '),
    categoryIds: array().default([]).min(1, ' '),
    questions: array()
        .of(
            object({
                title: string().required(' '),
                content: string().required(' '),
                type: mixed<QuestionType>().required(' '),
                answers: array()
                    .of(object({id: string(), text: string().required(' '), isCorrect: boolean().required(' ')}))
                    .default([])
                    .test(
                        'answers',
                        'Должен быть хотя бы один правильный ответ',
                        (answers) => !!answers?.find((answer) => answer.isCorrect)
                    )
            })
        )
        .default([])
});

export const ZunTopic = () => {
    const {topicId} = useParams();
    const {data, loading, error} = useTopicQuery({variables: {where: {id: {eq: topicId}}}, skip: !topicId});
    const topic = data?.topic;
    const [createOrUpdateTopic, {loading: mutationLoading}] = useCreateOrUpdateTopicMutation();
    const notify = useNotify();
    const {hasPermission} = useAuthContext();
    const navigate = useNavigate();

    const form = useForm({
        values: topic
            ? schema.cast(
                  {
                      ...data?.topic,
                      categoryIds: topic?.categories.map((i) => i.id)
                  },
                  {stripUnknown: true}
              )
            : undefined,
        resolver: yupResolver(schema)
    });

    const {handleSubmit} = form;
    const onSubmit = (data: InferType<typeof schema>) => {
        createOrUpdateTopic({
            variables: {
                topicModel: {
                    ...data,
                    id: topicId,
                    type: ExamType.KnowledgeSkillTest
                }
            }
        })
            .then((res) => {
                notify({type: NotificationType.SUCCESS});
                !topicId && navigate(ROUTES.ZUN_TOPICS + '/' + res.data?.createOrUpdateTopic.id);
            })
            .catch(() => {
                notify({type: NotificationType.ERROR});
            });
    };
    const onError = () => {
        notify({type: NotificationType.ERROR, text: 'Проверьте правильность заполнения полей'});
    };

    return (
        <CircularLoading loading={!!topicId && (loading || !!error)}>
            <Container>
                <Stack direction={'row'} mb={2} justifyContent={'space-between'}>
                    <Typography variant={'h2'}>Тема вопросов проверки ЗУН</Typography>
                    {hasPermission(PermissionName.EditTopics) && (
                        <LoadingButton
                            loading={mutationLoading}
                            variant={'contained'}
                            onClick={handleSubmit(onSubmit, onError)}>
                            Сохранить
                        </LoadingButton>
                    )}
                </Stack>
                <Form form={form} autoComplete={'off'}>
                    <Stack spacing={2}>
                        <MainInfo />
                        <QuestionsBank />
                    </Stack>
                </Form>
            </Container>
        </CircularLoading>
    );
};
