import {useNavigate, useParams} from 'react-router';
import {Form, FormTextField} from 'components/form';
import {useForm} from 'react-hook-form';
import {Container, Stack, Typography} from '@mui/material';
import TransferList from './TransferList';
import CircularLoading from 'components/CircularLoading';
import {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import {InferType, object, string} from 'yup';
import {
    CreateRoleMutation,
    Permission,
    PermissionName,
    useCreateRoleMutation,
    usePermissionsQuery,
    useRoleQuery,
    useUpdateRoleMutation
} from '@generated/graphql';
import {NotificationType, useNotify} from 'hooks';
import {useAuthContext} from 'context/authContext';
import {ROUTES} from 'constants/routes';

const schema = object({
    displayName: string().required(' ')
});
export const Role = () => {
    const {roleId} = useParams();
    const [createRole, {loading: createRoleLoading}] = useCreateRoleMutation();
    const [updateRole, {loading: updateRoleLoading}] = useUpdateRoleMutation();
    const {
        data: roleData,
        loading: isRoleLoading,
        error: isRoleError
    } = useRoleQuery({variables: {where: {id: {eq: roleId}}}});
    const {data: permissionsData, loading: isPermissionsLoading, error: isPermissionsError} = usePermissionsQuery();
    const role = roleData?.role;
    const permissions = permissionsData?.permissions?.items || [];

    const [chosenPermissions, setChosenPermissions] = useState<Permission[]>([]);
    const navigate = useNavigate();
    const notify = useNotify();
    const {hasPermission} = useAuthContext();

    const form = useForm({
        values: role ? schema.cast(role, {stripUnknown: true}) : undefined,
        resolver: yupResolver(schema)
    });

    const {handleSubmit} = form;

    const onSubmit = (data: InferType<typeof schema>) => {
        const func = roleId ? updateRole : createRole;
        func({
            variables: {model: {...data, permissionIds: chosenPermissions.map((i) => i.id!), id: roleId!}}
        })
            .then((res) => {
                !roleId && navigate(ROUTES.ROLES + '/' + (res.data as CreateRoleMutation).createRole.id);
                notify({type: NotificationType.SUCCESS});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };
    return (
        <CircularLoading
            loading={isPermissionsLoading || !!isPermissionsError || (!!roleId && (isRoleLoading || !!isRoleError))}>
            <Container>
                <Stack direction={'row'} mb={2} justifyContent={'space-between'}>
                    <Typography variant={'h2'}>Роль</Typography>
                    {hasPermission(PermissionName.EditUserRoles) && (
                        <LoadingButton
                            loading={roleId ? updateRoleLoading : createRoleLoading}
                            variant={'contained'}
                            onClick={handleSubmit(onSubmit)}>
                            Сохранить
                        </LoadingButton>
                    )}
                </Stack>

                <Form form={form}>
                    <Stack spacing={2}>
                        <FormTextField name={'displayName'} label={'Название роли'} />
                        <TransferList
                            key={role?.permissions?.length}
                            leftList={(role?.permissions as Permission[]) || []}
                            rightList={permissions as Permission[]}
                            setChosenPermissions={setChosenPermissions}
                        />
                    </Stack>
                </Form>
            </Container>
        </CircularLoading>
    );
};
