import {forwardRef} from 'react';
import {IMaskInput} from 'react-imask';

type TextMaskCustomProps = {
    onChange: (event: {target: {name: string; value: string}}) => void;
    mask: string;
    name: string;
};

const TextMaskCustom = forwardRef<HTMLInputElement, TextMaskCustomProps>(function TextMaskCustom(props, ref) {
    const {onChange, name, mask, ...other} = props;
    return (
        <IMaskInput
            {...other}
            mask={mask}
            inputRef={ref}
            onAccept={(value: any) => onChange({target: {name, value}})}
            overwrite
        />
    );
});
export default TextMaskCustom;
