import React from 'react';
import {alpha, Box, useTheme} from '@mui/material';
import {Line} from 'react-chartjs-2';
import {TimeBlock} from './components';
import {BlockType} from './types';

export const subjectiveLevelOfControlInternalities = [
    'InternalityGeneral',
    'InternalityAchievement',
    'InternalityFailure',
    'InternalityFamilyRelationships',
    'InternalityProductionRelationships',
    'InternalityInterpersonalRelationships',
    'InternalityHealth'
] as const;

export type SubjectiveLevelOfControlResultKeys = (typeof subjectiveLevelOfControlInternalities)[number];

export type SubjectiveLevelOfControlResult = {[key in SubjectiveLevelOfControlResultKeys]: number};

const SCALE: {[K in SubjectiveLevelOfControlResultKeys]: string} = {
    InternalityGeneral: 'Общая интернальность (Ио)',
    InternalityAchievement: 'В области достижений (Ид)',
    InternalityFailure: 'В области неудач (Ин)',
    InternalityFamilyRelationships: 'В семейных отношениях (Ис)',
    InternalityProductionRelationships: 'В области производственных отношений (Ип)',
    InternalityInterpersonalRelationships: 'В области межличностных отношений (Им)',
    InternalityHealth: 'В отношении здоровья и болезни (Из)'
};

interface SubjectiveLevelOfControlResultsType extends BlockType {
    Result: SubjectiveLevelOfControlResult & {
        Max: number;
    };
}

export const SubjectiveLevelOfControlResults = ({data}: {data: SubjectiveLevelOfControlResultsType}) => {
    const theme = useTheme();
    const result = data.Result;

    return (
        <Box>
            <TimeBlock startTime={data.StartTime} finishTime={data.FinishTime} />
            <Box
                sx={{
                    '& td': {
                        '&: not(:first-of-type)': {width: 36, boxSizing: 'border-box', textAlign: 'center'},
                        px: 1
                    },
                    my: 2
                }}>
                <table border={1} style={{borderSpacing: 0, width: '100%'}}>
                    <thead>
                        <tr>
                            <th>Шкала</th>
                            <th colSpan={10}>Значение</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(SCALE).map(([key, value], index) => (
                            <tr key={index}>
                                <td>{value}</td>
                                {Array(10)
                                    .fill(0)
                                    .map((_, index) => (
                                        <td
                                            key={index}
                                            style={{
                                                backgroundColor:
                                                    index + 1 === result[key as SubjectiveLevelOfControlResultKeys]
                                                        ? alpha(theme.palette.primary.main, 0.5)
                                                        : 'inherit'
                                            }}>
                                            {index + 1}
                                        </td>
                                    ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
            <Line
                options={{
                    scales: {
                        x: {
                            labels: ['Ио', 'Ид', 'Ин', 'Ис', 'Ип', 'Им', 'Из']
                        },
                        x1: {
                            labels: subjectiveLevelOfControlInternalities.map((key) => result[key].toString()),
                            ticks: {
                                color: theme.palette.primary.main
                            }
                        },
                        y: {
                            min: 1,
                            max: 10,
                            ticks: {
                                stepSize: 1
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            display: false
                        }
                    }
                }}
                data={{
                    datasets: [
                        {
                            data: Object.keys(SCALE).map((key) => result[key as SubjectiveLevelOfControlResultKeys]),
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main
                        }
                    ]
                }}
            />
        </Box>
    );
};
