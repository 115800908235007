import React from 'react';
import {SvgIcon} from '@mui/material';
import {
    ArrowDropUp as ArrowDropUpIcon,
    ArrowLeft as ArrowDropDownIcon,
    ArrowLeft as ArrowLeftIcon,
    ArrowRight as ArrowRightIcon,
    CloseSharp as CloseSharpIcon,
    DriveFileRenameOutline as DriveFileRenameOutlineIcon
} from '@mui/icons-material';

export enum ACTION {
    MOVE_UP = 'MOVE_UP',
    MOVE_DOWN = 'MOVE_DOWN',
    DELETE = 'DELETE',
    EDIT = 'EDIT',
    MOVE_RIGHT = 'MOVE_RIGHT',
    MOVE_LEFT = 'MOVE_LEFT'
}

const COMPONENTS = {
    MOVE_UP: ArrowDropUpIcon,
    MOVE_DOWN: ArrowDropDownIcon,
    DELETE: CloseSharpIcon,
    EDIT: DriveFileRenameOutlineIcon,
    MOVE_RIGHT: ArrowRightIcon,
    MOVE_LEFT: ArrowLeftIcon
};

export const ActionButton = ({type, onClick}: {type: ACTION; onClick: () => void}) => {
    return (
        <SvgIcon
            component={COMPONENTS[type]}
            onClick={onClick}
            sx={{
                '&:hover': {
                    border: (theme) => '1px solid ' + theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.main
                },
                border: '1px solid ',
                borderRadius: '5px',
                color: (theme) => theme.palette.divider,
                cursor: 'pointer',
                width: 24,
                height: 24,
                margin: '1px 0'
            }}
        />
    );
};
