import moment from 'moment';
import React from 'react';
import {TextRow} from '../../components/TextRow';

export const formatDecimal = (value: number) => {
    return value < 10 ? `0${value}` : value;
};

export const formatTime = (duration: moment.Duration) =>
    `${formatDecimal(duration.minutes())}:${formatDecimal(duration.seconds())}`;

export const TimeBlock = ({startTime, finishTime}: any) => {
    return (
        <>
            <TextRow text={'Время начала аттестации'} value={moment(startTime).format('HH:mm:ss')} />
            <TextRow
                text={'Затраченное время'}
                value={formatTime(moment.duration(moment(finishTime).diff(moment(startTime))))}
            />
        </>
    );
};
