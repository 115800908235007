import {Box, IconButton, InputAdornment, InputLabel, TextField, TextFieldProps} from '@mui/material';
import {Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon} from '@mui/icons-material';
import {useController, UseControllerProps} from 'react-hook-form';
import TextMaskCustom from './TextMaskCustom';
import {IMaskInputProps} from 'react-imask/dist/mixin';
import {useState} from 'react';

type FormTextFieldProps = {
    label?: string;
    maskProps?: IMaskInputProps<any>;
};

export const FormTextField = (props: Omit<TextFieldProps, 'label'> & FormTextFieldProps & UseControllerProps) => {
    const {label, name, placeholder, maskProps, type = 'text', rules, ...rest} = props;
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const {
        field,
        fieldState: {error}
    } = useController({defaultValue: '', name, rules});
    return (
        <Box width={'100%'}>
            {label && <InputLabel error={!!error}>{label}</InputLabel>}
            <TextField
                {...field}
                onChange={(e) => {
                    const val = e.target.value;
                    if (type === 'number') {
                        field.onChange(val.replace(/[^0-9]/g, ''));
                    } else field.onChange(val);
                }}
                error={!!error}
                helperText={error?.message}
                fullWidth
                type={isPasswordShown || type === 'number' ? 'text' : type}
                placeholder={placeholder || label}
                InputProps={{
                    inputComponent: maskProps?.mask ? (TextMaskCustom as any) : undefined,
                    inputProps: maskProps,
                    endAdornment: type === 'password' && (
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='toggle password visibility'
                                onClick={() => setIsPasswordShown((prevState) => !prevState)}
                                edge='end'>
                                {isPasswordShown ? (
                                    <VisibilityOffIcon color={'primary'} />
                                ) : (
                                    <VisibilityIcon color={'primary'} />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                    ...props.InputProps
                }}
                {...rest}
            />
        </Box>
    );
};
