import {Box, Stack, Table, TableBody, TableCell, TableRow} from '@mui/material';
import {green, grey} from '@mui/material/colors';
import {TextRow} from './components';
import {BlockType} from './types';

interface MemoryResultsType extends BlockType {
    Numbers: number[][];
    Answers: string[][];
    Result: {
        Score: number;
        FinalScore: number;
        Text: string;
    };
}

export const MemoryResults = ({data}: {data: MemoryResultsType}) => {
    const results = data.Result;
    return (
        <Box>
            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} mb={2}>
                {Array.from({length: 3}).map((_, tableIndex) => (
                    <Table
                        border={1}
                        sx={{borderSpacing: 0, borderColor: grey[200], width: 'auto', maxWidth: 228}}
                        key={tableIndex}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4} sx={{textAlign: 'center', fontSize: 16, py: 0.5}}>
                                    {tableIndex + 1}
                                </TableCell>
                            </TableRow>
                            {Array.from({length: 3}).map((_, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {Array.from({length: 4}).map((_, colIndex) => {
                                        const numberIndex = rowIndex * 4 + colIndex;
                                        const number = data.Numbers[tableIndex][numberIndex];
                                        return (
                                            <TableCell
                                                key={numberIndex}
                                                sx={{
                                                    textAlign: 'center',
                                                    fontSize: 24,
                                                    boxSizing: 'content-box',
                                                    p: 1,
                                                    backgroundColor: data.Answers[tableIndex]?.includes(
                                                        number.toString()
                                                    )
                                                        ? green[500]
                                                        : 'inherit'
                                                }}
                                                width={40}
                                                height={40}>
                                                {number}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ))}
            </Stack>
            {/*<TextRow text={'Количество правильных ответов в таблице 1'} value={results[0]} />*/}
            {/*<TextRow text={'Количество правильных ответов в таблице 2'} value={results[1]} />*/}
            {/*<TextRow text={'Количество правильных ответов в таблице 3'} value={results[2]} />*/}
            <TextRow text={'Итоговый балл'} value={results.FinalScore} />
            <TextRow text={'Описание'} value={results.Text} />
        </Box>
    );
};
