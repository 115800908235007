export const ROUTES = {
    EXAMS: '/exams',
    PARTICIPANTS: '/participants',
    CATEGORIES: '/categories',
    USERS: '/users',
    ROLES: '/roles',
    ZUN_TOPICS: '/zun-topics',
    PSY_TOPICS: '/psy-topics',
    SIGNATORY: '/signatory',
};
