import React, {PropsWithChildren, ReactNode} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    Divider,
    IconButton,
    Typography
} from '@mui/material';
import {LoadingButton} from '@mui/lab';

export type CustomModalProps = {
    handleClose: () => void;
    title: string;
    subtitle?: string;
    handleSubmit?: () => void;
    actionText?: string;
    loading?: boolean;
    buttonText?: string;
    divider?: boolean;
    noActions?: boolean;
    actions?: ReactNode;
};

const CustomModal = ({
    handleClose,
    open,
    title,
    subtitle,
    children,
    buttonText = 'Сохранить',
    handleSubmit,
    divider,
    loading,
    maxWidth,
    actions,
    noActions,
    fullWidth = true,
    ...rest
}: PropsWithChildren<CustomModalProps & DialogProps>) => {
    return (
        <Dialog onClose={handleClose} open={open} sx={{p: 3}} fullWidth={fullWidth} maxWidth={maxWidth} {...rest}>
            <DialogTitle id='dialog-title' component={'div'}>
                <Typography variant={'h3'} mb={subtitle ? 1 : 0}>
                    {title}
                </Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
                <IconButton
                    aria-label='close'
                    onClick={handleClose}
                    sx={{position: 'absolute', right: 8, top: 8, color: 'grey.400'}}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            {divider && <Divider />}
            <DialogContent sx={{pb: noActions ? 3 : 0}}>{children}</DialogContent>
            {!noActions && (
                <DialogActions sx={{py: 2, px: 3}}>
                    {actions ? (
                        actions
                    ) : (
                        <>
                            <Button onClick={handleClose}>Отмена</Button>
                            <LoadingButton autoFocus onClick={handleSubmit} variant={'contained'} loading={loading}>
                                {buttonText}
                            </LoadingButton>
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CustomModal;
