import {formatDate} from 'helpers';
import {IconButton, Tooltip} from '@mui/material';
import {useNavigate} from 'react-router';
import {Edit as EditIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {PermissionName, RoleQuery} from '@generated/graphql';
import {useAuthContext} from 'context/authContext';

export const tableConfig = (): Column<RoleQuery['role']>[] => {
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();
    return [
        {
            header: 'Роль',
            render: (role) => role?.displayName
        },
        {
            header: 'Дата создания',
            render: (role) => formatDate(role?.createdAt)
        },
        {
            header: 'Кол-во пользователей',
            render: (role) => role?.users?.length
        },
        {
            header: 'Действие',
            hide: !hasPermission(PermissionName.EditUserRoles),
            render: (role) => (
                <Tooltip title={'Редактировать'}>
                    <IconButton
                        color={'primary'}
                        onClick={() => {
                            navigate(role?.id);
                        }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];
};
