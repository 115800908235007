import React from 'react';
import {useForm} from 'react-hook-form';
import {Box, Container, Stack} from '@mui/material';
import {Form, FormTextField} from 'components/form';
import {yupResolver} from '@hookform/resolvers/yup';
import {InferType, object, string} from 'yup';
import {useQsParams} from 'hooks/useQsParams';
import {LoadingButton} from '@mui/lab';
import {TokenModel, useLoginMutation} from '@generated/graphql';
import cookies from 'js-cookie';
import {NotificationType, useNotify} from 'hooks';
import logo from 'assets/logo_image.png';

const schema = object({
    email: string().required(' '),
    password: string().required(' ')
});

const Login = () => {
    const form = useForm({
        resolver: yupResolver(schema)
    });
    const {handleSubmit} = form;
    const [login, {loading}] = useLoginMutation();
    const [params] = useQsParams();
    const notify = useNotify();

    const onSubmit = (data: InferType<typeof schema>) => {
        login({variables: {email: data.email, password: data.password}})
            .then((res) => {
                const tokens = res.data?.login as TokenModel;
                Object.keys(tokens).forEach((key) => {
                    cookies.set(key, tokens[key as keyof TokenModel]!);
                });
                window.location.href = params.redirectUrl;
            })
            .catch(() => {
                notify({type: NotificationType.ERROR});
            });
    };

    return (
        <Container maxWidth={'xs'} sx={{height: '100vh', display: 'flex'}}>
            <Box sx={{my: 'auto', width: '100%'}}>
                <Form form={form} onSubmit={handleSubmit(onSubmit)}>
                    <img src={logo} alt={'logo'} width={'100%'} />
                    <Stack spacing={2}>
                        <FormTextField name={'email'} label={'Email'} />
                        <FormTextField name={'password'} label={'Пароль'} type={'password'} />
                    </Stack>
                    <LoadingButton type={'submit'} loading={loading} variant={'contained'} sx={{mt: 3, width: '100%'}}>
                        Войти
                    </LoadingButton>
                </Form>
            </Box>
        </Container>
    );
};

export default Login;
