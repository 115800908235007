import React from 'react';
import {Breakpoint, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {LoadingButton} from '@mui/lab';

export type DeleteModalProps = {
    open: boolean;
    onClose: () => void;
    title: string;
    description?: string;
    handleClick: () => void;
    actionText?: string;
    color?: 'inherit' | 'error' | 'primary' | 'secondary' | 'success' | 'info' | 'warning' | undefined;
    loading?: boolean;
    maxWidth?: Breakpoint;
};

const DeleteModal = ({
    open,
    onClose,
    title,
    description,
    handleClick,
    actionText,
    color,
    loading,
    maxWidth = 'sm'
}: DeleteModalProps) => {
    return (
        <form>
            <Dialog open={open} onClose={onClose} maxWidth={maxWidth}>
                <DialogTitle id='alert-dialog-title' typography={'h4'}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{py: 2, px: 3, pt: 0}}>
                    <Button onClick={onClose}>Отменить</Button>
                    <LoadingButton
                        onClick={handleClick}
                        color={color || 'error'}
                        variant={color ? 'contained' : 'text'}
                        loading={loading || false}>
                        {actionText || 'Удалить'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </form>
    );
};

export default DeleteModal;
