import React from 'react';
import {Box} from '@mui/material';
import {TextRow, TimeBlock} from './components';
import {grey} from '@mui/material/colors';
import {Pie} from 'react-chartjs-2';
import {BlockType} from './types';

export interface DegreeOfChronicFatigueResultsType extends BlockType {
    Result: {
        Score: number;
        MaxScore: number;
        Title: string;
        Color: string;
    };
}

export const DegreeOfChronicFatigueResults = ({data}: {data: DegreeOfChronicFatigueResultsType}) => {
    const result = data.Result;

    return (
        <Box>
            <TextRow text={'Степень хронического утомления'} value={result.Title} />
            <TimeBlock startTime={data.StartTime} finishTime={data.FinishTime} />
            <Box maxHeight={450} mt={-10}>
                <Pie
                    data={{
                        labels: ['Процент утомляемости'],
                        datasets: [
                            {
                                data: [result.Score, result.MaxScore - result.Score],
                                backgroundColor: [result.Color, grey[300]],
                                hoverBackgroundColor: [result.Color, grey[300]],
                                borderWidth: 0
                            }
                        ]
                    }}
                    options={{
                        plugins: {
                            legend: {
                                position: 'right'
                            },
                            datalabels: {
                                align: 'center' as const,
                                formatter: (value: any) => Math.round((value / result.MaxScore) * 100) + '%',
                                color: '#fff',
                                font: {
                                    size: 22,
                                    weight: 'bold'
                                }
                            }
                        }
                    }}
                />
            </Box>
        </Box>
    );
};
