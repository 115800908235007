import React, {useEffect} from 'react';
import {Button, Card, Stack, Typography} from '@mui/material';
import {useFieldArray} from 'react-hook-form';
import {ACTION, ActionButton} from 'components';
import {Question} from './Question';

export const QuestionsBank = () => {
    const {fields, append, remove} = useFieldArray({name: 'questions'});
    const handleAppend = () => append({title: '', content: '', type: null});

    useEffect(() => {
        !fields.length && handleAppend();
    }, []);

    return (
        <Card>
            <Typography variant={'h3'}>Банк вопросов</Typography>
            <Stack spacing={2} mt={2}>
                {fields.map((field, index) => (
                    <Stack direction={'row'} key={field.id} spacing={1}>
                        <Question questionIndex={index} />
                        {fields.length > 1 && <ActionButton onClick={() => remove(index)} type={ACTION.DELETE} />}
                    </Stack>
                ))}
            </Stack>
            <Button onClick={handleAppend} variant={'outlined'} sx={{mt: 2}}>
                Добавить вопрос
            </Button>
        </Card>
    );
};
