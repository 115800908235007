import {Button, Card, Popover, Stack} from '@mui/material';
import React, {useEffect} from 'react';
import {SubmitHandler, useFormContext} from 'react-hook-form';
import {Form} from 'components/form';
import {generateQueryOnSubmit, onClearFilter, onUseEffect} from '../generators';
import GeneratedInput from '../generators/inputsGenerator';
import {useQsParams} from 'hooks/useQsParams';

type FiltersPopoverProps = {
    filters: any;
    defaultValues?: any;
    handleClose: () => void;
    anchorEl: HTMLButtonElement | null;
};
const FiltersPopover = ({filters, anchorEl, handleClose}: FiltersPopoverProps) => {
    const form = useFormContext<any>();
    const {reset, handleSubmit} = form;
    const [params, setParams] = useQsParams();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onSubmit: SubmitHandler<typeof form> = (data: typeof form) => {
        generateQueryOnSubmit(data, filters, setParams, params);
        handleClose();
    };

    const onClear = () => {
        onClearFilter(filters, reset, setParams);
        handleClose();
    };

    useEffect(() => {
        onUseEffect(filters, reset, params);
    }, [params]);

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            elevation={4}
            PaperProps={{sx: {width: 400}}}
            keepMounted
            disableRestoreFocus
            onClose={handleClose}
            anchorOrigin={{vertical: 'center', horizontal: 'left'}}
            transformOrigin={{vertical: 'center', horizontal: 'right'}}>
            <Form form={form} onSubmit={handleSubmit(onSubmit)}>
                <Card sx={{width: 400}}>
                    <Stack
                        direction={'column'}
                        justifyContent={'space-between'}
                        height={'100%'}
                        alignItems={'stretch'}
                        spacing={2}>
                        {filters.map((input: any, i: number) => (
                            <GeneratedInput key={i} input={input} />
                        ))}
                        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} mt={2}>
                            <Button onClick={onClear}>Сбросить</Button>
                            <Button autoFocus variant={'contained'} type={'submit'}>
                                Применить
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
            </Form>
        </Popover>
    );
};

export default FiltersPopover;
