import {useCallback, useState} from 'react';

export type ModalProps = {
    open: boolean;
    handleToggle: () => void;
};
export const useModal = (): ModalProps => {
    const [open, setOpen] = useState(false);
    const handleToggle = useCallback(() => setOpen((prevState) => !prevState), [open]);

    return {
        handleToggle,
        open
    };
};
