import {ExamStatus, ExamType, ParticipantExamStatus, PsychologyTestType, QuestionType, Sex} from '@generated/graphql';
import {amber, blue, green, grey, red} from '@mui/material/colors';

export const QuestionTypeMap = {
    [QuestionType.TestQuestion]: 'Тестовый вопрос',
    [QuestionType.ThematicQuestion]: 'Тематический вопрос',
    [QuestionType.AppliedTask]: 'Практическая задача'
};

export const SexMap = {
    [Sex.Male]: 'Мужской',
    [Sex.Female]: 'Женский'
};

export const ExamStatusMap = {
    [ExamStatus.Planned]: {
        text: 'Запланирована',
        color: blue[500]
    },
    [ExamStatus.Canceled]: {
        text: 'Отменена',
        color: red[500]
    },
    [ExamStatus.Finished]: {
        text: 'Отменена',
        color: green[500]
    }
};

export const ExamTypeMap = {
    [ExamType.KnowledgeSkillTest]: 'Проверка знаний, умений и навыков',
    [ExamType.Physical]: 'Проверка соответствия уровня физической подготовки',
    [ExamType.Psychology]: 'Проверка соответствия личностных (психофизиологических) качеств'
};
export const ExamTypeShortMap = {
    [ExamType.KnowledgeSkillTest]: 'ЗУН',
    [ExamType.Physical]: 'ФИЗО',
    [ExamType.Psychology]: 'ПФО'
};

export const ParticipantExamStatusMap = {
    [ParticipantExamStatus.HasResult]: {
        text: 'Получены',
        color: amber[500]
    },
    [ParticipantExamStatus.HasNoResult]: {
        text: 'Не получены',
        color: grey[500]
    },
    [ParticipantExamStatus.Approved]: {
        text: 'Соответствуют',
        color: green[500]
    },
    [ParticipantExamStatus.NotApproved]: {
        text: 'Не соответствуют',
        color: red[500]
    }
};

// export const GENERATION_STATUS_ENUM = {
//     [GenerationStatus.Generated]: 'Билеты сгенерированы',
//     [GenerationStatus.NotGenerated]: 'Билеты не сгенерированы'
// };
export const PsychologyTestTypeMap = {
    [PsychologyTestType.Raven]: 'Прогрессивные матрицы Равена',
    [PsychologyTestType.SubjectiveLevelOfControl]: 'Уровень субъективного контроля',
    [PsychologyTestType.DifficultAssociation]: 'Сложные аналогии',
    [PsychologyTestType.DegreeOfChronicFatigue]: 'Степень хронического утомления',
    [PsychologyTestType.Kettel]: 'Опросник Кеттелла',
    [PsychologyTestType.Mmpi]: 'ММИЛ',
    [PsychologyTestType.LandoltCircles]: 'Кольца Ландольта',
    [PsychologyTestType.LuscherColors]: 'Цветовой тест Люшера',
    [PsychologyTestType.SchulteTables]: 'Таблицы Шульте',
    [PsychologyTestType.Bourdon]: 'Корректурная проба Бурдона',
    [PsychologyTestType.Memory]: 'Тест на память'
};
