import {Button, Container, Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router';
import TableLayout from 'components/table/TableLayout';
import {tableConfig} from './config';
import {PermissionName, useRolesQuery} from '@generated/graphql';
import {useAuthContext} from 'context/authContext';

export const Roles = () => {
    const {data, loading, error, refetch} = useRolesQuery();
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();

    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'}>
                <Typography variant={'h2'}>Список ролей</Typography>
                {hasPermission(PermissionName.EditUserRoles) && (
                    <Button variant={'contained'} onClick={() => navigate('create')}>
                        Добавить роль
                    </Button>
                )}
            </Stack>
            <TableLayout loading={loading || !!error} data={data?.roles} columns={tableConfig()} refetch={refetch} />
        </Container>
    );
};
