import {red} from '@mui/material/colors';
import {Palette} from '@mui/material';

export default {
    primary: {
        main: '#0069ff'
    },
    secondary: {
        main: '#19857b'
    },
    error: {
        main: red.A400
    }
} as Palette;
