import {Box, Button, Typography} from '@mui/material';
import {FilterList as FilterListIcon} from '@mui/icons-material';
import React from 'react';
import {useQsParams} from 'hooks/useQsParams';

type CounterIconProps = {counter: number};
const CounterIcon = ({counter}: CounterIconProps) => {
    return counter ? (
        <Box sx={{backgroundColor: 'grey.900', width: 16, height: 16, borderRadius: 16}}>
            <Typography fontSize={12} lineHeight={'16px'} color='white'>
                {counter}
            </Typography>
        </Box>
    ) : null;
};

type FilterButtonProps = {onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void};
const FilterButton = ({onClick}: FilterButtonProps) => {
    const [params] = useQsParams();
    return (
        <Button
            variant='contained'
            sx={{lineHeight: 0}}
            startIcon={<FilterListIcon />}
            color={'inherit'}
            endIcon={params.where ? <CounterIcon counter={Object.keys(params.where || {}).length} /> : null}
            onClick={onClick}>
            Фильтры
        </Button>
    );
};

export default FilterButton;
