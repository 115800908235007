import {
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';
import {useQsParams} from 'hooks/useQsParams';
import React, {useEffect, useMemo} from 'react';
import {Filters} from '../filters';
import TableSortLabel from './TableSortLabel';
import {SkeletonTable} from './Skeleton';
import {useSorting} from 'hooks';
import {generateFilterQuery} from '../generators';
import {merge} from 'lodash';

export type Column<T> = {
    header: React.ReactNode;
    hide?: boolean;
    name?: string;
    render: (item: T) => React.ReactNode;
};

type PageInfo = {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
};

type PagedModel<T> = {
    pageInfo?: PageInfo;
    totalCount?: number;
    items?: T[] | null;
};

type TableLayoutProps<T> = {
    data?: PagedModel<T> | null;
    columns: Column<T>[];
    filters?: any;
    pagination?: boolean;
    loading?: boolean;
    defaultFilter?: any;
    refetch?: (...args: any) => void;
};

const TableLayout = <T extends {id?: string}>({
    data,
    columns,
    filters,
    pagination = true,
    loading,
    refetch = () => {},
    defaultFilter = {}
}: TableLayoutProps<T>) => {
    const [params, setParams] = useQsParams();
    const [limit, page] = [Number(params.limit) || defaultFilter.take || 10, Number(params.page) || 0];

    const sortingParams = useSorting();
    const filteringParams = useMemo(() => (filters ? generateFilterQuery(filters, params) : {}), [params]);

    useEffect(() => {
        refetch(merge(defaultFilter, {...sortingParams, where: filteringParams, skip: page * limit, take: +limit}));
    }, [params]);

    const changePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
        setParams({...params, page: newPage});

    const changeLimit = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        setParams({
            ...params,
            limit: event.target.value,
            page: 0
        });

    return (
        <>
            <Stack direction={'row'} justifyContent={'flex-end'}>
                {filters && <Filters filters={filters} />}
            </Stack>
            <TableContainer component={Paper} variant={'outlined'} sx={{mt: 2}}>
                <Table sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            {columns.map(
                                (item, index) =>
                                    !item.hide && (
                                        <TableCell key={index}>
                                            {item.name ? <TableSortLabel key={index} item={item} /> : item.header}
                                        </TableCell>
                                    )
                            )}
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <SkeletonTable rows={limit} columns={columns} />
                    ) : (
                        <TableBody>
                            {data?.items?.length ? (
                                data?.items?.map((row, index) => (
                                    <TableRow key={row?.id || index}>
                                        {columns.map(
                                            (item, index) =>
                                                !item.hide && <TableCell key={index}>{item.render(row)}</TableCell>
                                        )}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell sx={{textAlign: 'center'}} colSpan={columns?.length}>
                                        Нет данных
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
            {pagination && (
                <TablePagination
                    component='div'
                    count={data?.totalCount || 0}
                    page={page}
                    onPageChange={changePage}
                    rowsPerPage={limit}
                    onRowsPerPageChange={changeLimit}
                    SelectProps={{
                        size: 'medium'
                    }}
                    labelRowsPerPage={'Строк на странице'}
                    labelDisplayedRows={({from, to, count}) => `${from}–${to} из ${count}`}
                />
            )}
        </>
    );
};

export default TableLayout;
