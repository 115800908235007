import React from 'react';
import {Box} from '@mui/material';
import {Pie} from 'react-chartjs-2';
import {green, red} from '@mui/material/colors';
import {DifficultAssociationResultsType} from '../DifficultAssociationResults';
import {RavenResultsType} from '../RavenResults';

export const CorrectAnswersPieChart = (props: {
    result: RavenResultsType['Result'] | DifficultAssociationResultsType['Result'];
}) => {
    const {result} = props;

    return (
        <Box maxHeight={450} mt={-10}>
            <Pie
                data={{
                    labels: ['Правильные ответы', 'Неправильные ответы'],
                    datasets: [
                        {
                            data: [result.Score, result.MaxScore - result.Score],
                            backgroundColor: [green[300], red[300]],
                            hoverBackgroundColor: [green[300], red[300]],
                            borderWidth: 0
                        }
                    ]
                }}
                options={{
                    plugins: {
                        legend: {
                            position: 'right'
                        },
                        datalabels: {
                            align: 'center' as const,
                            formatter: (value: any) => Math.round((value / result.MaxScore) * 10000) / 100 + '%',
                            color: '#fff',
                            font: {
                                size: 22,
                                weight: 'bold'
                            }
                        }
                    }
                }}
            />
        </Box>
    );
};
