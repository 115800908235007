import React, {useContext, useMemo, useState} from 'react';
import CustomModal from 'components/modals/CustomModal';
import {tableConfig} from './config';
import TableLayout from 'components/table/TableLayout';
import {useAddManyParticipantToExamMutation, useParticipantsQuery} from '@generated/graphql';
import {ModalProps, NotificationType, useNotify, useQsParams} from 'hooks';
import {filterFields} from '../../../../../../Participant/list/config';
import {ExamContext, useExamContext} from '../../../../Exam';

const AddParticipantModal = ({open, handleToggle}: ModalProps) => {
    const {exam} = useExamContext();
    const [params, setParams] = useQsParams();
    const filters = filterFields();
    const [checked, setChecked] = useState<string[]>([]);
    const {data, loading, error, refetch} = useParticipantsQuery();
    const participantIds = useMemo(() => (data?.participants?.items?.map((i) => i.id) as string[]) || [], [data]);
    const {refetch: refetchExam} = useContext(ExamContext);
    const [addManyParticipantToExam, {loading: loadingMutation}] = useAddManyParticipantToExamMutation();
    const notify = useNotify();

    const handleClose = () => {
        handleToggle();
        setParams({});
    };

    const handleSubmit = () => {
        if (checked.length)
            addManyParticipantToExam({variables: {model: {examId: exam?.id, participantIds: checked}}}).then(() => {
                notify({type: NotificationType.SUCCESS});
                refetchExam().then(() => handleClose());
            });
        else notify({text: 'Выберите хотя бы одного аттестуемого', type: NotificationType.ERROR});
    };

    return (
        <CustomModal
            handleClose={handleClose}
            title={'Добавить аттестуемого'}
            loading={loadingMutation}
            maxWidth={'md'}
            handleSubmit={handleSubmit}
            open={open}>
            <TableLayout
                data={data?.participants}
                loading={loading || !!error}
                defaultFilter={{
                    where: {
                        participantExams: {
                            all: {or: [{examId: {neq: exam?.id}}, {isDeleted: {eq: true}}]}
                        }
                    }
                }}
                refetch={refetch}
                columns={tableConfig(checked, setChecked, participantIds)}
                filters={filters}
            />
        </CustomModal>
    );
};

export default AddParticipantModal;
