import {FormControlLabel, FormControlLabelProps, Radio} from '@mui/material';
import {useController, UseControllerProps} from 'react-hook-form';
import React from 'react';

export const FormRadio = ({
    name,
    label = '',
    sx,
    disabled,
    onChange,
    rules,
    value
}: UseControllerProps & Partial<FormControlLabelProps>) => {
    const {field} = useController({name, rules, defaultValue: false});
    return (
        <FormControlLabel
            value={value}
            onChange={(e: any, checked) => (onChange ? onChange(e, checked) : field.onChange(e.target.checked))}
            checked={field.value}
            sx={{...sx, ml: '-11px !important'}}
            control={<Radio />}
            label={label}
            disabled={disabled}
        />
    );
};
