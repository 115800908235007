import React from 'react';
import {useRoutes} from 'react-router';
import {routes} from './routes';
import {ApolloProvider} from '@apollo/client';
import {client} from 'config/apolloConfig';
import {AuthProvider} from 'context/authContext';
import {ToastProvider} from 'context/toastContext';
import {ThemeProvider} from '@mui/material';
import {theme} from 'theme';
import {ModalProvider} from 'context/modalContext';

function App() {
    const content = useRoutes(routes);
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <ToastProvider>
                        <ModalProvider>{content}</ModalProvider>
                    </ToastProvider>
                </AuthProvider>
            </ThemeProvider>
        </ApolloProvider>
    );
}

export default App;
