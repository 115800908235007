import {ReactNode} from 'react';
import {Box, CircularProgress} from '@mui/material';

type CircularLoadingProps = {
    children: ReactNode;
    loading: boolean;
};

const CircularLoading = ({children, loading}: CircularLoadingProps) => {
    return (
        <>
            {loading ? (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress />
                </Box>
            ) : (
                children
            )}
        </>
    );
};

export default CircularLoading;
