import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {ParticipantExamStatus} from '@generated/graphql';
import {useFormContext, useWatch} from 'react-hook-form';
import {useEffect} from 'react';
import {TextRow} from '../components/TextRow';
import {ParticipantExamStatusMap} from 'constants/index';
import {ExerciseStatus, ExerciseTableRow} from './ExerciseTableRow';

export const PhysicalResults = () => {
    const {setValue, watch} = useFormContext();

    const watchFirstStatus = useWatch({name: 'attr[0].status'});
    const watchSecondStatus = useWatch({name: 'attr[1].status'});

    const watchDecision = watch('decision') as ParticipantExamStatus;

    useEffect(() => {
        if (watchFirstStatus === ExerciseStatus.Passed && watchSecondStatus === ExerciseStatus.Passed) {
            setValue('decision', ParticipantExamStatus.Approved);
        } else {
            setValue('decision', ParticipantExamStatus.NotApproved);
        }
    }, [watchFirstStatus, watchSecondStatus]);
    return (
        <>
            <TableContainer component={Paper} variant={'outlined'} sx={{my: 2}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>№</TableCell>
                            <TableCell>Название упражнения</TableCell>
                            <TableCell>Норматив</TableCell>
                            <TableCell>Результат</TableCell>
                            <TableCell>Статус</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from({length: 2}).map((i, index) => (
                            <ExerciseTableRow index={index} key={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TextRow text={'Соответствие требованиям'} value={ParticipantExamStatusMap[watchDecision]?.text || ''} />
        </>
    );
};
