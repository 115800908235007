import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {Edit as EditIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {ExamsItemFragment, PermissionName} from '@generated/graphql';
import {formatDate, getEnumArray} from 'helpers';
import {ExamStatusMap, ExamTypeMap} from 'constants/index';
import {useNavigate} from 'react-router';
import {FILTER_TYPES, INPUT_TYPES} from 'components/generators';
import {Label} from 'components/Label';
import React from 'react';
import {useAuthContext} from 'context';

export const tableConfig = (): Column<ExamsItemFragment>[] => {
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();
    return [
        {
            header: 'Название',
            render: (exam) => exam.title,
            name: 'title'
        },
        {
            header: 'Дата начала',
            render: (exam) => formatDate(exam.plannedStartDate, 'DD.MM.YYYY'),
            name: 'plannedStartDate'
        },
        {
            header: 'Площадка',
            render: (exam) => exam.place,
            name: 'place'
        },
        {
            header: 'Тип',
            render: (exam) => (
                <Typography variant={'body2'} maxWidth={300}>
                    {exam.type && ExamTypeMap[exam.type]}
                </Typography>
            ),
            name: 'status'
        },
        {
            header: 'Статус',
            // render: (exam) => exam.status && ExamStatusMap[exam.status],
            render: (exam) => (
                <Label color={ExamStatusMap[exam?.status].color}>{ExamStatusMap[exam?.status].text}</Label>
            ),
            name: 'status'
        },
        {
            header: 'Действие',
            hide: !hasPermission(PermissionName.EditExams),
            render: (exam) => (
                <Stack direction={'row'} spacing={1}>
                    <Tooltip title={'Редактировать'}>
                        <IconButton color={'primary'} onClick={() => navigate(exam.id)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        }
    ];
};

export const filterFields = () => {
    return [
        {
            name: 'title',
            label: 'Название аттестации',
            inputType: INPUT_TYPES.TEXT,
            filterType: FILTER_TYPES.STRING_FILTER
        },
        {
            name: 'place',
            label: 'Площадка проведения аттестации',
            inputType: INPUT_TYPES.TEXT,
            filterType: FILTER_TYPES.STRING_FILTER
        },
        {
            name: 'plannedStartDate',
            label: 'Дата планового начала аттестации',
            inputType: INPUT_TYPES.DATE,
            filterType: FILTER_TYPES.DATE_FILTER
        },
        {
            name: 'status',
            label: 'Статус проведения аттестации',
            inputType: INPUT_TYPES.AUTOCOMPLETE,
            options: getEnumArray(ExamStatusMap, 'text'),
            filterType: FILTER_TYPES.ENUM_FILTER
        }
    ];
};
