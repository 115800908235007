import {createTheme, ThemeOptions} from '@mui/material';
import components from './components';
import typography from './typography';
import palette from './palette';
import shadows from './shadows';
// noinspection ES6UnusedImports
import {} from '@mui/lab/themeAugmentation';

export const theme: ThemeOptions = createTheme({
    palette,
    components,
    typography,
    shadows
});
