import React from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow, useTheme} from '@mui/material';

import {TimeBlock} from './components';
import {Line} from 'react-chartjs-2';
import {BlockType} from './types';

export const kettelBaseFactors = [
    'A',
    'B',
    'C',
    'E',
    'F',
    'G',
    'H',
    'I',
    'L',
    'M',
    'N',
    'O',
    'Q1',
    'Q2',
    'Q3',
    'Q4'
] as const;

export const kettelAdditionalFactors = ['F1', 'F2', 'F3', 'F4'] as const;

export type KettelBaseFactorsKeys = (typeof kettelBaseFactors)[number];

export type KettelAdditionalFactorsKeys = (typeof kettelAdditionalFactors)[number];

export type KettelBaseFactorsResult = {[key in KettelBaseFactorsKeys]: number};

export type KettelAdditionalFactorsResult = {[key in KettelAdditionalFactorsKeys]: number};

type MergedKettelKeys = KettelAdditionalFactorsKeys | KettelBaseFactorsKeys;

const SCALE: {[K in MergedKettelKeys]: string} = {
    A: 'Замкнутость - общительность',
    B: 'Интеллект',
    C: 'Эмоциональная стабильность',
    E: 'Подчиненность - доминантность',
    F: 'Сдержанность - экспрессивность',
    G: 'Моральная нормативность',
    H: 'Робость - смелость',
    I: 'Практицизм - чувствительность',
    L: 'Доверчивость - подозрительность',
    M: 'Практичность - мечтательность',
    N: 'Прямолинейность - дипломатичность',
    O: 'Спокойствие - тревожность',
    Q1: 'Консерватизм - радикализм',
    Q2: 'Конформизм - нонконформизм',
    Q3: 'Самоконтроль',
    Q4: 'Расслабленность - напряженность',
    F1: 'Тревога',
    F2: 'Интроверсия - экстраверсия',
    F3: 'Чувствительность - уравновешенность',
    F4: 'Конформность - независимость'
};

interface KettelResultsType extends BlockType {
    Result: {
        Raw: KettelBaseFactorsResult;
        Levels: KettelBaseFactorsResult & KettelAdditionalFactorsResult;
    };
}

export const KettelResults = ({data}: {data: KettelResultsType}) => {
    const theme = useTheme();
    const result = data.Result;

    const mergedKettelFactors = [...kettelBaseFactors, ...kettelAdditionalFactors];

    return (
        <Box whiteSpace={'pre'}>
            <TimeBlock startTime={data.StartTime} finishTime={data.FinishTime} />
            <Line
                style={{marginTop: 16}}
                options={{
                    scales: {
                        x: {
                            labels: mergedKettelFactors
                        },
                        x1: {
                            labels: mergedKettelFactors.map((key) => result.Levels[key].toString()),
                            ticks: {
                                color: theme.palette.primary.main
                            }
                        },
                        y: {
                            min: 1,
                            max: 10,
                            ticks: {
                                stepSize: 1
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            display: false
                        },
                        annotation: {
                            annotations: {
                                annotation1: {
                                    type: 'box',
                                    backgroundColor: 'rgba(165, 214, 167, 0.2)',
                                    borderColor: 'rgba(165, 214, 167, 0.2)',
                                    yMax: 3,
                                    yMin: 8
                                },
                                annotation2: {
                                    type: 'box',
                                    backgroundColor: 'rgba(255, 255, 0, 0.1)',
                                    borderColor: 'rgba(255, 255, 0, 0.1)',
                                    yMax: 2,
                                    yMin: 3
                                },
                                annotation3: {
                                    type: 'box',
                                    backgroundColor: 'rgba(255, 255, 0, 0.1)',
                                    borderColor: 'rgba(255, 255, 0, 0.1)',
                                    yMax: 8,
                                    yMin: 9
                                },
                                annotation4: {
                                    type: 'box',
                                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    borderColor: 'rgba(255, 0, 0, 0.1)',
                                    yMax: 9,
                                    yMin: 10
                                },
                                annotation5: {
                                    type: 'box',
                                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                    borderColor: 'rgba(255, 0, 0, 0.1)',
                                    yMax: 1,
                                    yMin: 2
                                },
                                annotation6: {
                                    type: 'line',
                                    scaleID: 'y',
                                    borderColor: 'rgba(165, 214, 167, 0.8)',
                                    borderWidth: 2,
                                    value: 5.5
                                }
                            }
                        }
                    }
                }}
                data={{
                    datasets: [
                        {
                            data: mergedKettelFactors.map((key) => result.Levels[key]),
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main
                        }
                    ]
                }}
            />
            <Table
                sx={{
                    mt: 4,
                    pb: 1,
                    '& td, th': {
                        border: '1px solid rgba(224, 224, 224, 1) !important',
                        padding: 1
                    },
                    '& th': {
                        fontWeight: 600
                    }
                }}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Шкала</TableCell>
                        <TableCell>Сырой балл</TableCell>
                        <TableCell>Стен</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(Object.keys(SCALE) as MergedKettelKeys[]).map((key, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{color: 'primary.main'}}>{key}</TableCell>
                            <TableCell>{SCALE[key]}</TableCell>
                            <TableCell>{result.Raw[key as KettelBaseFactorsKeys] || '–'}</TableCell>
                            <TableCell>{result.Levels[key]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
};
