import {IconButton, Stack, Tooltip} from '@mui/material';
import {Delete as DeleteIcon, Description as DescriptionIcon, TrendingUp as TrendingUpIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {
    ExamType,
    ParticipantExamFragment,
    ParticipantExamStatus,
    PermissionName,
    useExportParticipantExamBlankMutation,
    useExportParticipantExamProtocolMutation,
    useRemoveParticipantToExamMutation
} from '@generated/graphql';
import {ModalContext} from 'context/modalContext';
import React, {useContext} from 'react';
import {ParticipantExamStatusMap, SexMap} from 'constants/index';
import {NotificationType, useNotify} from 'hooks';
import {formatDate} from 'helpers';
import moment from 'moment/moment';
import {Label} from 'components/Label';
import {useExamContext} from '../../Exam';
import {useAuthContext} from 'context/authContext';
import {saveAs} from 'file-saver';

export const tableConfig = (
    showPassword: boolean,
    handleOpenResultsModal: (id: string) => void
): Column<ParticipantExamFragment>[] => {
    const {exam, refetch} = useExamContext();
    const notify = useNotify();
    const {handleOpen} = useContext(ModalContext);
    const {hasPermission} = useAuthContext();
    const [removeParticipantToExam, {loading}] = useRemoveParticipantToExamMutation();
    const [exportBlank] = useExportParticipantExamBlankMutation();
    const [exportProtocol] = useExportParticipantExamProtocolMutation();

    const handleExportProtocol = (id: string, uin?: string) => {
        exportProtocol({variables: {participantExamId: id}})
            .then((res) => {
                res.data?.exportParticipantExamProtocol &&
                    saveAs(res.data?.exportParticipantExamProtocol, `Протокол_${uin}`);
                notify({type: NotificationType.SUCCESS});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };
    const handleExportBlank = (id: string, uin?: string) => {
        exportBlank({variables: {participantExamId: id}})
            .then((res) => {
                res.data?.exportParticipantExamBlank &&
                    saveAs(res.data?.exportParticipantExamBlank, `Бланк_допуска_${uin}`);
                notify({type: NotificationType.SUCCESS});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };

    return [
        {
            header: 'УИН',
            render: (participantExam) => participantExam?.participant?.uin
        },
        {
            header: 'Пол',
            render: (participantExam) => participantExam?.participant?.sex && SexMap[participantExam?.participant?.sex]
        },
        {
            header: 'Дата рождения',
            render: (participantExam) => formatDate(participantExam?.participant?.birthdayDate, 'DD.MM.YYYY')
        },
        {
            header: 'Возраст',
            render: (participantExam) =>
                participantExam?.participant?.birthdayDate &&
                moment().diff(participantExam?.participant?.birthdayDate, 'years')
        },
        {
            header: 'Категория',
            render: (participant) => participant?.participant?.category?.name
        },
        {
            header: 'Пароль',
            render: (participantExam) => {
                return showPassword
                    ? participantExam?.password.replaceAll(new RegExp('.', 'g'), '*')
                    : participantExam?.password;
            }
        },
        {
            header: 'Результаты',
            render: (participantExam) => (
                <Label color={ParticipantExamStatusMap[participantExam?.status].color}>
                    {ParticipantExamStatusMap[participantExam?.status].text}
                </Label>
            )
        },
        {
            header: 'Действие',
            render: (participantExam) => (
                <Stack direction={'row'}>
                    <Tooltip title={'Просмотреть результаты'}>
                        <IconButton
                            color={'primary'}
                            onClick={() => handleOpenResultsModal(participantExam.id)}
                            disabled={
                                participantExam.status === ParticipantExamStatus.HasNoResult &&
                                exam?.type !== ExamType.Physical
                            }>
                            <TrendingUpIcon />
                        </IconButton>
                    </Tooltip>
                    {hasPermission(PermissionName.EditExams) && (
                        <Tooltip title={'Исключить аттестуемого'}>
                            <IconButton
                                color={'primary'}
                                onClick={() => {
                                    handleOpen({
                                        title: 'Удаление аттестуемого',
                                        description: 'Вы уверены, что хотите исключить аттестуемого?',
                                        handleClick: () =>
                                            removeParticipantToExam({
                                                variables: {participantExamId: participantExam?.id}
                                            }).then(() => {
                                                refetch();
                                                notify({type: NotificationType.SUCCESS});
                                            }),
                                        loading
                                    });
                                }}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Tooltip title={'Печать бланка допуска'}>
                        <IconButton
                            color={'primary'}
                            onClick={() => handleExportBlank(participantExam.id, participantExam.participant?.uin)}>
                            <DescriptionIcon />
                        </IconButton>
                    </Tooltip>
                    {(participantExam.status === ParticipantExamStatus.Approved ||
                        participantExam.status === ParticipantExamStatus.NotApproved) && (
                        <Tooltip title={'Печать протокола'}>
                            <IconButton
                                color={'primary'}
                                onClick={() =>
                                    handleExportProtocol(participantExam.id, participantExam.participant?.uin)
                                }>
                                <DescriptionIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            )
        }
    ];
};
