import {IconButton, Stack, Tooltip} from '@mui/material';
import {Delete, Edit as EditIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {
    ParticipantsItemFragment,
    PermissionName,
    useCategoriesQuery,
    useDeleteParticipantMutation
} from '@generated/graphql';
import {ModalContext} from 'context/modalContext';
import React, {useContext} from 'react';
import {SexMap} from 'constants/index';
import {FILTER_TYPES, INPUT_TYPES} from 'components/generators';
import {formatDate, getEnumArray} from 'helpers';
import moment from 'moment';
import {useApolloClient} from '@apollo/client';
import {useAuthContext} from 'context/authContext';

export const tableConfig = (
    handleOpen: (participant: ParticipantsItemFragment) => void
): Column<ParticipantsItemFragment>[] => {
    const {handleOpen: handleDeleteModal} = useContext(ModalContext);
    const [deleteParticipant, {loading}] = useDeleteParticipantMutation();
    const client = useApolloClient();
    const {hasPermission} = useAuthContext();

    return [
        {
            header: 'УИН',
            render: (participant) => participant?.uin,
            name: 'uin'
        },
        {
            header: 'Пол',
            render: (participant) => SexMap[participant!.sex],
            name: 'sex'
        },
        {
            header: 'Дата рождения',
            render: (participant) => formatDate(participant?.birthdayDate, 'DD.MM.YYYY'),
            name: 'birthdayDate'
        },
        {
            header: 'Возраст',
            render: (participant) => moment().diff(participant?.birthdayDate, 'years')
        },
        {
            header: 'Категория',
            render: (participant) => participant?.category?.name
        },
        {
            header: 'Действие',
            hide: !hasPermission(PermissionName.EditParticipants),
            render: (participant) => (
                <Stack direction={'row'} spacing={1}>
                    <Tooltip title={'Редактировать'}>
                        <IconButton color={'primary'} onClick={() => handleOpen(participant)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Удалить'}>
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                handleDeleteModal({
                                    title: 'Удаление аттестуемого',
                                    description:
                                        'Вы уверены, что хотите удалить данные аттестуемого? Отменить удаление будет невозможно.',
                                    handleClick: () => {
                                        deleteParticipant({variables: {id: participant?.id}}).then(() => {
                                            client.refetchQueries({include: ['participants']});
                                        });
                                    },
                                    loading
                                });
                            }}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        }
    ];
};

export const filterFields = () => {
    const {data: dataCategories} = useCategoriesQuery();
    const categories = dataCategories?.categories?.items || [];
    return [
        {
            name: 'uin',
            label: 'УИН',
            inputType: INPUT_TYPES.TEXT,
            filterType: FILTER_TYPES.STRING_FILTER
        },
        {
            name: 'sex',
            label: 'Пол аттестуемого',
            inputType: INPUT_TYPES.AUTOCOMPLETE,
            options: getEnumArray(SexMap),
            filterType: FILTER_TYPES.ENUM_FILTER
        },
        {
            name: 'birthdayDate',
            label: 'Дата рождения',
            inputType: INPUT_TYPES.DATE,
            filterType: FILTER_TYPES.DATE_FILTER
        },
        {
            name: 'category.id',
            label: 'Категория',
            inputType: INPUT_TYPES.AUTOCOMPLETE,
            options: categories.map((category) => ({
                id: category.id,
                displayName: category.name
            })),
            filterType: FILTER_TYPES.ENUM_FILTER
        }
    ];
};
