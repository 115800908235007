import React from 'react';
import {Box, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from '@mui/material';
import {TimeBlock} from './components';
import {Line} from 'react-chartjs-2';
import {BlockType} from './types';

export const mmpiScales = ['L', 'F', 'K', 'Hs', 'D', 'Hy', 'Pd', 'M', 'Pa', 'Pt', 'Sc', 'Ma', 'Si'] as const;

export type MmpiScalesKeys = (typeof mmpiScales)[number];

export type MmpiResult = {[key in MmpiScalesKeys]: number};

const SCALE_DESCRIPTION = {
    additional: [
        'Шкала «лжи» <span>(L)</span> — предназначена для оценки искренности обследуемого.',
        'Шкала достоверности <span>(F)</span> — создана для выявления недостоверных результатов (связанных с небрежностью обследуемого), а также аггравации и симуляции.',
        'Шкала коррекции <span>(K)</span> — введена для того, чтобы сгладить искажения, вносимые чрезмерной недоступностью и осторожностью обследуемого.'
    ],
    main: [
        'Шкала ипохондрии <span>(Hs)</span> — определяет «близость» обследуемого к астено-невротическому типу личности.',
        'Шкала депрессии <span>(D)</span> — предназначена для определения степени субъективной депрессии, морального дискомфорта (гипотимический тип личности).',
        'Шкала истерии <span>(Hy)</span> — разработана для выявления лиц, склонных к диссоциативным (конверсионным) расстройствам.',
        'Шкала психопатии <span>(Pd)</span> — направлена на диагностику диссоциального типа личности.',
        'Шкала маскулинности — феминности <span>(M)</span> — предназначена для измерения степени идентификации обследуемого с ролью мужчины или женщины, предписываемой обществом.',
        'Шкала паранойи <span>(Pa)</span> — позволяет судить о наличии сверхценных идей, подозрительности (паранойяльный тип личности).',
        'Шкала психастении <span>(Pt)</span> — устанавливается сходство обследуемого с больными, страдающими фобиями, навязчивыми действиями и мыслями (тревожно-мнительный тип личности)',
        'Шкала шизофрении <span>(Sc)</span> — направлена на диагностику шизоидного типа личности.',
        'Шкала гипомании <span>(Ma)</span> — определяется степень «близости» обследуемого гипертимному типу личности.',
        'Шкала социальной интроверсии <span>(Si)</span> — диагностика степени соответствия интровертированному типу личности. Клинической шкалой не является, добавлена в опросник в ходе его дальнейшей разработки.'
    ]
};

const SCALE: {[K in MmpiScalesKeys]: string} = {
    L: 'Шкала L',
    F: 'Шкала F',
    K: 'Шкала К',
    Hs: 'Шкала Hs: ипохондрии',
    D: 'Шкала D: депрессии',
    Hy: 'Шкала Hy: конверсионной истерии',
    Pd: 'Шкала Pd: психопатии',
    M: 'Шкала M: мужских черт характера',
    Pa: 'Шкала Pa: паранойяльности',
    Pt: 'Шкала Pt: психастении',
    Sc: 'Шкала Sc: шизоидности',
    Ma: 'Шкала Ma: гипомании',
    Si: 'Шкала Si: социальной интроверсии'
};

interface MmpiResultsType extends BlockType {
    Result: {
        Score: MmpiResult;
        Raw: MmpiResult;
    };
}

export const MmpiResults = ({data}: {data: MmpiResultsType}) => {
    const theme = useTheme();
    const result = data.Result;
    const transformedResults = mmpiScales.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: result.Score[curr] < 120 ? result.Score[curr] : 120
        }),
        {}
    ) as MmpiResult;

    return (
        <Box whiteSpace={'pre'}>
            <TimeBlock startTime={data.StartTime} finishTime={data.FinishTime} />
            <Line
                options={{
                    scales: {
                        x: {
                            labels: [...mmpiScales]
                        },
                        x1: {
                            labels: mmpiScales.map((key) =>
                                result.Score[key] > 120 ? '120+' : transformedResults[key].toString()
                            ),
                            ticks: {
                                color: theme.palette.primary.main
                            }
                        },
                        y: {
                            min: 0,
                            max: 130,
                            ticks: {
                                stepSize: 10
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false
                        },
                        datalabels: {
                            display: false
                        },
                        annotation: {
                            annotations: {
                                annotation1: {
                                    type: 'box',
                                    backgroundColor: 'rgba(165, 214, 167, 0.2)',
                                    borderColor: 'rgba(165, 214, 167, 0.2)',
                                    yMax: 70,
                                    yMin: 30
                                },
                                annotation2: {
                                    type: 'line',
                                    scaleID: 'y',
                                    borderColor: 'rgba(165, 214, 167, 0.8)',
                                    borderWidth: 2,
                                    value: 50
                                }
                            }
                        }
                    }
                }}
                data={{
                    datasets: [
                        {
                            data: mmpiScales.map((key, index) => (index >= 3 ? NaN : transformedResults[key])),
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main
                        },
                        {
                            data: mmpiScales.map((key, index) => (index < 3 ? NaN : transformedResults[key])),
                            borderColor: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.main
                        }
                    ]
                }}
            />
            <Table
                sx={{
                    my: 4,
                    '& td, th': {
                        border: '1px solid rgba(224, 224, 224, 1) !important',
                        padding: 1
                    },
                    '& th': {
                        fontWeight: 600
                    }
                }}>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Шкала</TableCell>
                        <TableCell>Сырой балл</TableCell>
                        <TableCell>Итоговый балл</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(Object.keys(SCALE) as MmpiScalesKeys[]).map((key, index) => (
                        <TableRow key={index}>
                            <TableCell sx={{color: 'primary.main'}}>{key}</TableCell>
                            <TableCell>{SCALE[key]}</TableCell>
                            <TableCell>{result.Raw[key]}</TableCell>
                            <TableCell>{result.Score[key]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Stack spacing={0.5} mt={2} sx={{'& span': {color: theme.palette.primary.main}}}>
                <Typography>
                    <b>Контрольные шкалы</b>
                </Typography>
                {SCALE_DESCRIPTION.additional.map((value, index) => (
                    <Typography key={index} whiteSpace={'pre-wrap'} dangerouslySetInnerHTML={{__html: value}} />
                ))}
                <Typography sx={{marginTop: '16px !important'}}>
                    <b>Основные шкалы</b>
                </Typography>
                {SCALE_DESCRIPTION.main.map((value, index) => (
                    <Typography key={index} whiteSpace={'pre-wrap'} dangerouslySetInnerHTML={{__html: value}} />
                ))}
            </Stack>
        </Box>
    );
};
