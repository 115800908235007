import {Chip, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {Edit as EditIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {PermissionName, TopicsItemFragment} from '@generated/graphql';
import React from 'react';
import {useAuthContext} from 'context/authContext';

export const tableConfig = (handleOpen: (topic: TopicsItemFragment) => void): Column<TopicsItemFragment>[] => {
    const {hasPermission} = useAuthContext();
    return [
        {
            header: 'Название темы',
            // name: 'name',
            render: (topic) => (
                <Tooltip title={topic?.name}>
                    <Typography maxWidth={300} noWrap variant={'body2'}>
                        {topic?.name}
                    </Typography>
                </Tooltip>
            )
        },
        {
            header: 'Категории',
            render: (topic) => (
                <Stack direction={'row'} gap={2} alignItems={'center'} flexWrap={'wrap'}>
                    {topic?.categories.map((category) => (
                        <Chip label={category.name} key={category?.id} />
                    ))}
                </Stack>
            )
        },
        {
            header: 'Действие',
            hide: !hasPermission(PermissionName.EditTopics),
            render: (topic) => (
                <Tooltip title={'Редактировать'}>
                    <IconButton color={'primary'} onClick={() => handleOpen(topic)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];
};
