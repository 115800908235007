import React from 'react';
import {Card, Stack, Typography} from '@mui/material';
import {FormAutocomplete, FormTextField} from 'components/form';
import {useCategoriesQuery} from '@generated/graphql';

export const MainInfo = () => {
    const {data: categoriesData} = useCategoriesQuery();
    return (
        <Card>
            <Typography variant={'h3'}>Общая информация</Typography>
            <Stack spacing={2} mt={2}>
                <FormTextField label={'Название темы'} name={'name'} />
                <FormAutocomplete
                    label={'Категории аттестуемых лиц'}
                    name={'categoryIds'}
                    optionText={'name'}
                    multiple
                    options={categoriesData?.categories?.items || []}
                />
            </Stack>
        </Card>
    );
};
