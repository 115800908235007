import {Typography} from '@mui/material';
import {BlockType} from './types';

interface LusherResultsType extends BlockType {
    Result: {
        Answer: number[];
        Couples: string[];
        Texts: string[];
    };
}

export const LuscherResults = ({data}: {data: LusherResultsType}) => {
    return (
        <Typography
            dangerouslySetInnerHTML={{
                __html: data.Result.Texts.join('')
            }}
        />
    );
};
