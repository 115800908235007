import shadows, {Shadows} from '@mui/material/styles/shadows';

export default [
    'none',
    '0px 1px 1px rgba(7, 45, 87, 0.25), 0px 0px 1px rgba(7, 45, 87, 0.31)',
    '0px 3px 5px rgba(7, 45, 87, 0.2), 0px 0px 1px rgba(7, 45, 87, 0.31)',
    '0px 8px 12px rgba(7, 45, 87, 0.15), 0px 0px 1px rgba(7, 45, 87, 0.31)',
    '0px 10px 18px rgba(7, 45, 87, 0.15), 0px 0px 1px rgba(7, 45, 87, 0.31)',
    '0px 18px 28px rgba(7, 45, 87, 0.15), 0px 0px 1px rgba(7, 45, 87, 0.31)',
    ...shadows.slice(6, 25)
] as Shadows;
