import React from 'react';
import {Box} from '@mui/material';
import {CorrectAnswersPieChart, TextRow, TimeBlock} from './components';
import {BlockType} from './types';

export interface RavenResultsType extends BlockType {
    Result: {
        IQ: number;
        Title: string;
        Rate: number;
        Score: number;
        MaxScore: number;
    };
}

export const RavenResults = ({data}: {data: RavenResultsType}) => {
    const result = data.Result;

    return (
        <Box>
            <TextRow
                text={'Количество правильных ответов'}
                value={result.Score + ' / ' + result.MaxScore + ` (${result.Rate * 100 + '%'})`}
            />
            <TextRow text={'Показатель IQ'} value={result.IQ} />
            <TextRow text={'Степень развития интеллекта'} value={result.Title} />
            <TimeBlock startTime={data.StartTime} finishTime={data.FinishTime} />
            <CorrectAnswersPieChart result={result} />
        </Box>
    );
};
