import {useContext, useState} from 'react';
import {Outlet, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import {ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Logout} from '@mui/icons-material';
import {
    AppBar,
    Box,
    BoxProps,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    SvgIcon,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import {getNavRoutes} from './routes';
import CircularLoading from 'components/CircularLoading';
import {AuthContext} from 'context/authContext';
import cookies from 'js-cookie';
// import logo from 'assets/logo.svg';

const drawerWidth = 320;

const MainLayout = () => {
    const {pathname} = useLocation();
    const [open, setOpen] = useState(false);
    const {me, loading, error} = useContext(AuthContext);

    const logout = () => {
        cookies.remove('accessToken');
        cookies.remove('refreshToken');
        cookies.remove('refreshTokenExpiryTime');
        window.location.href = '/login?redirectUrl=' + window.location.href;
    };

    return (
        <Box>
            <AppBar position='fixed' sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                        {/*<Link to={'/purchases/active'} style={{display: 'inline-flex'}}>*/}
                        {/*    <Box component={'img'} src={logo} alt={'logo'} sx={{cursor: 'pointer'}} />*/}
                        {/*</Link>*/}
                        <Box flexGrow={1} />
                        <CircularLoading loading={loading || !!error}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                spacing={3}
                                mr={4}>
                                <Typography variant='h5'>{me?.fullName}</Typography>
                                <Tooltip title={'Выйти'}>
                                    <IconButton onClick={logout} color={'inherit'} size={'medium'}>
                                        <Logout />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </CircularLoading>
                    </Stack>
                </Toolbar>
            </AppBar>

            {/*<Stack direction={'row'}>*/}
            <StyledDrawer variant='permanent' open={open}>
                <DrawerHeader />
                <List>
                    {getNavRoutes().map(
                        (item, index) =>
                            item.permission && (
                                <Tooltip title={item.title} placement='right' key={index}>
                                    <Link to={item.href}>
                                        <ListItem
                                            disablePadding
                                            sx={{
                                                display: 'block',
                                                '& > div *': {
                                                    ...(pathname.includes(item.href) && {
                                                        color: 'primary.main',
                                                        fontWeight: 500
                                                    })
                                                }
                                            }}>
                                            <ListItemButton sx={{minHeight: 48}}>
                                                <ListItemIcon sx={{minWidth: 0, mr: 2, justifyContent: 'center'}}>
                                                    <SvgIcon component={item.icon} />
                                                </ListItemIcon>
                                                <ListItemText primary={item.title} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                </Tooltip>
                            )
                    )}
                </List>
                <Box flexGrow={1} />
                <Box sx={{alignSelf: 'flex-end', mr: 1}}>
                    <IconButton color='primary' aria-label='open drawer' onClick={() => setOpen(!open)}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Box>
            </StyledDrawer>
            <StyledBody open={open}>
                <DrawerHeader />
                <Outlet />
            </StyledBody>
            {/*</Stack>*/}
        </Box>
    );
};
export default MainLayout;

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`
});

const StyledDrawer = styled(Drawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    whiteSpace: 'nowrap',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

interface StyledBodyProps extends BoxProps {
    open?: boolean;
}

const StyledBody = styled(Box, {shouldForwardProp: (prop) => prop !== 'open'})<StyledBodyProps>(({theme, open}) => ({
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1)
    },

    ...(open && {
        marginLeft: drawerWidth,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    }),
    ...(!open && {
        marginLeft: `calc(${theme.spacing(7)} + 1px)`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    })
}));
