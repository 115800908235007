import {ROUTES} from 'constants/routes';
import {
    AdminPanelSettings as AdminPanelSettingsIcon,
    FactCheckOutlined as FactCheckOutlinedIcon,
    List,
    ListAlt,
    LockOpen,
    People as PeopleIcon,
    PersonSearch
} from '@mui/icons-material';
import {useAuthContext} from 'context/authContext';
import {PermissionName} from '@generated/graphql';

export const getNavRoutes = () => {
    const {hasPermission} = useAuthContext();
    return [
        {
            title: 'Список аттестаций',
            href: ROUTES.EXAMS,
            icon: FactCheckOutlinedIcon,
            permission: hasPermission(PermissionName.ViewExamsList)
        },
        {
            title: 'Список аттестуемых',
            href: ROUTES.PARTICIPANTS,
            icon: PeopleIcon,
            permission: hasPermission(PermissionName.ViewParticipantsList)
        },
        {
            title: 'Список категорий аттестуемых лиц',
            href: ROUTES.CATEGORIES,
            icon: ListAlt,
            permission: hasPermission(PermissionName.ViewCategoriesList)
        },
        {
            title: 'Список пользователей',
            href: ROUTES.USERS,
            icon: PersonSearch,
            permission: hasPermission(PermissionName.ViewUsersList)
        },
        {
            title: 'Список ролей',
            href: ROUTES.ROLES,
            icon: LockOpen,
            permission: hasPermission(PermissionName.ViewRolesList)
        },
        {
            title: 'Список тем проверки ЗУН',
            href: ROUTES.ZUN_TOPICS,
            icon: List,
            permission: hasPermission(PermissionName.ViewTopicsList)
        },
        {
            title: 'Список тем проверки ПФО',
            href: ROUTES.PSY_TOPICS,
            icon: List,
            permission: hasPermission(PermissionName.ViewTopicsList)
        },
        {
            title: 'Обновление подписанта',
            href: ROUTES.SIGNATORY,
            icon: AdminPanelSettingsIcon,
            permission: hasPermission(PermissionName.EditSignatory)
        }
    ];
};
