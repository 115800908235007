import {Card, Container, Stack, Typography} from '@mui/material';
import {Form, FormTextField} from 'components/form';
import CircularLoading from 'components/CircularLoading';
import React from 'react';
import {useForm} from 'react-hook-form';
import {InferType, object, string} from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {PermissionName, useSignatoryQuery, useUpdateSignatoryMutation} from '@generated/graphql';
import {LoadingButton} from '@mui/lab';
import {NotificationType, useNotify} from 'hooks';
import {useAuthContext} from 'context/authContext';

const schema = object({
    certificationBodyFullName: string().required(' '),
    certificationBodyShortName: string().required(' '),
    position: string().required(' '),
    employee: string().required(' '),
    pfoStatementHeader: string().required(' ')
});
export const UpdateSignatory = () => {
    const {hasPermission} = useAuthContext();
    const notify = useNotify();
    const {data, loading, error} = useSignatoryQuery();
    const [updateSignatory, {loading: updateSignatoryLoading}] = useUpdateSignatoryMutation();
    const signatory = data?.signatory;
    const form = useForm({
        values: signatory
            ? schema.cast(
                  {
                      ...signatory
                  },
                  {stripUnknown: true}
              )
            : undefined,
        resolver: yupResolver(schema)
    });
    const {handleSubmit} = form;
    const onSubmit = (data: InferType<typeof schema>) => {
        updateSignatory({variables: {signatoryModel: {id: signatory?.id, ...data}}})
            .then((res) => {
                notify({type: NotificationType.SUCCESS});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };

    return (
        <CircularLoading loading={loading || !!error}>
            <Container>
                <Stack direction={'row'} mb={2} justifyContent={'space-between'}>
                    <Typography variant={'h2'}>Обновление подписанта</Typography>
                    {hasPermission(PermissionName.EditSignatory) && (
                        <LoadingButton
                            loading={updateSignatoryLoading}
                            variant={'contained'}
                            onClick={handleSubmit(onSubmit)}>
                            Сохранить
                        </LoadingButton>
                    )}
                </Stack>
                <Form form={form}>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={12} md={6}>*/}
                    <Card>
                        <Stack spacing={2}>
                            <FormTextField
                                label={'Полное наименование органа аттестации'}
                                name={'certificationBodyFullName'}
                                multiline
                                maxRows={10}
                            />
                            <FormTextField
                                label={'Краткое наименование органа аттестации'}
                                name={'certificationBodyShortName'}
                                multiline
                                maxRows={10}
                            />
                            <FormTextField label={'Должность руководителя'} name={'position'} multiline maxRows={10} />
                            <FormTextField label={'Руководитель'} name={'employee'} multiline maxRows={10} />
                            <FormTextField
                                label={'Шапка заявления (Протокол ПФО)'}
                                name={'pfoStatementHeader'}
                                multiline
                                maxRows={10}
                            />
                        </Stack>
                    </Card>
                    {/*</Grid>*/}
                    {/*</Grid>*/}
                </Form>
            </Container>
        </CircularLoading>
    );
};
