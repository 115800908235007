import {Button, Container, Stack, Typography} from '@mui/material';
import React from 'react';
import {useNavigate} from 'react-router';
import TableLayout from 'components/table/TableLayout';
import {filterFields, tableConfig} from './config';
import {ExamType, PermissionName, useTopicsQuery} from '@generated/graphql';
import {useAuthContext} from 'context/authContext';

export const ZunTopics = () => {
    const filters = filterFields();
    const {data, loading, error, refetch} = useTopicsQuery();
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();
    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'} mb={5}>
                <Typography variant={'h2'}>Список тем проверки знаний, умений и навыков</Typography>
                {hasPermission(PermissionName.EditTopics) && (
                    <Button variant={'contained'} onClick={() => navigate('create')}>
                        Добавить тему
                    </Button>
                )}
            </Stack>
            <TableLayout
                loading={loading || !!error}
                data={data?.topics}
                defaultFilter={{
                    where: {
                        type: {
                            eq: ExamType.KnowledgeSkillTest
                        }
                    }
                }}
                columns={tableConfig()}
                filters={filters}
                refetch={refetch}
            />
        </Container>
    );
};
