import {TextRow} from './components';
import {useMemo} from 'react';
import moment from 'moment';
import {Box} from '@mui/material';
import {BlockType} from './types';

interface SchulteResultsType extends BlockType {
    Result: {
        Efficiency: number;
        Errors: number;
    };
}

export const SchulteResults = ({data}: {data: SchulteResultsType}) => {
    const testDuration = useMemo(
        () => Math.round(moment.duration(moment(data.FinishTime).diff(moment(data.StartTime))).asSeconds()),
        []
    );

    return (
        <Box>
            <TextRow text={'Время (секунды)'} value={testDuration} />
            <TextRow text={'Ошибок'} value={data.Result.Errors} />
            <TextRow text={'Эффективность работы'} value={data.Result.Efficiency + ' / 5'} />
        </Box>
    );
};
