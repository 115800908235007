import React from 'react';
import {Box} from '@mui/material';
import {CorrectAnswersPieChart, TextRow, TimeBlock} from './components';
import {BlockType} from './types';

export interface DifficultAssociationResultsType extends BlockType {
    Result: {
        Score: number;
        MaxScore: number;
        Rate: number;
    };
}

export const DifficultAssociationResults = ({data}: {data: DifficultAssociationResultsType}) => {
    const result = data.Result;

    return (
        <Box>
            <TextRow
                text={'Количество правильных ответов'}
                value={result.Score + ' / ' + result.MaxScore + ` (${result.Rate * 100 + '%'})`}
            />
            <TimeBlock startTime={data.StartTime} finishTime={data.FinishTime} />
            <CorrectAnswersPieChart result={result} />
        </Box>
    );
};
