import {Box, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, Stack, Typography} from '@mui/material';
import {green, red} from '@mui/material/colors';
import {QuestionType} from '@generated/graphql';

export const getColor = (item: any, active?: boolean) =>
    item.isCorrect ? green[active ? 600 : 300] : red[active ? 600 : 300];

export const Question = (props: {questions: any; questionIndex: number}) => {
    const {questions, questionIndex} = props;
    const currentQuestion = questions[questionIndex];

    return (
        <>
            {currentQuestion && (
                <>
                    <Typography variant={'h4'} textAlign={'center'} mb={2}>
                        {currentQuestion.title}
                    </Typography>
                    <Typography
                        mb={2}
                        dangerouslySetInnerHTML={{
                            __html: currentQuestion.content
                        }}
                    />
                    {currentQuestion.type === QuestionType.TestQuestion && (
                        <RadioGroup value={currentQuestion.userAnswersIds?.[0]} key={currentQuestion.id}>
                            <Stack spacing={2}>
                                {currentQuestion.answers.map((item: any, index: number) => (
                                    <Box
                                        key={`radio-${index}`}
                                        sx={{
                                            '.MuiRadio-root.Mui-disabled, .MuiFormControlLabel-label.Mui-disabled': {
                                                color: getColor(item, true) + '!important'
                                            }
                                        }}>
                                        <FormControlLabel
                                            value={item.id}
                                            disabled
                                            control={<Radio sx={{py: 0}} />}
                                            label={item.text}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        </RadioGroup>
                    )}
                    {(currentQuestion.type === QuestionType.ThematicQuestion ||
                        currentQuestion.type === QuestionType.AppliedTask) && (
                        <FormGroup key={currentQuestion.id}>
                            <Stack spacing={2}>
                                {currentQuestion.answers.map((item: any, index: number) => (
                                    <Box
                                        key={`checkbox-${index}`}
                                        sx={{
                                            '.MuiCheckbox-root.Mui-disabled, .MuiFormControlLabel-label.Mui-disabled': {
                                                color: getColor(item, true) + '!important'
                                            }
                                        }}>
                                        <FormControlLabel
                                            value={item.id}
                                            disabled
                                            checked={currentQuestion.userAnswersIds?.includes(item.id)}
                                            control={<Checkbox sx={{py: 0}} />}
                                            label={item.text}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        </FormGroup>
                    )}
                </>
            )}
        </>
    );
};
