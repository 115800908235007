import {Checkbox} from '@mui/material';
import {Column} from 'components/table/TableLayout';
import {ParticipantQuery} from '@generated/graphql';
import React, {ChangeEvent} from 'react';
import {SexMap} from 'constants/index';
import _ from 'lodash';
import {formatDate} from 'helpers';
import moment from 'moment';

export const tableConfig = (
    checked: string[],
    setChecked: (state: string[]) => void,
    participantsIds: string[]
): Column<ParticipantQuery['participant']>[] => {
    const handleCheck = (e: ChangeEvent<HTMLInputElement>, id: string) => {
        if (e.target.checked) {
            setChecked([...checked, id]);
        } else {
            setChecked(checked.filter((i) => i !== id));
        }
    };

    const handleCheckAll = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setChecked(_.uniq([...checked, ...participantsIds]));
        } else {
            setChecked(checked.filter((i) => !participantsIds.includes(i)));
        }
    };

    return [
        {
            header: (
                <Checkbox
                    sx={{p: 0}}
                    disableRipple
                    onChange={handleCheckAll}
                    checked={!!participantsIds.length && participantsIds.every((i) => checked.includes(i))}
                />
            ),
            render: (participant) => (
                <Checkbox
                    sx={{p: 0}}
                    disableRipple
                    checked={checked.includes(participant!.id)}
                    onChange={(e) => handleCheck(e, participant!.id)}
                />
            )
        },
        {
            header: 'УИН',
            render: (participant) => participant?.uin,
            name: 'uin'
        },
        {
            header: 'Пол',
            render: (participant) => SexMap[participant!.sex],
            name: 'sex'
        },
        {
            header: 'Дата рождения',
            render: (participant) => formatDate(participant?.birthdayDate, 'DD.MM.YYYY'),
            name: 'birthdayDate'
        },
        {
            header: 'Возраст',
            render: (participant) => participant?.birthdayDate && moment().diff(participant?.birthdayDate, 'years')
        },
        {
            header: 'Категория',
            render: (participant) => participant?.category?.name
        }
    ];
};
