import {Box, Stack, SvgIcon} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {useMemo} from 'react';

function ArrowUpIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <path d='M3.5 0.5L6.9641 5.75H0.0358984L3.5 0.5Z' />
        </SvgIcon>
    );
}

function ArrowDownIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <path d='M3.5 5.5L0.0358982 0.25L6.9641 0.25L3.5 5.5Z' />
        </SvgIcon>
    );
}

const TableSortLabel = ({item}: any) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useMemo(() => Object.fromEntries(searchParams), [searchParams]);
    const {sortingParameter, sortOrder} = params;

    return (
        <Box
            display='flex'
            alignItems='center'
            sx={{cursor: 'pointer'}}
            onClick={() => {
                if (sortingParameter === item.name && sortOrder === 'ASC') {
                    setSearchParams({...params, sortOrder: 'DESC'});
                } else if (sortingParameter === item.name && sortOrder === 'DESC') {
                    setSearchParams(_.omit(params, 'sortingParameter', 'sortOrder'));
                } else {
                    setSearchParams({...params, sortingParameter: item.name, sortOrder: 'ASC'});
                }
            }}>
            {item.header}
            <Stack ml={1} direction={'column'}>
                <ArrowUpIcon
                    inheritViewBox
                    sx={{
                        width: 7,
                        height: 7,
                        marginBottom: '1px',
                        color: (theme: any) =>
                            sortOrder === 'ASC' && sortingParameter === item.name
                                ? theme.palette.primary.main
                                : theme.palette.grey['600']
                    }}
                />
                <ArrowDownIcon
                    inheritViewBox
                    sx={{
                        marginTop: '1px',
                        width: 7,
                        height: 7,
                        color: (theme: any) =>
                            sortOrder === 'DESC' && sortingParameter === item.name
                                ? theme.palette.primary.main
                                : theme.palette.grey['600']
                    }}
                />
            </Stack>
        </Box>
    );
};

export default TableSortLabel;
