import {Form} from 'components/form';
import React, {useState} from 'react';
import FilterButton from 'components/filters/FilterButton';
import FiltersPopover from './FiltersPopover';
import {useForm} from 'react-hook-form';

const Filters = ({filters}: any) => {
    const form = useForm();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Form form={form}>
            <FilterButton onClick={handleClick} />
            <FiltersPopover filters={filters} handleClose={handleClose} anchorEl={anchorEl} />
        </Form>
    );
};

export default Filters;
