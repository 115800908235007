import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import React, {useEffect} from 'react';
import {Button, Card, Grid, Stack} from '@mui/material';
import {FormAutocomplete, FormCheckbox, FormEditor, FormRadio, FormTextField} from 'components/form';
import {getEnumArray} from 'helpers';
import {QuestionTypeMap} from 'constants/index';
import {QuestionType} from '@generated/graphql';
import {ACTION, ActionButton} from 'components';

export const Question = ({questionIndex}: {questionIndex: number}) => {
    const {
        setValue,
        formState: {errors}
    } = useFormContext();
    const {fields, append, remove} = useFieldArray({
        name: `questions[${questionIndex}].answers`
    });
    const watch = useWatch();
    const questionError = (errors?.questions as any)?.[questionIndex];

    const questionType = watch.questions[questionIndex]?.type;
    const answers = watch?.questions[questionIndex]?.answers;

    useEffect(() => {
        !fields.length && append({text: '', isCorrect: true});
    }, []);

    const handleChangeRadioButton = (e: any) => {
        e.target.checked &&
            fields.forEach((field, i) => {
                setValue(`questions[${questionIndex}].answers.[${i}].isCorrect`, i === +e.target.value);
            });
    };

    return (
        <Card sx={{width: '100%', borderColor: questionError ? 'error.main' : undefined}}>
            <Stack spacing={2}>
                <FormAutocomplete
                    name={`questions[${questionIndex}].type`}
                    options={getEnumArray(QuestionTypeMap)}
                    label={'Тип вопроса'}
                />
                <FormTextField
                    name={`questions[${questionIndex}].title`}
                    label={'Заголовок'}
                    multiline
                    minRows={2}
                    maxRows={15}
                />
                <FormEditor name={`questions[${questionIndex}].content`} label={'Описание'} />
                {fields.length > 0 && questionType && (
                    <Card
                        sx={{
                            borderColor: questionError?.answers ? 'error.main' : undefined
                        }}>
                        <Grid container>
                            <Grid item xs={1} md={2}>
                                Правильный
                            </Grid>
                            <Grid item xs={11} md={10}>
                                Ответ
                            </Grid>
                        </Grid>
                        {fields.map((element, index) => (
                            <Grid container mt={1} key={element.id} alignItems={'center'}>
                                <Grid item xs={1} md={2}>
                                    {questionType === QuestionType.TestQuestion ? (
                                        <FormRadio
                                            onChange={handleChangeRadioButton}
                                            value={index}
                                            name={`questions[${questionIndex}].answers.[${index}].isCorrect`}
                                        />
                                    ) : (
                                        <FormCheckbox
                                            disabled={Boolean(
                                                answers &&
                                                    answers.filter((i: any) => i.isCorrect).length > 1 &&
                                                    !watch.questions[questionIndex]?.answers[index]?.isCorrect
                                            )}
                                            name={`questions[${questionIndex}].answers.[${index}].isCorrect`}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={11} md={10}>
                                    <Stack width={'100%'} direction='row' alignItems={'center'} spacing={1}>
                                        <FormTextField
                                            placeholder={'Ответ'}
                                            name={`questions[${questionIndex}].answers.[${index}].text`}
                                            multiline
                                            minRows={2}
                                            maxRows={15}
                                        />
                                        {fields.length > 1 && (
                                            <ActionButton onClick={() => remove(index)} type={ACTION.DELETE} />
                                        )}
                                    </Stack>
                                </Grid>
                            </Grid>
                        ))}
                        <Button onClick={() => append({text: '', isCorrect: false})} variant={'outlined'} sx={{mt: 2}}>
                            Добавить вариант ответа
                        </Button>
                    </Card>
                )}
            </Stack>
        </Card>
    );
};
