import {Dropzone, Form} from 'components/form';
import CustomModal from 'components/modals/CustomModal';
import React from 'react';
import {ModalProps, NotificationType, useNotify} from 'hooks';
import {useForm} from 'react-hook-form';
import {useAddParticipantExamResultsMutation} from '@generated/graphql';
import {array, InferType, object} from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useApolloClient} from '@apollo/client';
import {useParams} from 'react-router';

const schema = object({
    files: array().default([]).min(1, ' ')
});

export const UploadResultsModal = ({open, handleToggle}: ModalProps) => {
    const client = useApolloClient();
    const {examId} = useParams();
    const form = useForm({
        resolver: yupResolver(schema)
    });
    const notify = useNotify();
    const {handleSubmit} = form;
    const [addParticipantExamResults, {loading}] = useAddParticipantExamResultsMutation();

    const onSubmit = (data: InferType<typeof schema>) => {
        addParticipantExamResults({
            variables: {
                examId,
                files: data.files
            }
        }).then((res) => {
            const arr = res.data?.addParticipantExamResults || [];
            const notThisExam = arr
                .filter((i) => i.split('_').pop() === 'NotThisExam')
                .map((i) => i.split('_').slice(0, -1).join('_'));
            const alreadyHasResult = arr
                .filter((i) => i.split('_').pop() === 'AlreadyHasResult')
                .map((i) => i.split('_').slice(0, -1).join('_'));
            const success = arr
                .filter((i) => i.split('_').pop() === 'Success')
                .map((i) => i.split('_').slice(0, -1).join('_'));

            const successMessage = success?.length
                ? 'Успешно загружено (' + success.length + 'шт.): ' + success.join(', ') + '.'
                : '';
            const alreadyHasResultMessage = alreadyHasResult?.length
                ? 'Результаты уже существуют (' + alreadyHasResult.length + 'шт.): ' + alreadyHasResult.join(', ') + '.'
                : '';
            const notThisExamMessage = notThisExam?.length
                ? 'Ошибка загрузки (' + notThisExam.length + 'шт.): ' + notThisExam.join(', ') + '.'
                : '';

            notify({
                type: success.length ? NotificationType.SUCCESS : NotificationType.ERROR,
                text: `${[successMessage, alreadyHasResultMessage, notThisExamMessage].filter((i) => i).join('\n')}`
            });

            client.refetchQueries({include: ['exam']}).then(() => {
                handleToggle();
            });
        });
    };

    const onError = () => {
        notify({type: NotificationType.ERROR, text: 'Прикрепите хотя бы один файл'});
    };

    return (
        <CustomModal
            handleClose={handleToggle}
            title={'Загрузка результатов аттестации'}
            open={open}
            loading={loading}
            handleSubmit={handleSubmit(onSubmit, onError)}>
            <Form form={form}>
                <Dropzone name={'files'} />
            </Form>
        </CustomModal>
    );
};
