import {IconButton, Stack, Tooltip} from '@mui/material';
import {useNavigate} from 'react-router';
import {Delete as DeleteIcon, Edit as EditIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {PermissionName, useDeleteUserMutation, UserFragment, useRolesQuery} from '@generated/graphql';
import {formatDate} from 'helpers';
import {ModalContext} from 'context/modalContext';
import React, {useContext} from 'react';
import {FILTER_TYPES, INPUT_TYPES} from 'components/generators';
import {useAuthContext} from 'context/authContext';

export const tableConfig = (refetch: () => void): Column<UserFragment>[] => {
    const navigate = useNavigate();
    const {handleOpen} = useContext(ModalContext);
    const {hasPermission} = useAuthContext();
    const [deleteUser, {loading}] = useDeleteUserMutation();
    return [
        {
            header: 'Пользователь',
            render: (user) => user?.fullName,
            name: 'fullName'
        },
        {
            header: 'E-mail',
            render: (user) => user?.email,
            name: 'email'
        },
        {
            header: 'Дата создания',
            render: (user) => formatDate(user?.createdAt),
            name: 'createdAt'
        },
        {
            header: 'Роль',
            render: (user) => user?.role?.displayName
        },
        {
            header: 'Действие',
            hide: !hasPermission(PermissionName.EditUserRecords),
            render: (user) => (
                <Stack direction={'row'} spacing={1}>
                    <Tooltip title={'Редактировать'}>
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                navigate(user?.id);
                            }}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Удалить пользователя'}>
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                handleOpen({
                                    title: 'Удаление пользователя',
                                    description:
                                        'Вы уверены, что хотите удалить данные пользователя? Отменить удаление будет невозможно.',
                                    handleClick: () => {
                                        deleteUser({variables: {id: user?.id}}).then(() => refetch());
                                    },
                                    loading
                                });
                            }}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        }
    ];
};

export const filterFields = () => {
    const {data: dataRoles} = useRolesQuery();
    const roles = dataRoles?.roles?.items || [];
    return [
        {
            name: 'roleId',
            label: 'Роль',
            inputType: INPUT_TYPES.AUTOCOMPLETE,
            options: roles.map((role) => ({
                id: role.id,
                displayName: role.displayName
            })),
            filterType: FILTER_TYPES.ENUM_FILTER
        },
        {
            name: 'fullName',
            label: 'ФИО пользователя',
            inputType: INPUT_TYPES.TEXT,
            filterType: FILTER_TYPES.STRING_FILTER
        },
        {
            name: 'email',
            label: 'Почта',
            inputType: INPUT_TYPES.TEXT,
            filterType: FILTER_TYPES.STRING_FILTER
        },
        {
            name: 'createdAt',
            label: 'Дата создания',
            inputType: INPUT_TYPES.DATE,
            filterType: FILTER_TYPES.DATE_FILTER
        }
    ];
};
