import {Box, Typography} from '@mui/material';
import {green, red, yellow} from '@mui/material/colors';
import {BlockType} from './types';

export const getCircle = (index: number) => {
    const width = 26;
    const height = 26;
    const r = 11;

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns='http://www.w3.org/2000/svg'>
            <circle
                cx={width / 2}
                cy={height / 2}
                r={r}
                stroke='black'
                fill='none'
                strokeWidth='4'
                strokeDasharray={Math.round(2 * Math.PI * r * (1 - 1 / 8) * 100) / 100}
                transform={`rotate(${22.5 + index * 45} ${width / 2} ${height / 2})`}
            />
        </svg>
    );
};

export const SYMBOLS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

interface LandoltResultsType extends BlockType {
    Answers: number[];
    Symbols: string[];
    Symbol: string;
    Result: {
        S: number;
        n: number;
        t: number;
        N: number;
        C: number;
        M: number;
        P: number;
        O: number;
        A: number;
        T1: number;
        T2: number;
        T3: number;
        E: number;
        Au: number;
        K: number;
        V: number;
        Q: number;
        Ku: number;
        KText: string;
    };
}

export const LandoltResults = ({data}: {data: LandoltResultsType}) => {
    const {Answers: answers, Symbols: symbols, Symbol: symbol} = data;
    const result = data.Result;

    const getColor = (index: number) => {
        if (symbols[index] === symbol) {
            if (answers.includes(index)) return green[400];
            else return yellow[400];
        } else {
            if (answers.includes(index)) return red[400];
            else return 'transparent';
        }
    };

    return (
        <>
            <Typography mb={2}>
                Таблица колец, в которой вы должны были выделить кольцо с конкретным направлением:{' '}
                {getCircle(SYMBOLS.indexOf(symbol))}
            </Typography>
            <Box display={'grid'} gridTemplateColumns={'repeat(25, 30px)'} gap={'2px'} boxSizing={'content-box'}>
                {symbols.map((item, index) => (
                    <Box key={index} p={'2px'} width={26} height={26} sx={{backgroundColor: getColor(index)}}>
                        {getCircle(SYMBOLS.indexOf(item))}
                    </Box>
                ))}
            </Box>
            <Typography fontWeight={700} mt={2}>
                Основные показатели
            </Typography>
            <Typography>Время, затраченное на тестирование (t): {result.t}</Typography>
            <Typography>Общее количество просмотренных колец (N): {result.N}</Typography>
            <Typography>Общее количество просмотренных строк (C): {result.C} </Typography>
            <Typography>Общее количество колец, которые нужно было выделить (n): {result.n}</Typography>
            <Typography>Общее количество выделенных колец (M): {result.M}</Typography>
            <Typography>Количество верно выделенных колец (S): {result.S}</Typography>
            <Typography>Количество пропущенных колец (P): {result.P}</Typography>
            <Typography>Количество ошибочно выбранных колец (O): {result.O}</Typography>
            <Typography fontWeight={700} mt={2}>
                Расчет результатов
            </Typography>
            <Typography>
                Показатель скорости внимания (производительности внимания) (A): {result.A} знаков в секунду
            </Typography>
            <Typography>Показатель точности работы (первый вариант) (T1): {result.T1} условных единиц</Typography>
            <Typography>Показатель точности работы (второй вариант) (T2): {result.T2} условных единиц</Typography>
            <Typography>
                Показатель точности работы (третий вариант, по Уиппу) (T3): {result.T3} условных единиц
            </Typography>
            <Typography>Коэффициент умственной продуктивности (E): {result.E} знаков</Typography>
            <Typography>Умственная работоспособность (Au): {result.Au} знаков в секунду</Typography>
            <Typography>
                Концентрация внимания (процент правильно выделенных символов от всех, что нужно было выделить) (K):{' '}
                {result.K} процентов
            </Typography>
            <Typography>Показатель устойчивости концентрации внимания (Ku): {result.Ku} баллов</Typography>
            <Typography>Объем зрительной информации (V): {result.V} знаков</Typography>
            <Typography>Скорости переработки (Q): {result.Q} знаков</Typography>
            <Typography fontWeight={700} mt={2}>
                Обобщенный результат
            </Typography>
            <Typography>
                Уровень концентрации внимания {result.K}, что соответствует значению - {result.KText}
            </Typography>
            <Typography>
                Общее количество ошибок равно {result.O + result.P}{' '}
                {result.O + result.P > 5
                    ? '– это выше нормального количества ошибок для взрослого человека.'
                    : '– это норма уровня концентрации внимания для взрослого человека.'}
            </Typography>
        </>
    );
};
