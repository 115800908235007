import {Components, Theme} from '@mui/material';
import {forwardRef} from 'react';
import {Link, LinkProps as RouterLinkProps} from 'react-router-dom';
import {grey} from '@mui/material/colors';

const RouterLink = forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & {href: RouterLinkProps['to']}>(
    (props, ref) => {
        const {href, ...other} = props;
        return <Link ref={ref} to={href} {...other} />;
    }
);

export default {
    MuiInputLabel: {
        styleOverrides: {
            root: {fontSize: '0.875rem'}
        }
    },
    MuiButton: {
        styleOverrides: {
            root: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none'
                }
            }
        }
    },
    MuiTextField: {
        defaultProps: {
            size: 'small'
        }
    },
    MuiInputBase: {
        defaultProps: {
            autoComplete: 'off'
        }
    },
    MuiCircularProgress: {
        defaultProps: {
            thickness: 2.5,
            size: 35
        }
    },
    MuiLink: {
        defaultProps: {
            component: RouterLink
        } as any
    },
    MuiCard: {
        defaultProps: {
            variant: 'outlined'
        },
        styleOverrides: {
            root: ({theme}) => ({
                padding: theme.spacing(2),
                borderColor: theme.palette.grey[200]
            })
        }
    },
    MuiMenu: {
        defaultProps: {
            elevation: 2,
            disableAutoFocusItem: true
        }
    },
    MuiIconButton: {
        defaultProps: {
            size: 'small'
        }
    },
    MuiTableHead: {
        styleOverrides: {
            root: {
                backgroundColor: grey[100]
            }
        }
    },
    MuiTableBody: {
        styleOverrides: {
            root: {
                '& .MuiTableRow-root:last-child .MuiTableCell-root': {
                    borderBottom: 0
                }
            }
        }
    },
    MuiTableCell: {
        styleOverrides: {
            root: ({theme}) => ({
                ...theme.typography.body2
            })
        }
    },
    MuiSelect: {
        defaultProps: {
            size: 'small'
        }
    },
    MuiListItemText: {
        defaultProps: {
            primaryTypographyProps: {
                typography: 'body2'
            }
        }
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                position: 'absolute',
                bottom: -20,
                marginLeft: 0
            }
        }
    },
    MuiTabPanel: {
        styleOverrides: {
            root: {
                paddingRight: 0,
                paddingLeft: 0
            }
        }
    }
} as Components<Theme>;
