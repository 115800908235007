import {Container, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import TableLayout from 'components/table/TableLayout';
import {tableConfig} from './config';
import {ExamType, TopicsItemFragment, useTopicsQuery} from '@generated/graphql';
import {CreateOrEditTopicModal} from './TopicModal';

export const PsyTopics = () => {
    const {data, loading, error} = useTopicsQuery({
        variables: {
            where: {
                type: {
                    eq: ExamType.Psychology
                }
            },
            skip: 0,
            take: 20
        }
    });
    const [topic, setTopic] = useState<TopicsItemFragment | null>(null);
    const handleOpen = (topic: TopicsItemFragment) => {
        setTopic(topic);
    };
    const handleClose = () => {
        setTopic(null);
    };

    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'} mb={5}>
                <Typography variant={'h2'}>Список тем проверки психофизиологических качеств</Typography>
                {/*<Button variant={'contained'} onClick={() => {}}>*/}
                {/*    Добавить тему*/}
                {/*</Button>*/}
            </Stack>
            <TableLayout
                loading={loading || !!error}
                data={data?.topics}
                columns={tableConfig(handleOpen)}
                defaultFilter={{
                    where: {
                        type: {
                            eq: ExamType.Psychology
                        }
                    }
                }}
                pagination={false}
                // filters={filters}
                // refetch={refetch}
            />
            {!!topic && <CreateOrEditTopicModal handleClose={handleClose} topic={topic} />}
        </Container>
    );
};
