import React from 'react';
import {useController, UseControllerProps} from 'react-hook-form';
import Editor from './Editor';
import {Box, InputLabel, Stack} from '@mui/material';

type FormEditorProps = {
    label?: string;
    disabled?: boolean;
};

export const FormEditor = ({name, label, rules, disabled}: FormEditorProps & UseControllerProps) => {
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: ''});
    return (
        <Stack spacing={0.5}>
            <Box sx={{position: 'relative'}}>
                {label && <InputLabel error={!!error}>{label}</InputLabel>}
                <Editor onChange={field.onChange} error={!!error} value={field.value} disabled={disabled} />
            </Box>
        </Stack>
    );
};
