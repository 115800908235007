import React from 'react';
import {FormAutocomplete, FormDatePicker, FormTextField} from '../form';

export const INPUT_TYPES = {
    DATE: 'DATE',
    TEXT: 'TEXT',
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    NUMBER_RANGE: 'NUMBER_RANGE'
    // CHECKBOX: 'checkbox',
    // RADIO_GROUP: 'radio_group'
};

export const INPUT_DEFAULT_VALUE = {
    [INPUT_TYPES.DATE]: null,
    [INPUT_TYPES.TEXT]: '',
    [INPUT_TYPES.AUTOCOMPLETE]: null,
    [INPUT_TYPES.DATE]: null
    // [INPUT_TYPES.CHECKBOX]: false,
    // [INPUT_TYPES.RADIO_GROUP]: ''
};

export const BOOLEAN_OPTIONS = [
    {displayName: 'Да', id: '1'},
    {displayName: 'Нет', id: '0'}
];

const GeneratedInput = ({input}: any) => {
    const {label, name, inputType, ...rest} = input;
    switch (inputType) {
        case INPUT_TYPES.DATE: {
            return (
                <FormDatePicker
                    // rules={{validate: (value: any) => (value ? moment(value).isValid() : true)}}
                    label={label}
                    name={name}
                    {...rest}
                />
            );
        }
        case INPUT_TYPES.TEXT: {
            return <FormTextField name={name} label={label} type={input.type} {...rest} />;
        }
        case INPUT_TYPES.AUTOCOMPLETE: {
            return (
                <FormAutocomplete
                    name={name}
                    label={label}
                    limitTags={1}
                    options={input.options}
                    // defaultValue={
                    //     input.defaultValue || input.multiple ? [] : INPUT_DEFAULT_VALUE[INPUT_TYPES.AUTOCOMPLETE]
                    // }
                    {...rest}
                />
            );
        }
        default:
            return <></>;
    }
};

export default GeneratedInput;
