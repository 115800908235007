import _ from 'lodash';
import {INPUT_DEFAULT_VALUE} from './inputsGenerator';

export const FILTER_TYPES = {
    STRING_FILTER: 'STRING_FILTER',
    // BOOL_FILTER: 'BOOL_FILTER',
    // MULTIPLE_STRING: 'MULTIPLE_STRING',
    ENUM_FILTER: 'ENUM_FILTER',
    CUSTOM_FILTER: 'CUSTOM_FILTER',
    // DATE_RANGE_FILTER: 'DATE_FILTER',
    DATE_FILTER: 'DATE_FILTER'
    // NUMBER_FILTER: 'NUMBER_FILTER',
    // NUMBER_RANGE: 'NUMBER_RANGE'
};

//юзаем чтобы сгенерировать query в router
export const generateQueryOnSubmit = (data: any, filters: any, setSearchParams: any, queryParams: any) => {
    const params = {};
    filters.forEach((filter: any) => {
        const {inputType, name} = filter;
        const dataName = _.get(data, name);
        const setParam = (data: any) => _.set(params, name, data);
        if (!dataName) return;
        switch (inputType) {
            default: {
                setParam(dataName);
                break;
            }
        }
    });
    setSearchParams({where: params, search: queryParams?.search});
};
//юзаем чтобы очистить инпуты и router.query
export const onClearFilter = (filters: any, reset: any, setSearchParams: any) => {
    setSearchParams({});
    // const params = {};
    // filters.forEach((filter: any) => {
    //     const {name, inputType, defaultValue, multiple} = filter;
    //     _.set(params, name, multiple ? [] : defaultValue || INPUT_DEFAULT_VALUE[inputType]);
    // });
    reset({});
};

//это вставляем в useEffect для заполнения инпутов данными из query
export const onUseEffect = (filters: any, reset: any, query: any) => {
    const params = {};
    const {where} = query;
    filters.forEach((filter: any) => {
        const {inputType, name} = filter;
        if (_.get(where, filter.name)) {
            const dataName = _.get(where, name);
            const setParam = (data: any) => _.set(params, name, data);
            setParam(dataName || INPUT_DEFAULT_VALUE[inputType]);
        } else _.set(params, name, INPUT_DEFAULT_VALUE[inputType]);
    });
    reset(params);
};

//это вставляем в запрос по типу {clientFilters: generateFilterQuery(...)}
export const generateFilterQuery = (filters: any, query: any) => {
    const {where} = query;
    const params: any = {};
    filters.forEach((filter: any) => {
        if (_.get(where, filter.name)) {
            const filterName = filter.filterName || filter.name;
            const queryValue = _.get(where, filter.name);
            const setParam = (data: any) => _.set(params, filterName, data);
            switch (filter.filterType) {
                case FILTER_TYPES.STRING_FILTER: {
                    setParam({ilike: `%${queryValue}%`});
                    break;
                }
                case FILTER_TYPES.DATE_FILTER:
                case FILTER_TYPES.ENUM_FILTER: {
                    setParam({eq: queryValue});
                    break;
                }
                case FILTER_TYPES.CUSTOM_FILTER: {
                    setParam(filter.customFilter(queryValue));
                }
            }
        }
    });
    return Object.keys(params).length === 0 ? undefined : params;
};
