import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export type AddManyParticipantToExamModelInput = {
  examId: Scalars['UUID']['input'];
  participantIds: Array<Scalars['UUID']['input']>;
};

export type Answer = {
  __typename?: 'Answer';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  isCorrect: Scalars['Boolean']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Question>;
  questionId: Scalars['UUID']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<Scalars['Int']['output']>;
};

export type AnswerFilterInput = {
  and?: InputMaybe<Array<AnswerFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isCorrect?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<AnswerFilterInput>>;
  order?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  question?: InputMaybe<QuestionFilterInput>;
  questionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  weight?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
};

export type AnswerModelInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  isCorrect: Scalars['Boolean']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  questionId?: InputMaybe<Scalars['UUID']['input']>;
  text: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Int']['input']>;
};

export type AnswerSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isCorrect?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  question?: InputMaybe<QuestionSortInput>;
  questionId?: InputMaybe<SortEnumType>;
  text?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  weight?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Category = {
  __typename?: 'Category';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  participants: Array<Participant>;
  topics: Array<Topic>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryCollectionSegment = {
  __typename?: 'CategoryCollectionSegment';
  items?: Maybe<Array<Category>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CategoryFilterInput = {
  and?: InputMaybe<Array<CategoryFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CategoryFilterInput>>;
  participants?: InputMaybe<ListFilterInputTypeOfParticipantFilterInput>;
  topics?: InputMaybe<ListFilterInputTypeOfTopicFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type CategoryModelInput = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CategorySortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type ComparableDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableGuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<Scalars['UUID']['input']>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<Scalars['Int']['input']>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type ComparableNullableOfDateTimeOffsetOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type Exam = {
  __typename?: 'Exam';
  actualEndDate?: Maybe<Scalars['DateTime']['output']>;
  actualStartDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  number: Scalars['String']['output'];
  participantExams: Array<ParticipantExam>;
  place: Scalars['String']['output'];
  plannedEndDate: Scalars['DateTime']['output'];
  plannedStartDate: Scalars['DateTime']['output'];
  status: ExamStatus;
  title: Scalars['String']['output'];
  type: ExamType;
  updatedAt: Scalars['DateTime']['output'];
};

export type ExamCollectionSegment = {
  __typename?: 'ExamCollectionSegment';
  items?: Maybe<Array<Exam>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ExamFilterInput = {
  actualEndDate?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  actualStartDate?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  and?: InputMaybe<Array<ExamFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  number?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExamFilterInput>>;
  participantExams?: InputMaybe<ListFilterInputTypeOfParticipantExamFilterInput>;
  place?: InputMaybe<StringOperationFilterInput>;
  plannedEndDate?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  plannedStartDate?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  status?: InputMaybe<ExamStatusOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<ExamTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type ExamModelInput = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  number: Scalars['String']['input'];
  place: Scalars['String']['input'];
  plannedEndDate: Scalars['DateTime']['input'];
  plannedStartDate: Scalars['DateTime']['input'];
  status?: ExamStatus;
  title: Scalars['String']['input'];
  type: ExamType;
};

export type ExamPaperQuestion = {
  __typename?: 'ExamPaperQuestion';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  order: Scalars['Int']['output'];
  participantExam?: Maybe<ParticipantExam>;
  participantExamId: Scalars['UUID']['output'];
  question?: Maybe<Question>;
  questionId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ExamPaperQuestionFilterInput = {
  and?: InputMaybe<Array<ExamPaperQuestionFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<ExamPaperQuestionFilterInput>>;
  order?: InputMaybe<ComparableInt32OperationFilterInput>;
  participantExam?: InputMaybe<ParticipantExamFilterInput>;
  participantExamId?: InputMaybe<ComparableGuidOperationFilterInput>;
  question?: InputMaybe<QuestionFilterInput>;
  questionId?: InputMaybe<ComparableGuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type ExamPaperQuestionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  participantExam?: InputMaybe<ParticipantExamSortInput>;
  participantExamId?: InputMaybe<SortEnumType>;
  question?: InputMaybe<QuestionSortInput>;
  questionId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type ExamSortInput = {
  actualEndDate?: InputMaybe<SortEnumType>;
  actualStartDate?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  number?: InputMaybe<SortEnumType>;
  place?: InputMaybe<SortEnumType>;
  plannedEndDate?: InputMaybe<SortEnumType>;
  plannedStartDate?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export enum ExamStatus {
  Canceled = 'CANCELED',
  Finished = 'FINISHED',
  Planned = 'PLANNED'
}

export type ExamStatusOperationFilterInput = {
  eq?: InputMaybe<ExamStatus>;
  in?: InputMaybe<Array<ExamStatus>>;
  neq?: InputMaybe<ExamStatus>;
  nin?: InputMaybe<Array<ExamStatus>>;
};

export enum ExamType {
  KnowledgeSkillTest = 'KNOWLEDGE_SKILL_TEST',
  Physical = 'PHYSICAL',
  Psychology = 'PSYCHOLOGY'
}

export type ExamTypeOperationFilterInput = {
  eq?: InputMaybe<ExamType>;
  in?: InputMaybe<Array<ExamType>>;
  neq?: InputMaybe<ExamType>;
  nin?: InputMaybe<Array<ExamType>>;
};

export type ListFilterInputTypeOfAnswerFilterInput = {
  all?: InputMaybe<AnswerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AnswerFilterInput>;
  some?: InputMaybe<AnswerFilterInput>;
};

export type ListFilterInputTypeOfCategoryFilterInput = {
  all?: InputMaybe<CategoryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CategoryFilterInput>;
  some?: InputMaybe<CategoryFilterInput>;
};

export type ListFilterInputTypeOfExamPaperQuestionFilterInput = {
  all?: InputMaybe<ExamPaperQuestionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ExamPaperQuestionFilterInput>;
  some?: InputMaybe<ExamPaperQuestionFilterInput>;
};

export type ListFilterInputTypeOfParticipantExamFilterInput = {
  all?: InputMaybe<ParticipantExamFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ParticipantExamFilterInput>;
  some?: InputMaybe<ParticipantExamFilterInput>;
};

export type ListFilterInputTypeOfParticipantFilterInput = {
  all?: InputMaybe<ParticipantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ParticipantFilterInput>;
  some?: InputMaybe<ParticipantFilterInput>;
};

export type ListFilterInputTypeOfPermissionFilterInput = {
  all?: InputMaybe<PermissionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PermissionFilterInput>;
  some?: InputMaybe<PermissionFilterInput>;
};

export type ListFilterInputTypeOfQuestionFilterInput = {
  all?: InputMaybe<QuestionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<QuestionFilterInput>;
  some?: InputMaybe<QuestionFilterInput>;
};

export type ListFilterInputTypeOfRoleFilterInput = {
  all?: InputMaybe<RoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RoleFilterInput>;
  some?: InputMaybe<RoleFilterInput>;
};

export type ListFilterInputTypeOfTopicFilterInput = {
  all?: InputMaybe<TopicFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TopicFilterInput>;
  some?: InputMaybe<TopicFilterInput>;
};

export type ListFilterInputTypeOfUserFilterInput = {
  all?: InputMaybe<UserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<UserFilterInput>;
  some?: InputMaybe<UserFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addManyParticipantToExam?: Maybe<ParticipantExamCollectionSegment>;
  addParticipantExamDecision: Scalars['Boolean']['output'];
  addParticipantExamResults: Array<Scalars['String']['output']>;
  addParticipantToExam?: Maybe<ParticipantExamCollectionSegment>;
  createCategory: Category;
  createOrUpdateExam: Exam;
  createOrUpdateTopic: Topic;
  createParticipant: Participant;
  createRole: Role;
  createUser: User;
  deleteExam: Scalars['Boolean']['output'];
  deleteParticipant: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  exportExam: Scalars['String']['output'];
  exportParticipantExamBlank: Scalars['String']['output'];
  exportParticipantExamDocument: Scalars['String']['output'];
  exportParticipantExamProtocol: Scalars['String']['output'];
  exportParticipantExamsBlanks: Scalars['String']['output'];
  exportParticipantExamsDocuments: Scalars['String']['output'];
  exportParticipantExamsProtocols: Scalars['String']['output'];
  login: TokenModel;
  refreshToken: TokenModel;
  removeParticipantFromExam?: Maybe<ParticipantExamCollectionSegment>;
  updateParticipant: Participant;
  updateRole: Role;
  updateSignatory: Signatory;
  updateUser: User;
};


export type MutationAddManyParticipantToExamArgs = {
  model: AddManyParticipantToExamModelInput;
  order?: InputMaybe<Array<ParticipantExamSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParticipantExamFilterInput>;
};


export type MutationAddParticipantExamDecisionArgs = {
  data: Scalars['String']['input'];
  decision: ParticipantExamStatus;
  participantExamId: Scalars['UUID']['input'];
};


export type MutationAddParticipantExamResultsArgs = {
  examId: Scalars['UUID']['input'];
  files: Array<Scalars['Upload']['input']>;
};


export type MutationAddParticipantToExamArgs = {
  examId: Scalars['UUID']['input'];
  order?: InputMaybe<Array<ParticipantExamSortInput>>;
  participantId: Scalars['UUID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParticipantExamFilterInput>;
};


export type MutationCreateCategoryArgs = {
  categoryModel: CategoryModelInput;
};


export type MutationCreateOrUpdateExamArgs = {
  examModel: ExamModelInput;
};


export type MutationCreateOrUpdateTopicArgs = {
  topicModel: TopicModelInput;
};


export type MutationCreateParticipantArgs = {
  participantModel: ParticipantModelInput;
};


export type MutationCreateRoleArgs = {
  model: RoleModelInput;
};


export type MutationCreateUserArgs = {
  userModel: UserModelInput;
};


export type MutationDeleteExamArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteParticipantArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationExportExamArgs = {
  examId: Scalars['UUID']['input'];
};


export type MutationExportParticipantExamBlankArgs = {
  participantExamId: Scalars['UUID']['input'];
};


export type MutationExportParticipantExamDocumentArgs = {
  participantExamId: Scalars['UUID']['input'];
};


export type MutationExportParticipantExamProtocolArgs = {
  participantExamId: Scalars['UUID']['input'];
};


export type MutationExportParticipantExamsBlanksArgs = {
  examId: Scalars['UUID']['input'];
};


export type MutationExportParticipantExamsDocumentsArgs = {
  examId: Scalars['UUID']['input'];
};


export type MutationExportParticipantExamsProtocolsArgs = {
  examId: Scalars['UUID']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRefreshTokenArgs = {
  accessToken: Scalars['String']['input'];
  refreshToken: Scalars['String']['input'];
};


export type MutationRemoveParticipantFromExamArgs = {
  order?: InputMaybe<Array<ParticipantExamSortInput>>;
  participantExamId: Scalars['UUID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParticipantExamFilterInput>;
};


export type MutationUpdateParticipantArgs = {
  participantModel: ParticipantModelInput;
};


export type MutationUpdateRoleArgs = {
  model: RoleModelInput;
};


export type MutationUpdateSignatoryArgs = {
  signatoryModel: SignatoryModelInput;
};


export type MutationUpdateUserArgs = {
  userModel: UserModelInput;
};

export type NullableOfPsychologyTestTypeOperationFilterInput = {
  eq?: InputMaybe<PsychologyTestType>;
  in?: InputMaybe<Array<InputMaybe<PsychologyTestType>>>;
  neq?: InputMaybe<PsychologyTestType>;
  nin?: InputMaybe<Array<InputMaybe<PsychologyTestType>>>;
};

export type Participant = {
  __typename?: 'Participant';
  birthdayDate: Scalars['DateTime']['output'];
  category?: Maybe<Category>;
  categoryId: Scalars['UUID']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  participantExams: Array<ParticipantExam>;
  sex: Sex;
  uin: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type ParticipantParticipantExamsArgs = {
  where?: InputMaybe<ParticipantExamFilterInput>;
};

export type ParticipantCollectionSegment = {
  __typename?: 'ParticipantCollectionSegment';
  items?: Maybe<Array<Participant>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ParticipantExam = {
  __typename?: 'ParticipantExam';
  createdAt: Scalars['DateTime']['output'];
  decision: Scalars['String']['output'];
  documentLink?: Maybe<Scalars['String']['output']>;
  exam?: Maybe<Exam>;
  examId: Scalars['UUID']['output'];
  examPaperQuestions: Array<ExamPaperQuestion>;
  id: Scalars['UUID']['output'];
  isDeleted: Scalars['Boolean']['output'];
  participant?: Maybe<Participant>;
  participantId: Scalars['UUID']['output'];
  password: Scalars['String']['output'];
  result: Scalars['String']['output'];
  status: ParticipantExamStatus;
  updatedAt: Scalars['DateTime']['output'];
  videoLink?: Maybe<Scalars['String']['output']>;
};


export type ParticipantExamExamPaperQuestionsArgs = {
  order?: InputMaybe<Array<ExamPaperQuestionSortInput>>;
};

export type ParticipantExamCollectionSegment = {
  __typename?: 'ParticipantExamCollectionSegment';
  items?: Maybe<Array<ParticipantExam>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ParticipantExamFilterInput = {
  and?: InputMaybe<Array<ParticipantExamFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  decision?: InputMaybe<StringOperationFilterInput>;
  documentLink?: InputMaybe<StringOperationFilterInput>;
  exam?: InputMaybe<ExamFilterInput>;
  examId?: InputMaybe<ComparableGuidOperationFilterInput>;
  examPaperQuestions?: InputMaybe<ListFilterInputTypeOfExamPaperQuestionFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ParticipantExamFilterInput>>;
  participant?: InputMaybe<ParticipantFilterInput>;
  participantId?: InputMaybe<ComparableGuidOperationFilterInput>;
  password?: InputMaybe<StringOperationFilterInput>;
  result?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<ParticipantExamStatusOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  videoLink?: InputMaybe<StringOperationFilterInput>;
};

export type ParticipantExamSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  decision?: InputMaybe<SortEnumType>;
  documentLink?: InputMaybe<SortEnumType>;
  exam?: InputMaybe<ExamSortInput>;
  examId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  participant?: InputMaybe<ParticipantSortInput>;
  participantId?: InputMaybe<SortEnumType>;
  password?: InputMaybe<SortEnumType>;
  result?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
  videoLink?: InputMaybe<SortEnumType>;
};

export enum ParticipantExamStatus {
  Approved = 'APPROVED',
  HasNoResult = 'HAS_NO_RESULT',
  HasResult = 'HAS_RESULT',
  NotApproved = 'NOT_APPROVED'
}

export type ParticipantExamStatusOperationFilterInput = {
  eq?: InputMaybe<ParticipantExamStatus>;
  in?: InputMaybe<Array<ParticipantExamStatus>>;
  neq?: InputMaybe<ParticipantExamStatus>;
  nin?: InputMaybe<Array<ParticipantExamStatus>>;
};

export type ParticipantFilterInput = {
  and?: InputMaybe<Array<ParticipantFilterInput>>;
  birthdayDate?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  category?: InputMaybe<CategoryFilterInput>;
  categoryId?: InputMaybe<ComparableGuidOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ParticipantFilterInput>>;
  participantExams?: InputMaybe<ListFilterInputTypeOfParticipantExamFilterInput>;
  sex?: InputMaybe<SexOperationFilterInput>;
  uin?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type ParticipantModelInput = {
  birthdayDate: Scalars['DateTime']['input'];
  categoryId: Scalars['UUID']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  sex: Sex;
  uin: Scalars['String']['input'];
};

export type ParticipantSortInput = {
  birthdayDate?: InputMaybe<SortEnumType>;
  category?: InputMaybe<CategorySortInput>;
  categoryId?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  sex?: InputMaybe<SortEnumType>;
  uin?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type Permission = {
  __typename?: 'Permission';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  name: PermissionName;
  roles: Array<Role>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PermissionCollectionSegment = {
  __typename?: 'PermissionCollectionSegment';
  items?: Maybe<Array<Permission>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PermissionFilterInput = {
  and?: InputMaybe<Array<PermissionFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<PermissionNameOperationFilterInput>;
  or?: InputMaybe<Array<PermissionFilterInput>>;
  roles?: InputMaybe<ListFilterInputTypeOfRoleFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export enum PermissionName {
  EditExams = 'EDIT_EXAMS',
  EditParticipants = 'EDIT_PARTICIPANTS',
  EditSignatory = 'EDIT_SIGNATORY',
  EditTopics = 'EDIT_TOPICS',
  EditUserRecords = 'EDIT_USER_RECORDS',
  EditUserRoles = 'EDIT_USER_ROLES',
  ViewCategoriesList = 'VIEW_CATEGORIES_LIST',
  ViewExamsList = 'VIEW_EXAMS_LIST',
  ViewParticipantsList = 'VIEW_PARTICIPANTS_LIST',
  ViewRolesList = 'VIEW_ROLES_LIST',
  ViewTopicsList = 'VIEW_TOPICS_LIST',
  ViewUsersList = 'VIEW_USERS_LIST'
}

export type PermissionNameOperationFilterInput = {
  eq?: InputMaybe<PermissionName>;
  in?: InputMaybe<Array<PermissionName>>;
  neq?: InputMaybe<PermissionName>;
  nin?: InputMaybe<Array<PermissionName>>;
};

export type PermissionSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type PhysicalQuestion = Question & {
  __typename?: 'PhysicalQuestion';
  answers: Array<Answer>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  examPaperQuestions: Array<ExamPaperQuestion>;
  id: Scalars['UUID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  topic?: Maybe<Topic>;
  topicId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PhysicalQuestionAnswersArgs = {
  order?: InputMaybe<Array<AnswerSortInput>>;
};

export type PsychologyQuestion = Question & {
  __typename?: 'PsychologyQuestion';
  answers: Array<Answer>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  examPaperQuestions: Array<ExamPaperQuestion>;
  id: Scalars['UUID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  testType: PsychologyTestType;
  title: Scalars['String']['output'];
  topic?: Maybe<Topic>;
  topicId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type PsychologyQuestionAnswersArgs = {
  order?: InputMaybe<Array<AnswerSortInput>>;
};

export enum PsychologyTestType {
  Bourdon = 'BOURDON',
  DegreeOfChronicFatigue = 'DEGREE_OF_CHRONIC_FATIGUE',
  DifficultAssociation = 'DIFFICULT_ASSOCIATION',
  Kettel = 'KETTEL',
  LandoltCircles = 'LANDOLT_CIRCLES',
  LuscherColors = 'LUSCHER_COLORS',
  Memory = 'MEMORY',
  Mmpi = 'MMPI',
  Raven = 'RAVEN',
  SchulteTables = 'SCHULTE_TABLES',
  SubjectiveLevelOfControl = 'SUBJECTIVE_LEVEL_OF_CONTROL'
}

export type Query = {
  __typename?: 'Query';
  categories?: Maybe<CategoryCollectionSegment>;
  category?: Maybe<Category>;
  exam?: Maybe<Exam>;
  exams?: Maybe<ExamCollectionSegment>;
  me?: Maybe<User>;
  participant?: Maybe<Participant>;
  participantExam?: Maybe<ParticipantExam>;
  participants?: Maybe<ParticipantCollectionSegment>;
  permission?: Maybe<Permission>;
  permissions?: Maybe<PermissionCollectionSegment>;
  role?: Maybe<Role>;
  roles?: Maybe<RoleCollectionSegment>;
  signatory: Signatory;
  topic?: Maybe<Topic>;
  topics?: Maybe<TopicCollectionSegment>;
  user?: Maybe<User>;
  users?: Maybe<UserCollectionSegment>;
};


export type QueryCategoriesArgs = {
  order?: InputMaybe<Array<CategorySortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryCategoryArgs = {
  where?: InputMaybe<CategoryFilterInput>;
};


export type QueryExamArgs = {
  where?: InputMaybe<ExamFilterInput>;
};


export type QueryExamsArgs = {
  order?: InputMaybe<Array<ExamSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ExamFilterInput>;
};


export type QueryParticipantArgs = {
  where?: InputMaybe<ParticipantFilterInput>;
};


export type QueryParticipantExamArgs = {
  where?: InputMaybe<ParticipantExamFilterInput>;
};


export type QueryParticipantsArgs = {
  order?: InputMaybe<Array<ParticipantSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ParticipantFilterInput>;
};


export type QueryPermissionArgs = {
  where?: InputMaybe<PermissionFilterInput>;
};


export type QueryPermissionsArgs = {
  order?: InputMaybe<Array<PermissionSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PermissionFilterInput>;
};


export type QueryRoleArgs = {
  where?: InputMaybe<RoleFilterInput>;
};


export type QueryRolesArgs = {
  order?: InputMaybe<Array<RoleSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<RoleFilterInput>;
};


export type QueryTopicArgs = {
  where?: InputMaybe<TopicFilterInput>;
};


export type QueryTopicsArgs = {
  order?: InputMaybe<Array<TopicSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TopicFilterInput>;
};


export type QueryUserArgs = {
  where?: InputMaybe<UserFilterInput>;
};


export type QueryUsersArgs = {
  order?: InputMaybe<Array<UserSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserFilterInput>;
};

export type Question = {
  answers: Array<Answer>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  examPaperQuestions: Array<ExamPaperQuestion>;
  id: Scalars['UUID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  topic?: Maybe<Topic>;
  topicId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type QuestionFilterInput = {
  and?: InputMaybe<Array<QuestionFilterInput>>;
  answers?: InputMaybe<ListFilterInputTypeOfAnswerFilterInput>;
  content?: InputMaybe<StringOperationFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  examPaperQuestions?: InputMaybe<ListFilterInputTypeOfExamPaperQuestionFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<QuestionFilterInput>>;
  order?: InputMaybe<ComparableNullableOfInt32OperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  topic?: InputMaybe<TopicFilterInput>;
  topicId?: InputMaybe<ComparableGuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type QuestionSortInput = {
  content?: InputMaybe<SortEnumType>;
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  topic?: InputMaybe<TopicSortInput>;
  topicId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export enum QuestionType {
  AppliedTask = 'APPLIED_TASK',
  TestQuestion = 'TEST_QUESTION',
  ThematicQuestion = 'THEMATIC_QUESTION'
}

export type RefreshToken = {
  __typename?: 'RefreshToken';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  refreshTokenExpiryTime?: Maybe<Scalars['DateTime']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type RefreshTokenFilterInput = {
  and?: InputMaybe<Array<RefreshTokenFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<RefreshTokenFilterInput>>;
  refreshTokenExpiryTime?: InputMaybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>;
  token?: InputMaybe<StringOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type RefreshTokenSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  refreshTokenExpiryTime?: InputMaybe<SortEnumType>;
  token?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  permissions: Array<Permission>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
};

export type RoleCollectionSegment = {
  __typename?: 'RoleCollectionSegment';
  items?: Maybe<Array<Role>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type RoleFilterInput = {
  and?: InputMaybe<Array<RoleFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  or?: InputMaybe<Array<RoleFilterInput>>;
  permissions?: InputMaybe<ListFilterInputTypeOfPermissionFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfUserFilterInput>;
};

export type RoleModelInput = {
  displayName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  permissionIds: Array<Scalars['UUID']['input']>;
};

export type RoleSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  displayName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type SexOperationFilterInput = {
  eq?: InputMaybe<Sex>;
  in?: InputMaybe<Array<Sex>>;
  neq?: InputMaybe<Sex>;
  nin?: InputMaybe<Array<Sex>>;
};

export type Signatory = {
  __typename?: 'Signatory';
  certificationBodyFullName: Scalars['String']['output'];
  certificationBodyShortName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  employee: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  pfoStatementHeader: Scalars['String']['output'];
  position: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SignatoryModelInput = {
  certificationBodyFullName: Scalars['String']['input'];
  certificationBodyShortName: Scalars['String']['input'];
  employee: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
  pfoStatementHeader: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ilike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TokenModel = {
  __typename?: 'TokenModel';
  accessToken: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  refreshTokenExpiryTime: Scalars['DateTime']['output'];
};

export type Topic = {
  __typename?: 'Topic';
  categories: Array<Category>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  questions: Array<Question>;
  testType?: Maybe<PsychologyTestType>;
  type: ExamType;
  updatedAt: Scalars['DateTime']['output'];
};

export type TopicCollectionSegment = {
  __typename?: 'TopicCollectionSegment';
  items?: Maybe<Array<Topic>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type TopicFilterInput = {
  and?: InputMaybe<Array<TopicFilterInput>>;
  categories?: InputMaybe<ListFilterInputTypeOfCategoryFilterInput>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TopicFilterInput>>;
  questions?: InputMaybe<ListFilterInputTypeOfQuestionFilterInput>;
  testType?: InputMaybe<NullableOfPsychologyTestTypeOperationFilterInput>;
  type?: InputMaybe<ExamTypeOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type TopicModelInput = {
  categoryIds: Array<Scalars['UUID']['input']>;
  id?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  questions: Array<TopicQuestionModelInput>;
  testType?: InputMaybe<PsychologyTestType>;
  type: ExamType;
};

export type TopicQuestion = Question & {
  __typename?: 'TopicQuestion';
  answers: Array<Answer>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  examPaperQuestions: Array<ExamPaperQuestion>;
  id: Scalars['UUID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  topic?: Maybe<Topic>;
  topicId: Scalars['UUID']['output'];
  type: QuestionType;
  updatedAt: Scalars['DateTime']['output'];
};


export type TopicQuestionAnswersArgs = {
  order?: InputMaybe<Array<AnswerSortInput>>;
};

export type TopicQuestionModelInput = {
  answers: Array<AnswerModelInput>;
  content: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  title: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['UUID']['input']>;
  type: QuestionType;
};

export type TopicSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  testType?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  refreshToken?: Maybe<RefreshToken>;
  refreshTokenId: Scalars['UUID']['output'];
  role?: Maybe<Role>;
  roleId: Scalars['UUID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserCollectionSegment = {
  __typename?: 'UserCollectionSegment';
  items?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  createdAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<ComparableGuidOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  middleName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  password?: InputMaybe<StringOperationFilterInput>;
  refreshToken?: InputMaybe<RefreshTokenFilterInput>;
  refreshTokenId?: InputMaybe<ComparableGuidOperationFilterInput>;
  role?: InputMaybe<RoleFilterInput>;
  roleId?: InputMaybe<ComparableGuidOperationFilterInput>;
  updatedAt?: InputMaybe<ComparableDateTimeOffsetOperationFilterInput>;
};

export type UserModelInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['UUID']['input']>;
  lastName: Scalars['String']['input'];
  middleName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  roleId: Scalars['UUID']['input'];
};

export type UserSortInput = {
  createdAt?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  middleName?: InputMaybe<SortEnumType>;
  password?: InputMaybe<SortEnumType>;
  refreshToken?: InputMaybe<RefreshTokenSortInput>;
  refreshTokenId?: InputMaybe<SortEnumType>;
  role?: InputMaybe<RoleSortInput>;
  roleId?: InputMaybe<SortEnumType>;
  updatedAt?: InputMaybe<SortEnumType>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'TokenModel', accessToken: string, refreshToken: string, refreshTokenExpiryTime: any } };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', firstName: string, lastName: string, middleName?: string | null, email: string, password: string, isActive: boolean, roleId: any, fullName: string, id: any, createdAt: any, updatedAt: any, role?: { __typename?: 'Role', id: any, displayName: string, permissions: Array<{ __typename?: 'Permission', id: any, name: PermissionName, description: string, displayName: string }> } | null } | null };

export type CategoriesQueryVariables = Exact<{
  where?: InputMaybe<CategoryFilterInput>;
}>;


export type CategoriesQuery = { __typename?: 'Query', categories?: { __typename?: 'CategoryCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Category', name: string, description: string, id: any, createdAt: any, updatedAt: any, topics: Array<{ __typename?: 'Topic', id: any, name: string }> }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasPreviousPage: boolean, hasNextPage: boolean } } | null };

export type CategoriesItemFragment = { __typename?: 'Category', name: string, description: string, id: any, createdAt: any, updatedAt: any, topics: Array<{ __typename?: 'Topic', id: any, name: string }> };

export type CategoryQueryVariables = Exact<{
  where?: InputMaybe<CategoryFilterInput>;
}>;


export type CategoryQuery = { __typename?: 'Query', category?: { __typename?: 'Category', name: string, description: string, id: any, createdAt: any, updatedAt: any, topics: Array<{ __typename?: 'Topic', id: any, name: string, type: ExamType, questions: Array<{ __typename?: 'PhysicalQuestion', id: any } | { __typename?: 'PsychologyQuestion', id: any } | { __typename?: 'TopicQuestion', id: any }> }> } | null };

export type AddManyParticipantToExamMutationVariables = Exact<{
  model: AddManyParticipantToExamModelInput;
  where?: InputMaybe<ParticipantExamFilterInput>;
}>;


export type AddManyParticipantToExamMutation = { __typename?: 'Mutation', addManyParticipantToExam?: { __typename?: 'ParticipantExamCollectionSegment', items?: Array<{ __typename?: 'ParticipantExam', id: any }> | null } | null };

export type CreateOrUpdateExamMutationVariables = Exact<{
  examModel: ExamModelInput;
}>;


export type CreateOrUpdateExamMutation = { __typename?: 'Mutation', createOrUpdateExam: { __typename?: 'Exam', title: string, number: string, status: ExamStatus, place: string, plannedStartDate: any, plannedEndDate: any, actualStartDate?: any | null, actualEndDate?: any | null, id: any, createdAt: any, updatedAt: any, participantExams: Array<{ __typename?: 'ParticipantExam', id: any, password: string, status: ParticipantExamStatus, isDeleted: boolean, participant?: { __typename?: 'Participant', id: any, uin: string, sex: Sex, createdAt: any, updatedAt: any } | null }> } };

export type ExamQueryVariables = Exact<{
  where?: InputMaybe<ExamFilterInput>;
}>;


export type ExamQuery = { __typename?: 'Query', exam?: { __typename?: 'Exam', title: string, number: string, status: ExamStatus, type: ExamType, place: string, plannedStartDate: any, plannedEndDate: any, id: any, createdAt: any, updatedAt: any, participantExams: Array<{ __typename?: 'ParticipantExam', id: any, password: string, status: ParticipantExamStatus, isDeleted: boolean, participant?: { __typename?: 'Participant', id: any, uin: string, sex: Sex, birthdayDate: any, createdAt: any, updatedAt: any, category?: { __typename?: 'Category', id: any, name: string } | null } | null }> } | null };

export type ParticipantExamFragment = { __typename?: 'ParticipantExam', id: any, password: string, status: ParticipantExamStatus, isDeleted: boolean, participant?: { __typename?: 'Participant', id: any, uin: string, sex: Sex, birthdayDate: any, createdAt: any, updatedAt: any, category?: { __typename?: 'Category', id: any, name: string } | null } | null };

export type ExamsQueryVariables = Exact<{
  where?: InputMaybe<ExamFilterInput>;
  order?: InputMaybe<Array<ExamSortInput> | ExamSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ExamsQuery = { __typename?: 'Query', exams?: { __typename?: 'ExamCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Exam', title: string, status: ExamStatus, place: string, plannedStartDate: any, plannedEndDate: any, actualStartDate?: any | null, actualEndDate?: any | null, id: any, type: ExamType, createdAt: any, updatedAt: any }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type ExamsItemFragment = { __typename?: 'Exam', title: string, status: ExamStatus, place: string, plannedStartDate: any, plannedEndDate: any, actualStartDate?: any | null, actualEndDate?: any | null, id: any, type: ExamType, createdAt: any, updatedAt: any };

export type ExportExamMutationVariables = Exact<{
  examId: Scalars['UUID']['input'];
}>;


export type ExportExamMutation = { __typename?: 'Mutation', exportExam: string };

export type RemoveParticipantToExamMutationVariables = Exact<{
  participantExamId: Scalars['UUID']['input'];
  where?: InputMaybe<ParticipantExamFilterInput>;
}>;


export type RemoveParticipantToExamMutation = { __typename?: 'Mutation', removeParticipantFromExam?: { __typename?: 'ParticipantExamCollectionSegment', items?: Array<{ __typename?: 'ParticipantExam', id: any }> | null } | null };

export type CreateParticipantMutationVariables = Exact<{
  participantModel: ParticipantModelInput;
}>;


export type CreateParticipantMutation = { __typename?: 'Mutation', createParticipant: { __typename?: 'Participant', uin: string, sex: Sex, isDeleted: boolean, id: any, createdAt: any, updatedAt: any } };

export type DeleteParticipantMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteParticipantMutation = { __typename?: 'Mutation', deleteParticipant: boolean };

export type ParticipantQueryVariables = Exact<{
  where?: InputMaybe<ParticipantFilterInput>;
}>;


export type ParticipantQuery = { __typename?: 'Query', participant?: { __typename?: 'Participant', uin: string, sex: Sex, birthdayDate: any, isDeleted: boolean, id: any, createdAt: any, updatedAt: any, category?: { __typename?: 'Category', id: any, name: string } | null } | null };

export type ParticipantsQueryVariables = Exact<{
  where?: InputMaybe<ParticipantFilterInput>;
  order?: InputMaybe<Array<ParticipantSortInput> | ParticipantSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ParticipantsQuery = { __typename?: 'Query', participants?: { __typename?: 'ParticipantCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Participant', uin: string, sex: Sex, birthdayDate: any, isDeleted: boolean, id: any, createdAt: any, updatedAt: any, category?: { __typename?: 'Category', id: any, name: string } | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasPreviousPage: boolean, hasNextPage: boolean } } | null };

export type ParticipantsItemFragment = { __typename?: 'Participant', uin: string, sex: Sex, birthdayDate: any, isDeleted: boolean, id: any, createdAt: any, updatedAt: any, category?: { __typename?: 'Category', id: any, name: string } | null };

export type UpdateParticipantMutationVariables = Exact<{
  participantModel: ParticipantModelInput;
}>;


export type UpdateParticipantMutation = { __typename?: 'Mutation', updateParticipant: { __typename?: 'Participant', uin: string, sex: Sex, birthdayDate: any, isDeleted: boolean, id: any, createdAt: any, updatedAt: any, category?: { __typename?: 'Category', id: any, name: string } | null } };

export type AddParticipantExamDecisionMutationVariables = Exact<{
  data: Scalars['String']['input'];
  decision: ParticipantExamStatus;
  participantExamId: Scalars['UUID']['input'];
}>;


export type AddParticipantExamDecisionMutation = { __typename?: 'Mutation', addParticipantExamDecision: boolean };

export type AddParticipantExamResultsMutationVariables = Exact<{
  files: Array<Scalars['Upload']['input']> | Scalars['Upload']['input'];
  examId: Scalars['UUID']['input'];
}>;


export type AddParticipantExamResultsMutation = { __typename?: 'Mutation', addParticipantExamResults: Array<string> };

export type ExportParticipantExamBlankMutationVariables = Exact<{
  participantExamId: Scalars['UUID']['input'];
}>;


export type ExportParticipantExamBlankMutation = { __typename?: 'Mutation', exportParticipantExamBlank: string };

export type ExportParticipantExamDocumentMutationVariables = Exact<{
  participantExamId: Scalars['UUID']['input'];
}>;


export type ExportParticipantExamDocumentMutation = { __typename?: 'Mutation', exportParticipantExamDocument: string };

export type ExportParticipantExamProtocolMutationVariables = Exact<{
  participantExamId: Scalars['UUID']['input'];
}>;


export type ExportParticipantExamProtocolMutation = { __typename?: 'Mutation', exportParticipantExamProtocol: string };

export type ExportParticipantExamsBlanksMutationVariables = Exact<{
  examId: Scalars['UUID']['input'];
}>;


export type ExportParticipantExamsBlanksMutation = { __typename?: 'Mutation', exportParticipantExamsBlanks: string };

export type ExportParticipantExamsDocumentsMutationVariables = Exact<{
  examId: Scalars['UUID']['input'];
}>;


export type ExportParticipantExamsDocumentsMutation = { __typename?: 'Mutation', exportParticipantExamsDocuments: string };

export type ExportParticipantExamsProtocolsMutationVariables = Exact<{
  examId: Scalars['UUID']['input'];
}>;


export type ExportParticipantExamsProtocolsMutation = { __typename?: 'Mutation', exportParticipantExamsProtocols: string };

export type ParticipantExamQueryVariables = Exact<{
  where?: InputMaybe<ParticipantExamFilterInput>;
}>;


export type ParticipantExamQuery = { __typename?: 'Query', participantExam?: { __typename?: 'ParticipantExam', id: any, isDeleted: boolean, createdAt: any, updatedAt: any, result: string, decision: string, status: ParticipantExamStatus, videoLink?: string | null, participant?: { __typename?: 'Participant', id: any, uin: string, sex: Sex, birthdayDate: any, category?: { __typename?: 'Category', id: any, name: string } | null } | null, examPaperQuestions: Array<{ __typename?: 'ExamPaperQuestion', id: any, order: number, question?: { __typename?: 'PhysicalQuestion', id: any, title: string, content: string, answers: Array<{ __typename?: 'Answer', id: any, order?: number | null, text: string, isCorrect: boolean }> } | { __typename?: 'PsychologyQuestion', id: any, title: string, content: string, answers: Array<{ __typename?: 'Answer', id: any, order?: number | null, text: string, isCorrect: boolean }> } | { __typename?: 'TopicQuestion', type: QuestionType, id: any, title: string, content: string, answers: Array<{ __typename?: 'Answer', id: any, order?: number | null, text: string, isCorrect: boolean }> } | null }> } | null };

export type PermissionQueryVariables = Exact<{
  where?: InputMaybe<PermissionFilterInput>;
}>;


export type PermissionQuery = { __typename?: 'Query', permission?: { __typename?: 'Permission', name: PermissionName, displayName: string, description: string, id: any, createdAt: any, updatedAt: any, roles: Array<{ __typename?: 'Role', id: any, displayName: string }> } | null };

export type PermissionsQueryVariables = Exact<{
  where?: InputMaybe<PermissionFilterInput>;
}>;


export type PermissionsQuery = { __typename?: 'Query', permissions?: { __typename?: 'PermissionCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Permission', name: PermissionName, displayName: string, description: string, id: any, createdAt: any, updatedAt: any, roles: Array<{ __typename?: 'Role', id: any, displayName: string }> }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type CreateRoleMutationVariables = Exact<{
  model: RoleModelInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'Role', displayName: string, id: any, createdAt: any, updatedAt: any, permissions: Array<{ __typename?: 'Permission', id: any, name: PermissionName, description: string, displayName: string, createdAt: any, updatedAt: any }> } };

export type RoleQueryVariables = Exact<{
  where?: InputMaybe<RoleFilterInput>;
}>;


export type RoleQuery = { __typename?: 'Query', role?: { __typename?: 'Role', displayName: string, id: any, createdAt: any, updatedAt: any, permissions: Array<{ __typename?: 'Permission', id: any, name: PermissionName, description: string, displayName: string, createdAt: any, updatedAt: any }>, users: Array<{ __typename?: 'User', id: any, fullName: string, roleId: any, updatedAt: any, createdAt: any }> } | null };

export type RolesQueryVariables = Exact<{
  where?: InputMaybe<RoleFilterInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type RolesQuery = { __typename?: 'Query', roles?: { __typename?: 'RoleCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Role', id: any, displayName: string, createdAt: any, updatedAt: any, permissions: Array<{ __typename?: 'Permission', id: any, name: PermissionName, description: string, displayName: string, createdAt: any, updatedAt: any }>, users: Array<{ __typename?: 'User', id: any, fullName: string, roleId: any, updatedAt: any, createdAt: any }> }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasPreviousPage: boolean, hasNextPage: boolean } } | null };

export type UpdateRoleMutationVariables = Exact<{
  model: RoleModelInput;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'Role', displayName: string, id: any, createdAt: any, updatedAt: any, permissions: Array<{ __typename?: 'Permission', id: any, name: PermissionName, description: string, displayName: string, createdAt: any, updatedAt: any }> } };

export type SignatoryQueryVariables = Exact<{ [key: string]: never; }>;


export type SignatoryQuery = { __typename?: 'Query', signatory: { __typename?: 'Signatory', certificationBodyFullName: string, certificationBodyShortName: string, employee: string, id: any, pfoStatementHeader: string, position: string } };

export type UpdateSignatoryMutationVariables = Exact<{
  signatoryModel: SignatoryModelInput;
}>;


export type UpdateSignatoryMutation = { __typename?: 'Mutation', updateSignatory: { __typename?: 'Signatory', certificationBodyFullName: string, certificationBodyShortName: string, employee: string, id: any, pfoStatementHeader: string, position: string } };

export type CreateOrUpdateTopicMutationVariables = Exact<{
  topicModel: TopicModelInput;
}>;


export type CreateOrUpdateTopicMutation = { __typename?: 'Mutation', createOrUpdateTopic: { __typename?: 'Topic', id: any, name: string, createdAt: any, updatedAt: any, testType?: PsychologyTestType | null, type: ExamType, questions: Array<{ __typename?: 'PhysicalQuestion', id: any, content: string, title: string, answers: Array<{ __typename?: 'Answer', id: any, isCorrect: boolean, text: string, updatedAt: any, createdAt: any }> } | { __typename?: 'PsychologyQuestion', id: any, content: string, title: string, answers: Array<{ __typename?: 'Answer', id: any, isCorrect: boolean, text: string, updatedAt: any, createdAt: any }> } | { __typename?: 'TopicQuestion', type: QuestionType, id: any, content: string, title: string, answers: Array<{ __typename?: 'Answer', id: any, isCorrect: boolean, text: string, updatedAt: any, createdAt: any }> }>, categories: Array<{ __typename?: 'Category', id: any, name: string, description: string }> } };

export type TopicQueryVariables = Exact<{
  where?: InputMaybe<TopicFilterInput>;
}>;


export type TopicQuery = { __typename?: 'Query', topic?: { __typename?: 'Topic', id: any, name: string, createdAt: any, updatedAt: any, questions: Array<{ __typename?: 'PhysicalQuestion' } | { __typename?: 'PsychologyQuestion' } | { __typename?: 'TopicQuestion', id: any, content: string, title: string, type: QuestionType, answers: Array<{ __typename?: 'Answer', id: any, isCorrect: boolean, text: string, updatedAt: any, createdAt: any }> }>, categories: Array<{ __typename?: 'Category', id: any, name: string, description: string }> } | null };

export type TopicsQueryVariables = Exact<{
  where?: InputMaybe<TopicFilterInput>;
  order?: InputMaybe<Array<TopicSortInput> | TopicSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type TopicsQuery = { __typename?: 'Query', topics?: { __typename?: 'TopicCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'Topic', id: any, type: ExamType, name: string, createdAt: any, updatedAt: any, testType?: PsychologyTestType | null, categories: Array<{ __typename?: 'Category', id: any, name: string, description: string }> }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type TopicsItemFragment = { __typename?: 'Topic', id: any, type: ExamType, name: string, createdAt: any, updatedAt: any, testType?: PsychologyTestType | null, categories: Array<{ __typename?: 'Category', id: any, name: string, description: string }> };

export type CreateUserMutationVariables = Exact<{
  userModel: UserModelInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', firstName: string, lastName: string, middleName?: string | null, email: string, password: string, isActive: boolean, roleId: any, fullName: string, id: any, createdAt: any, updatedAt: any, role?: { __typename?: 'Role', id: any, displayName: string } | null } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type UpdateUserMutationVariables = Exact<{
  userModel: UserModelInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', firstName: string, lastName: string, middleName?: string | null, email: string, password: string, isActive: boolean, roleId: any, fullName: string, id: any, createdAt: any, updatedAt: any, role?: { __typename?: 'Role', id: any, displayName: string } | null } };

export type UserQueryVariables = Exact<{
  where?: InputMaybe<UserFilterInput>;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', firstName: string, lastName: string, middleName?: string | null, email: string, password: string, isActive: boolean, roleId: any, fullName: string, id: any, createdAt: any, updatedAt: any, role?: { __typename?: 'Role', id: any, displayName: string } | null } | null };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserFilterInput>;
  order?: InputMaybe<Array<UserSortInput> | UserSortInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users?: { __typename?: 'UserCollectionSegment', totalCount: number, items?: Array<{ __typename?: 'User', firstName: string, lastName: string, middleName?: string | null, email: string, password: string, isActive: boolean, roleId: any, fullName: string, id: any, createdAt: any, updatedAt: any, role?: { __typename?: 'Role', id: any, displayName: string, updatedAt: any, createdAt: any } | null }> | null, pageInfo: { __typename?: 'CollectionSegmentInfo', hasNextPage: boolean, hasPreviousPage: boolean } } | null };

export type UserFragment = { __typename?: 'User', firstName: string, lastName: string, middleName?: string | null, email: string, password: string, isActive: boolean, roleId: any, fullName: string, id: any, createdAt: any, updatedAt: any, role?: { __typename?: 'Role', id: any, displayName: string, updatedAt: any, createdAt: any } | null };

export const CategoriesItemFragmentDoc = gql`
    fragment CategoriesItem on Category {
  name
  description
  topics {
    id
    name
  }
  id
  createdAt
  updatedAt
}
    `;
export const ParticipantExamFragmentDoc = gql`
    fragment ParticipantExam on ParticipantExam {
  id
  password
  status
  isDeleted
  participant {
    id
    uin
    sex
    category {
      id
      name
    }
    birthdayDate
    createdAt
    updatedAt
  }
}
    `;
export const ExamsItemFragmentDoc = gql`
    fragment ExamsItem on Exam {
  title
  status
  place
  plannedStartDate
  plannedEndDate
  actualStartDate
  actualEndDate
  id
  type
  createdAt
  updatedAt
}
    `;
export const ParticipantsItemFragmentDoc = gql`
    fragment ParticipantsItem on Participant {
  uin
  sex
  birthdayDate
  category {
    id
    name
  }
  isDeleted
  id
  createdAt
  updatedAt
}
    `;
export const TopicsItemFragmentDoc = gql`
    fragment TopicsItem on Topic {
  id
  type
  name
  createdAt
  updatedAt
  type
  testType
  categories {
    id
    name
    description
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  firstName
  lastName
  middleName
  email
  password
  isActive
  roleId
  fullName
  id
  createdAt
  updatedAt
  role {
    id
    displayName
    updatedAt
    createdAt
  }
}
    `;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    accessToken
    refreshToken
    refreshTokenExpiryTime
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MeDocument = gql`
    query me {
  me {
    firstName
    lastName
    middleName
    email
    password
    isActive
    roleId
    role {
      id
      displayName
      permissions {
        id
        name
        description
        displayName
      }
    }
    fullName
    id
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CategoriesDocument = gql`
    query categories($where: CategoryFilterInput) {
  categories(where: $where) {
    items {
      ...CategoriesItem
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
    ${CategoriesItemFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const CategoryDocument = gql`
    query category($where: CategoryFilterInput) {
  category(where: $where) {
    name
    description
    topics {
      id
      name
      type
      questions {
        id
      }
    }
    id
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useCategoryQuery__
 *
 * To run a query within a React component, call `useCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCategoryQuery(baseOptions?: Apollo.QueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
      }
export function useCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryQuery, CategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryQuery, CategoryQueryVariables>(CategoryDocument, options);
        }
export type CategoryQueryHookResult = ReturnType<typeof useCategoryQuery>;
export type CategoryLazyQueryHookResult = ReturnType<typeof useCategoryLazyQuery>;
export type CategoryQueryResult = Apollo.QueryResult<CategoryQuery, CategoryQueryVariables>;
export const AddManyParticipantToExamDocument = gql`
    mutation addManyParticipantToExam($model: AddManyParticipantToExamModelInput!, $where: ParticipantExamFilterInput) {
  addManyParticipantToExam(model: $model, where: $where) {
    items {
      id
    }
  }
}
    `;
export type AddManyParticipantToExamMutationFn = Apollo.MutationFunction<AddManyParticipantToExamMutation, AddManyParticipantToExamMutationVariables>;

/**
 * __useAddManyParticipantToExamMutation__
 *
 * To run a mutation, you first call `useAddManyParticipantToExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManyParticipantToExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManyParticipantToExamMutation, { data, loading, error }] = useAddManyParticipantToExamMutation({
 *   variables: {
 *      model: // value for 'model'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAddManyParticipantToExamMutation(baseOptions?: Apollo.MutationHookOptions<AddManyParticipantToExamMutation, AddManyParticipantToExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManyParticipantToExamMutation, AddManyParticipantToExamMutationVariables>(AddManyParticipantToExamDocument, options);
      }
export type AddManyParticipantToExamMutationHookResult = ReturnType<typeof useAddManyParticipantToExamMutation>;
export type AddManyParticipantToExamMutationResult = Apollo.MutationResult<AddManyParticipantToExamMutation>;
export type AddManyParticipantToExamMutationOptions = Apollo.BaseMutationOptions<AddManyParticipantToExamMutation, AddManyParticipantToExamMutationVariables>;
export const CreateOrUpdateExamDocument = gql`
    mutation createOrUpdateExam($examModel: ExamModelInput!) {
  createOrUpdateExam(examModel: $examModel) {
    title
    number
    status
    place
    plannedStartDate
    plannedEndDate
    actualStartDate
    actualEndDate
    id
    createdAt
    updatedAt
    participantExams {
      id
      password
      status
      isDeleted
      participant {
        id
        uin
        sex
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export type CreateOrUpdateExamMutationFn = Apollo.MutationFunction<CreateOrUpdateExamMutation, CreateOrUpdateExamMutationVariables>;

/**
 * __useCreateOrUpdateExamMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateExamMutation, { data, loading, error }] = useCreateOrUpdateExamMutation({
 *   variables: {
 *      examModel: // value for 'examModel'
 *   },
 * });
 */
export function useCreateOrUpdateExamMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateExamMutation, CreateOrUpdateExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateExamMutation, CreateOrUpdateExamMutationVariables>(CreateOrUpdateExamDocument, options);
      }
export type CreateOrUpdateExamMutationHookResult = ReturnType<typeof useCreateOrUpdateExamMutation>;
export type CreateOrUpdateExamMutationResult = Apollo.MutationResult<CreateOrUpdateExamMutation>;
export type CreateOrUpdateExamMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateExamMutation, CreateOrUpdateExamMutationVariables>;
export const ExamDocument = gql`
    query exam($where: ExamFilterInput) {
  exam(where: $where) {
    title
    number
    status
    type
    place
    plannedStartDate
    plannedEndDate
    id
    createdAt
    updatedAt
    participantExams {
      ...ParticipantExam
    }
  }
}
    ${ParticipantExamFragmentDoc}`;

/**
 * __useExamQuery__
 *
 * To run a query within a React component, call `useExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExamQuery(baseOptions?: Apollo.QueryHookOptions<ExamQuery, ExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
      }
export function useExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamQuery, ExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
        }
export type ExamQueryHookResult = ReturnType<typeof useExamQuery>;
export type ExamLazyQueryHookResult = ReturnType<typeof useExamLazyQuery>;
export type ExamQueryResult = Apollo.QueryResult<ExamQuery, ExamQueryVariables>;
export const ExamsDocument = gql`
    query exams($where: ExamFilterInput, $order: [ExamSortInput!], $skip: Int, $take: Int) {
  exams(where: $where, order: $order, skip: $skip, take: $take) {
    items {
      ...ExamsItem
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${ExamsItemFragmentDoc}`;

/**
 * __useExamsQuery__
 *
 * To run a query within a React component, call `useExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useExamsQuery(baseOptions?: Apollo.QueryHookOptions<ExamsQuery, ExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamsQuery, ExamsQueryVariables>(ExamsDocument, options);
      }
export function useExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamsQuery, ExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamsQuery, ExamsQueryVariables>(ExamsDocument, options);
        }
export type ExamsQueryHookResult = ReturnType<typeof useExamsQuery>;
export type ExamsLazyQueryHookResult = ReturnType<typeof useExamsLazyQuery>;
export type ExamsQueryResult = Apollo.QueryResult<ExamsQuery, ExamsQueryVariables>;
export const ExportExamDocument = gql`
    mutation exportExam($examId: UUID!) {
  exportExam(examId: $examId)
}
    `;
export type ExportExamMutationFn = Apollo.MutationFunction<ExportExamMutation, ExportExamMutationVariables>;

/**
 * __useExportExamMutation__
 *
 * To run a mutation, you first call `useExportExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportExamMutation, { data, loading, error }] = useExportExamMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useExportExamMutation(baseOptions?: Apollo.MutationHookOptions<ExportExamMutation, ExportExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportExamMutation, ExportExamMutationVariables>(ExportExamDocument, options);
      }
export type ExportExamMutationHookResult = ReturnType<typeof useExportExamMutation>;
export type ExportExamMutationResult = Apollo.MutationResult<ExportExamMutation>;
export type ExportExamMutationOptions = Apollo.BaseMutationOptions<ExportExamMutation, ExportExamMutationVariables>;
export const RemoveParticipantToExamDocument = gql`
    mutation removeParticipantToExam($participantExamId: UUID!, $where: ParticipantExamFilterInput) {
  removeParticipantFromExam(participantExamId: $participantExamId, where: $where) {
    items {
      id
    }
  }
}
    `;
export type RemoveParticipantToExamMutationFn = Apollo.MutationFunction<RemoveParticipantToExamMutation, RemoveParticipantToExamMutationVariables>;

/**
 * __useRemoveParticipantToExamMutation__
 *
 * To run a mutation, you first call `useRemoveParticipantToExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParticipantToExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParticipantToExamMutation, { data, loading, error }] = useRemoveParticipantToExamMutation({
 *   variables: {
 *      participantExamId: // value for 'participantExamId'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRemoveParticipantToExamMutation(baseOptions?: Apollo.MutationHookOptions<RemoveParticipantToExamMutation, RemoveParticipantToExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveParticipantToExamMutation, RemoveParticipantToExamMutationVariables>(RemoveParticipantToExamDocument, options);
      }
export type RemoveParticipantToExamMutationHookResult = ReturnType<typeof useRemoveParticipantToExamMutation>;
export type RemoveParticipantToExamMutationResult = Apollo.MutationResult<RemoveParticipantToExamMutation>;
export type RemoveParticipantToExamMutationOptions = Apollo.BaseMutationOptions<RemoveParticipantToExamMutation, RemoveParticipantToExamMutationVariables>;
export const CreateParticipantDocument = gql`
    mutation createParticipant($participantModel: ParticipantModelInput!) {
  createParticipant(participantModel: $participantModel) {
    uin
    sex
    isDeleted
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateParticipantMutationFn = Apollo.MutationFunction<CreateParticipantMutation, CreateParticipantMutationVariables>;

/**
 * __useCreateParticipantMutation__
 *
 * To run a mutation, you first call `useCreateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParticipantMutation, { data, loading, error }] = useCreateParticipantMutation({
 *   variables: {
 *      participantModel: // value for 'participantModel'
 *   },
 * });
 */
export function useCreateParticipantMutation(baseOptions?: Apollo.MutationHookOptions<CreateParticipantMutation, CreateParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParticipantMutation, CreateParticipantMutationVariables>(CreateParticipantDocument, options);
      }
export type CreateParticipantMutationHookResult = ReturnType<typeof useCreateParticipantMutation>;
export type CreateParticipantMutationResult = Apollo.MutationResult<CreateParticipantMutation>;
export type CreateParticipantMutationOptions = Apollo.BaseMutationOptions<CreateParticipantMutation, CreateParticipantMutationVariables>;
export const DeleteParticipantDocument = gql`
    mutation deleteParticipant($id: UUID!) {
  deleteParticipant(id: $id)
}
    `;
export type DeleteParticipantMutationFn = Apollo.MutationFunction<DeleteParticipantMutation, DeleteParticipantMutationVariables>;

/**
 * __useDeleteParticipantMutation__
 *
 * To run a mutation, you first call `useDeleteParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteParticipantMutation, { data, loading, error }] = useDeleteParticipantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteParticipantMutation(baseOptions?: Apollo.MutationHookOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteParticipantMutation, DeleteParticipantMutationVariables>(DeleteParticipantDocument, options);
      }
export type DeleteParticipantMutationHookResult = ReturnType<typeof useDeleteParticipantMutation>;
export type DeleteParticipantMutationResult = Apollo.MutationResult<DeleteParticipantMutation>;
export type DeleteParticipantMutationOptions = Apollo.BaseMutationOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>;
export const ParticipantDocument = gql`
    query participant($where: ParticipantFilterInput) {
  participant(where: $where) {
    uin
    sex
    birthdayDate
    isDeleted
    id
    createdAt
    updatedAt
    category {
      id
      name
    }
  }
}
    `;

/**
 * __useParticipantQuery__
 *
 * To run a query within a React component, call `useParticipantQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useParticipantQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, options);
      }
export function useParticipantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantQuery, ParticipantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantQuery, ParticipantQueryVariables>(ParticipantDocument, options);
        }
export type ParticipantQueryHookResult = ReturnType<typeof useParticipantQuery>;
export type ParticipantLazyQueryHookResult = ReturnType<typeof useParticipantLazyQuery>;
export type ParticipantQueryResult = Apollo.QueryResult<ParticipantQuery, ParticipantQueryVariables>;
export const ParticipantsDocument = gql`
    query participants($where: ParticipantFilterInput, $order: [ParticipantSortInput!], $skip: Int, $take: Int) {
  participants(where: $where, take: $take, skip: $skip, order: $order) {
    items {
      ...ParticipantsItem
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
    ${ParticipantsItemFragmentDoc}`;

/**
 * __useParticipantsQuery__
 *
 * To run a query within a React component, call `useParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useParticipantsQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, options);
      }
export function useParticipantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantsQuery, ParticipantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantsQuery, ParticipantsQueryVariables>(ParticipantsDocument, options);
        }
export type ParticipantsQueryHookResult = ReturnType<typeof useParticipantsQuery>;
export type ParticipantsLazyQueryHookResult = ReturnType<typeof useParticipantsLazyQuery>;
export type ParticipantsQueryResult = Apollo.QueryResult<ParticipantsQuery, ParticipantsQueryVariables>;
export const UpdateParticipantDocument = gql`
    mutation updateParticipant($participantModel: ParticipantModelInput!) {
  updateParticipant(participantModel: $participantModel) {
    uin
    sex
    birthdayDate
    category {
      id
      name
    }
    isDeleted
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateParticipantMutationFn = Apollo.MutationFunction<UpdateParticipantMutation, UpdateParticipantMutationVariables>;

/**
 * __useUpdateParticipantMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantMutation, { data, loading, error }] = useUpdateParticipantMutation({
 *   variables: {
 *      participantModel: // value for 'participantModel'
 *   },
 * });
 */
export function useUpdateParticipantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateParticipantMutation, UpdateParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateParticipantMutation, UpdateParticipantMutationVariables>(UpdateParticipantDocument, options);
      }
export type UpdateParticipantMutationHookResult = ReturnType<typeof useUpdateParticipantMutation>;
export type UpdateParticipantMutationResult = Apollo.MutationResult<UpdateParticipantMutation>;
export type UpdateParticipantMutationOptions = Apollo.BaseMutationOptions<UpdateParticipantMutation, UpdateParticipantMutationVariables>;
export const AddParticipantExamDecisionDocument = gql`
    mutation addParticipantExamDecision($data: String!, $decision: ParticipantExamStatus!, $participantExamId: UUID!) {
  addParticipantExamDecision(
    data: $data
    decision: $decision
    participantExamId: $participantExamId
  )
}
    `;
export type AddParticipantExamDecisionMutationFn = Apollo.MutationFunction<AddParticipantExamDecisionMutation, AddParticipantExamDecisionMutationVariables>;

/**
 * __useAddParticipantExamDecisionMutation__
 *
 * To run a mutation, you first call `useAddParticipantExamDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantExamDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantExamDecisionMutation, { data, loading, error }] = useAddParticipantExamDecisionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      decision: // value for 'decision'
 *      participantExamId: // value for 'participantExamId'
 *   },
 * });
 */
export function useAddParticipantExamDecisionMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantExamDecisionMutation, AddParticipantExamDecisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantExamDecisionMutation, AddParticipantExamDecisionMutationVariables>(AddParticipantExamDecisionDocument, options);
      }
export type AddParticipantExamDecisionMutationHookResult = ReturnType<typeof useAddParticipantExamDecisionMutation>;
export type AddParticipantExamDecisionMutationResult = Apollo.MutationResult<AddParticipantExamDecisionMutation>;
export type AddParticipantExamDecisionMutationOptions = Apollo.BaseMutationOptions<AddParticipantExamDecisionMutation, AddParticipantExamDecisionMutationVariables>;
export const AddParticipantExamResultsDocument = gql`
    mutation addParticipantExamResults($files: [Upload!]!, $examId: UUID!) {
  addParticipantExamResults(files: $files, examId: $examId)
}
    `;
export type AddParticipantExamResultsMutationFn = Apollo.MutationFunction<AddParticipantExamResultsMutation, AddParticipantExamResultsMutationVariables>;

/**
 * __useAddParticipantExamResultsMutation__
 *
 * To run a mutation, you first call `useAddParticipantExamResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantExamResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantExamResultsMutation, { data, loading, error }] = useAddParticipantExamResultsMutation({
 *   variables: {
 *      files: // value for 'files'
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useAddParticipantExamResultsMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantExamResultsMutation, AddParticipantExamResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantExamResultsMutation, AddParticipantExamResultsMutationVariables>(AddParticipantExamResultsDocument, options);
      }
export type AddParticipantExamResultsMutationHookResult = ReturnType<typeof useAddParticipantExamResultsMutation>;
export type AddParticipantExamResultsMutationResult = Apollo.MutationResult<AddParticipantExamResultsMutation>;
export type AddParticipantExamResultsMutationOptions = Apollo.BaseMutationOptions<AddParticipantExamResultsMutation, AddParticipantExamResultsMutationVariables>;
export const ExportParticipantExamBlankDocument = gql`
    mutation exportParticipantExamBlank($participantExamId: UUID!) {
  exportParticipantExamBlank(participantExamId: $participantExamId)
}
    `;
export type ExportParticipantExamBlankMutationFn = Apollo.MutationFunction<ExportParticipantExamBlankMutation, ExportParticipantExamBlankMutationVariables>;

/**
 * __useExportParticipantExamBlankMutation__
 *
 * To run a mutation, you first call `useExportParticipantExamBlankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportParticipantExamBlankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportParticipantExamBlankMutation, { data, loading, error }] = useExportParticipantExamBlankMutation({
 *   variables: {
 *      participantExamId: // value for 'participantExamId'
 *   },
 * });
 */
export function useExportParticipantExamBlankMutation(baseOptions?: Apollo.MutationHookOptions<ExportParticipantExamBlankMutation, ExportParticipantExamBlankMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportParticipantExamBlankMutation, ExportParticipantExamBlankMutationVariables>(ExportParticipantExamBlankDocument, options);
      }
export type ExportParticipantExamBlankMutationHookResult = ReturnType<typeof useExportParticipantExamBlankMutation>;
export type ExportParticipantExamBlankMutationResult = Apollo.MutationResult<ExportParticipantExamBlankMutation>;
export type ExportParticipantExamBlankMutationOptions = Apollo.BaseMutationOptions<ExportParticipantExamBlankMutation, ExportParticipantExamBlankMutationVariables>;
export const ExportParticipantExamDocumentDocument = gql`
    mutation exportParticipantExamDocument($participantExamId: UUID!) {
  exportParticipantExamDocument(participantExamId: $participantExamId)
}
    `;
export type ExportParticipantExamDocumentMutationFn = Apollo.MutationFunction<ExportParticipantExamDocumentMutation, ExportParticipantExamDocumentMutationVariables>;

/**
 * __useExportParticipantExamDocumentMutation__
 *
 * To run a mutation, you first call `useExportParticipantExamDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportParticipantExamDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportParticipantExamDocumentMutation, { data, loading, error }] = useExportParticipantExamDocumentMutation({
 *   variables: {
 *      participantExamId: // value for 'participantExamId'
 *   },
 * });
 */
export function useExportParticipantExamDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ExportParticipantExamDocumentMutation, ExportParticipantExamDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportParticipantExamDocumentMutation, ExportParticipantExamDocumentMutationVariables>(ExportParticipantExamDocumentDocument, options);
      }
export type ExportParticipantExamDocumentMutationHookResult = ReturnType<typeof useExportParticipantExamDocumentMutation>;
export type ExportParticipantExamDocumentMutationResult = Apollo.MutationResult<ExportParticipantExamDocumentMutation>;
export type ExportParticipantExamDocumentMutationOptions = Apollo.BaseMutationOptions<ExportParticipantExamDocumentMutation, ExportParticipantExamDocumentMutationVariables>;
export const ExportParticipantExamProtocolDocument = gql`
    mutation exportParticipantExamProtocol($participantExamId: UUID!) {
  exportParticipantExamProtocol(participantExamId: $participantExamId)
}
    `;
export type ExportParticipantExamProtocolMutationFn = Apollo.MutationFunction<ExportParticipantExamProtocolMutation, ExportParticipantExamProtocolMutationVariables>;

/**
 * __useExportParticipantExamProtocolMutation__
 *
 * To run a mutation, you first call `useExportParticipantExamProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportParticipantExamProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportParticipantExamProtocolMutation, { data, loading, error }] = useExportParticipantExamProtocolMutation({
 *   variables: {
 *      participantExamId: // value for 'participantExamId'
 *   },
 * });
 */
export function useExportParticipantExamProtocolMutation(baseOptions?: Apollo.MutationHookOptions<ExportParticipantExamProtocolMutation, ExportParticipantExamProtocolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportParticipantExamProtocolMutation, ExportParticipantExamProtocolMutationVariables>(ExportParticipantExamProtocolDocument, options);
      }
export type ExportParticipantExamProtocolMutationHookResult = ReturnType<typeof useExportParticipantExamProtocolMutation>;
export type ExportParticipantExamProtocolMutationResult = Apollo.MutationResult<ExportParticipantExamProtocolMutation>;
export type ExportParticipantExamProtocolMutationOptions = Apollo.BaseMutationOptions<ExportParticipantExamProtocolMutation, ExportParticipantExamProtocolMutationVariables>;
export const ExportParticipantExamsBlanksDocument = gql`
    mutation exportParticipantExamsBlanks($examId: UUID!) {
  exportParticipantExamsBlanks(examId: $examId)
}
    `;
export type ExportParticipantExamsBlanksMutationFn = Apollo.MutationFunction<ExportParticipantExamsBlanksMutation, ExportParticipantExamsBlanksMutationVariables>;

/**
 * __useExportParticipantExamsBlanksMutation__
 *
 * To run a mutation, you first call `useExportParticipantExamsBlanksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportParticipantExamsBlanksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportParticipantExamsBlanksMutation, { data, loading, error }] = useExportParticipantExamsBlanksMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useExportParticipantExamsBlanksMutation(baseOptions?: Apollo.MutationHookOptions<ExportParticipantExamsBlanksMutation, ExportParticipantExamsBlanksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportParticipantExamsBlanksMutation, ExportParticipantExamsBlanksMutationVariables>(ExportParticipantExamsBlanksDocument, options);
      }
export type ExportParticipantExamsBlanksMutationHookResult = ReturnType<typeof useExportParticipantExamsBlanksMutation>;
export type ExportParticipantExamsBlanksMutationResult = Apollo.MutationResult<ExportParticipantExamsBlanksMutation>;
export type ExportParticipantExamsBlanksMutationOptions = Apollo.BaseMutationOptions<ExportParticipantExamsBlanksMutation, ExportParticipantExamsBlanksMutationVariables>;
export const ExportParticipantExamsDocumentsDocument = gql`
    mutation exportParticipantExamsDocuments($examId: UUID!) {
  exportParticipantExamsDocuments(examId: $examId)
}
    `;
export type ExportParticipantExamsDocumentsMutationFn = Apollo.MutationFunction<ExportParticipantExamsDocumentsMutation, ExportParticipantExamsDocumentsMutationVariables>;

/**
 * __useExportParticipantExamsDocumentsMutation__
 *
 * To run a mutation, you first call `useExportParticipantExamsDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportParticipantExamsDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportParticipantExamsDocumentsMutation, { data, loading, error }] = useExportParticipantExamsDocumentsMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useExportParticipantExamsDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<ExportParticipantExamsDocumentsMutation, ExportParticipantExamsDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportParticipantExamsDocumentsMutation, ExportParticipantExamsDocumentsMutationVariables>(ExportParticipantExamsDocumentsDocument, options);
      }
export type ExportParticipantExamsDocumentsMutationHookResult = ReturnType<typeof useExportParticipantExamsDocumentsMutation>;
export type ExportParticipantExamsDocumentsMutationResult = Apollo.MutationResult<ExportParticipantExamsDocumentsMutation>;
export type ExportParticipantExamsDocumentsMutationOptions = Apollo.BaseMutationOptions<ExportParticipantExamsDocumentsMutation, ExportParticipantExamsDocumentsMutationVariables>;
export const ExportParticipantExamsProtocolsDocument = gql`
    mutation exportParticipantExamsProtocols($examId: UUID!) {
  exportParticipantExamsProtocols(examId: $examId)
}
    `;
export type ExportParticipantExamsProtocolsMutationFn = Apollo.MutationFunction<ExportParticipantExamsProtocolsMutation, ExportParticipantExamsProtocolsMutationVariables>;

/**
 * __useExportParticipantExamsProtocolsMutation__
 *
 * To run a mutation, you first call `useExportParticipantExamsProtocolsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportParticipantExamsProtocolsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportParticipantExamsProtocolsMutation, { data, loading, error }] = useExportParticipantExamsProtocolsMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useExportParticipantExamsProtocolsMutation(baseOptions?: Apollo.MutationHookOptions<ExportParticipantExamsProtocolsMutation, ExportParticipantExamsProtocolsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportParticipantExamsProtocolsMutation, ExportParticipantExamsProtocolsMutationVariables>(ExportParticipantExamsProtocolsDocument, options);
      }
export type ExportParticipantExamsProtocolsMutationHookResult = ReturnType<typeof useExportParticipantExamsProtocolsMutation>;
export type ExportParticipantExamsProtocolsMutationResult = Apollo.MutationResult<ExportParticipantExamsProtocolsMutation>;
export type ExportParticipantExamsProtocolsMutationOptions = Apollo.BaseMutationOptions<ExportParticipantExamsProtocolsMutation, ExportParticipantExamsProtocolsMutationVariables>;
export const ParticipantExamDocument = gql`
    query participantExam($where: ParticipantExamFilterInput) {
  participantExam(where: $where) {
    id
    isDeleted
    createdAt
    updatedAt
    result
    decision
    status
    videoLink
    participant {
      id
      uin
      sex
      birthdayDate
      category {
        id
        name
      }
    }
    examPaperQuestions {
      id
      order
      question {
        id
        title
        content
        ... on TopicQuestion {
          type
        }
        answers {
          id
          order
          text
          isCorrect
        }
      }
    }
  }
}
    `;

/**
 * __useParticipantExamQuery__
 *
 * To run a query within a React component, call `useParticipantExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useParticipantExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParticipantExamQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useParticipantExamQuery(baseOptions?: Apollo.QueryHookOptions<ParticipantExamQuery, ParticipantExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParticipantExamQuery, ParticipantExamQueryVariables>(ParticipantExamDocument, options);
      }
export function useParticipantExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParticipantExamQuery, ParticipantExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParticipantExamQuery, ParticipantExamQueryVariables>(ParticipantExamDocument, options);
        }
export type ParticipantExamQueryHookResult = ReturnType<typeof useParticipantExamQuery>;
export type ParticipantExamLazyQueryHookResult = ReturnType<typeof useParticipantExamLazyQuery>;
export type ParticipantExamQueryResult = Apollo.QueryResult<ParticipantExamQuery, ParticipantExamQueryVariables>;
export const PermissionDocument = gql`
    query permission($where: PermissionFilterInput) {
  permission(where: $where) {
    name
    displayName
    description
    roles {
      id
      displayName
    }
    id
    createdAt
    updatedAt
  }
}
    `;

/**
 * __usePermissionQuery__
 *
 * To run a query within a React component, call `usePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePermissionQuery(baseOptions?: Apollo.QueryHookOptions<PermissionQuery, PermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionQuery, PermissionQueryVariables>(PermissionDocument, options);
      }
export function usePermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionQuery, PermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionQuery, PermissionQueryVariables>(PermissionDocument, options);
        }
export type PermissionQueryHookResult = ReturnType<typeof usePermissionQuery>;
export type PermissionLazyQueryHookResult = ReturnType<typeof usePermissionLazyQuery>;
export type PermissionQueryResult = Apollo.QueryResult<PermissionQuery, PermissionQueryVariables>;
export const PermissionsDocument = gql`
    query permissions($where: PermissionFilterInput) {
  permissions(where: $where, skip: 0, take: 100) {
    items {
      name
      displayName
      description
      roles {
        id
        displayName
      }
      id
      createdAt
      updatedAt
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    `;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, options);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($model: RoleModelInput!) {
  createRole(model: $model) {
    displayName
    permissions {
      id
      name
      description
      displayName
      createdAt
      updatedAt
    }
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const RoleDocument = gql`
    query role($where: RoleFilterInput) {
  role(where: $where) {
    displayName
    permissions {
      id
      name
      description
      displayName
      createdAt
      updatedAt
    }
    users {
      id
      fullName
      roleId
      updatedAt
      createdAt
    }
    id
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useRoleQuery__
 *
 * To run a query within a React component, call `useRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRoleQuery(baseOptions?: Apollo.QueryHookOptions<RoleQuery, RoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
      }
export function useRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleQuery, RoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleQuery, RoleQueryVariables>(RoleDocument, options);
        }
export type RoleQueryHookResult = ReturnType<typeof useRoleQuery>;
export type RoleLazyQueryHookResult = ReturnType<typeof useRoleLazyQuery>;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const RolesDocument = gql`
    query roles($where: RoleFilterInput, $skip: Int, $take: Int) {
  roles(where: $where, skip: $skip, take: $take) {
    items {
      id
      displayName
      permissions {
        id
        name
        description
        displayName
        createdAt
        updatedAt
      }
      users {
        id
        fullName
        roleId
        updatedAt
        createdAt
      }
      createdAt
      updatedAt
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($model: RoleModelInput!) {
  updateRole(model: $model) {
    displayName
    permissions {
      id
      name
      description
      displayName
      createdAt
      updatedAt
    }
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      model: // value for 'model'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const SignatoryDocument = gql`
    query signatory {
  signatory {
    certificationBodyFullName
    certificationBodyShortName
    employee
    id
    pfoStatementHeader
    position
  }
}
    `;

/**
 * __useSignatoryQuery__
 *
 * To run a query within a React component, call `useSignatoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignatoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignatoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSignatoryQuery(baseOptions?: Apollo.QueryHookOptions<SignatoryQuery, SignatoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignatoryQuery, SignatoryQueryVariables>(SignatoryDocument, options);
      }
export function useSignatoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignatoryQuery, SignatoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignatoryQuery, SignatoryQueryVariables>(SignatoryDocument, options);
        }
export type SignatoryQueryHookResult = ReturnType<typeof useSignatoryQuery>;
export type SignatoryLazyQueryHookResult = ReturnType<typeof useSignatoryLazyQuery>;
export type SignatoryQueryResult = Apollo.QueryResult<SignatoryQuery, SignatoryQueryVariables>;
export const UpdateSignatoryDocument = gql`
    mutation updateSignatory($signatoryModel: SignatoryModelInput!) {
  updateSignatory(signatoryModel: $signatoryModel) {
    certificationBodyFullName
    certificationBodyShortName
    employee
    id
    pfoStatementHeader
    position
  }
}
    `;
export type UpdateSignatoryMutationFn = Apollo.MutationFunction<UpdateSignatoryMutation, UpdateSignatoryMutationVariables>;

/**
 * __useUpdateSignatoryMutation__
 *
 * To run a mutation, you first call `useUpdateSignatoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignatoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignatoryMutation, { data, loading, error }] = useUpdateSignatoryMutation({
 *   variables: {
 *      signatoryModel: // value for 'signatoryModel'
 *   },
 * });
 */
export function useUpdateSignatoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignatoryMutation, UpdateSignatoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignatoryMutation, UpdateSignatoryMutationVariables>(UpdateSignatoryDocument, options);
      }
export type UpdateSignatoryMutationHookResult = ReturnType<typeof useUpdateSignatoryMutation>;
export type UpdateSignatoryMutationResult = Apollo.MutationResult<UpdateSignatoryMutation>;
export type UpdateSignatoryMutationOptions = Apollo.BaseMutationOptions<UpdateSignatoryMutation, UpdateSignatoryMutationVariables>;
export const CreateOrUpdateTopicDocument = gql`
    mutation createOrUpdateTopic($topicModel: TopicModelInput!) {
  createOrUpdateTopic(topicModel: $topicModel) {
    id
    name
    createdAt
    updatedAt
    testType
    type
    questions {
      id
      content
      title
      answers {
        id
        isCorrect
        text
        updatedAt
        createdAt
      }
      ... on TopicQuestion {
        type
      }
    }
    categories {
      id
      name
      description
    }
  }
}
    `;
export type CreateOrUpdateTopicMutationFn = Apollo.MutationFunction<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>;

/**
 * __useCreateOrUpdateTopicMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateTopicMutation, { data, loading, error }] = useCreateOrUpdateTopicMutation({
 *   variables: {
 *      topicModel: // value for 'topicModel'
 *   },
 * });
 */
export function useCreateOrUpdateTopicMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>(CreateOrUpdateTopicDocument, options);
      }
export type CreateOrUpdateTopicMutationHookResult = ReturnType<typeof useCreateOrUpdateTopicMutation>;
export type CreateOrUpdateTopicMutationResult = Apollo.MutationResult<CreateOrUpdateTopicMutation>;
export type CreateOrUpdateTopicMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateTopicMutation, CreateOrUpdateTopicMutationVariables>;
export const TopicDocument = gql`
    query topic($where: TopicFilterInput) {
  topic(where: $where) {
    id
    name
    createdAt
    updatedAt
    questions {
      ... on TopicQuestion {
        id
        content
        title
        type
        answers(order: {order: ASC}) {
          id
          isCorrect
          text
          updatedAt
          createdAt
        }
      }
    }
    categories {
      id
      name
      description
    }
  }
}
    `;

/**
 * __useTopicQuery__
 *
 * To run a query within a React component, call `useTopicQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTopicQuery(baseOptions?: Apollo.QueryHookOptions<TopicQuery, TopicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
      }
export function useTopicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicQuery, TopicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicQuery, TopicQueryVariables>(TopicDocument, options);
        }
export type TopicQueryHookResult = ReturnType<typeof useTopicQuery>;
export type TopicLazyQueryHookResult = ReturnType<typeof useTopicLazyQuery>;
export type TopicQueryResult = Apollo.QueryResult<TopicQuery, TopicQueryVariables>;
export const TopicsDocument = gql`
    query topics($where: TopicFilterInput, $order: [TopicSortInput!], $skip: Int, $take: Int) {
  topics(where: $where, order: $order, skip: $skip, take: $take) {
    items {
      ...TopicsItem
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${TopicsItemFragmentDoc}`;

/**
 * __useTopicsQuery__
 *
 * To run a query within a React component, call `useTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopicsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useTopicsQuery(baseOptions?: Apollo.QueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
      }
export function useTopicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopicsQuery, TopicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopicsQuery, TopicsQueryVariables>(TopicsDocument, options);
        }
export type TopicsQueryHookResult = ReturnType<typeof useTopicsQuery>;
export type TopicsLazyQueryHookResult = ReturnType<typeof useTopicsLazyQuery>;
export type TopicsQueryResult = Apollo.QueryResult<TopicsQuery, TopicsQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($userModel: UserModelInput!) {
  createUser(userModel: $userModel) {
    firstName
    lastName
    middleName
    email
    password
    isActive
    roleId
    role {
      id
      displayName
    }
    fullName
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userModel: // value for 'userModel'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: UUID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($userModel: UserModelInput!) {
  updateUser(userModel: $userModel) {
    firstName
    lastName
    middleName
    email
    password
    isActive
    roleId
    role {
      id
      displayName
    }
    fullName
    id
    createdAt
    updatedAt
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userModel: // value for 'userModel'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserDocument = gql`
    query user($where: UserFilterInput) {
  user(where: $where) {
    firstName
    lastName
    middleName
    email
    password
    isActive
    roleId
    role {
      id
      displayName
    }
    fullName
    id
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($where: UserFilterInput, $order: [UserSortInput!], $skip: Int, $take: Int) {
  users(where: $where, order: $order, skip: $skip, take: $take) {
    items {
      ...User
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order: // value for 'order'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;