import {Card, Container, Grid, Stack, Typography} from '@mui/material';
import React from 'react';
import {Form, FormAutocomplete, FormTextField} from 'components/form';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router';
import CircularLoading from 'components/CircularLoading';
import {LoadingButton} from '@mui/lab';
import {yupResolver} from '@hookform/resolvers/yup';
import {InferType, lazy, object, string} from 'yup';
import {NotificationType, useNotify} from 'hooks';
import {
    CreateUserMutation,
    PermissionName,
    useCreateUserMutation,
    useRolesQuery,
    useUpdateUserMutation,
    useUserQuery
} from '@generated/graphql';
import {ROUTES} from 'constants/routes';
import {useAuthContext} from 'context/authContext';
import {ApolloError} from '@apollo/client';

const getSchema = (userId?: string) =>
    object({
        firstName: string().required(' '),
        lastName: string().required(' '),
        middleName: string().default(''),
        roleId: string().required(' '),
        email: string().required(' ').email('Введите корректный E-mail'),
        password: lazy((value: string) => {
            if (!userId || value.length) {
                return string()
                    .required(' ')
                    .min(8, 'Пароль должен содержать не менее 8 символов')
                    .test(
                        'complexity',
                        'Должны использоваться символы по крайней мере трех правил из следующих:\n' +
                            'заглавные латинские буквы;\n' +
                            'строчные латинские буквы;\n' +
                            'цифры;\n' +
                            'специальные знаки (такие как ! * ( ) : | _).',
                        (value) => checkComplexity(value!)
                    );
            } else {
                return string().default('');
            }
        })
    });
const checkComplexity = (value: string) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumbers = /\d/.test(value);
    const hasNonalphas = /\W/.test(value);
    return [hasUpperCase, hasLowerCase, hasNumbers, hasNonalphas].filter(Boolean).length >= 3;
};

export const User = () => {
    const {userId} = useParams();
    const {data, loading, error} = useUserQuery({variables: {where: {id: {eq: userId}}}, skip: !userId});
    const user = data?.user;
    const schema = getSchema(userId);

    const {data: roleOptions} = useRolesQuery();
    const [createUser, {loading: createLoading}] = useCreateUserMutation();
    const [updateUser, {loading: updateLoading}] = useUpdateUserMutation();
    const navigate = useNavigate();
    const notify = useNotify();
    const {hasPermission} = useAuthContext();

    const form = useForm({
        values: user ? schema.cast({...user, password: '', roleId: user.role?.id}, {stripUnknown: true}) : undefined,
        resolver: yupResolver(schema)
    });

    const {handleSubmit} = form;

    const onSubmit = (data: InferType<typeof schema>) => {
        const func = userId ? updateUser : createUser;
        func({
            variables: {
                userModel: {
                    ...data,
                    id: userId
                }
            }
        })
            .then((res) => {
                !userId && navigate(ROUTES.USERS + '/' + (res.data as CreateUserMutation).createUser.id);
                notify({type: NotificationType.SUCCESS});
            })
            .catch((error: ApolloError) => {
                const message = error.graphQLErrors?.[0]?.extensions?.message as string;
                if (message?.includes('already exists')) {
                    notify({type: NotificationType.ERROR, text: 'Пользователь с таким E-mail адресом уже существует'});
                } else {
                    notify({type: NotificationType.ERROR});
                }
            });
    };

    return (
        <CircularLoading loading={!!userId && (loading || !!error)}>
            <Container>
                <Stack direction={'row'} mb={2} justifyContent={'space-between'}>
                    <Typography variant={'h2'}>Пользователь</Typography>
                    {hasPermission(PermissionName.EditUserRecords) && (
                        <LoadingButton
                            loading={userId ? updateLoading : createLoading}
                            variant={'contained'}
                            onClick={handleSubmit(onSubmit)}>
                            Сохранить
                        </LoadingButton>
                    )}
                </Stack>
                <Form form={form} autoComplete={'off'}>
                    <Card>
                        <Grid container columnSpacing={5} rowSpacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <FormTextField label={'Фамилия'} name={'lastName'} />
                                    <FormTextField label={'Имя'} name={'firstName'} />
                                    <FormTextField label={'Отчество'} name={'middleName'} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={2}>
                                    <FormAutocomplete
                                        label={'Роль'}
                                        name={'roleId'}
                                        options={roleOptions?.roles?.items || []}
                                    />
                                    <FormTextField label={'E-mail'} name={'email'} />
                                    <FormTextField
                                        sx={{
                                            '.MuiFormHelperText-root': {
                                                position: 'relative',
                                                bottom: 0
                                            }
                                        }}
                                        label={'Новый пароль'}
                                        name={'password'}
                                        type={'password'}
                                        autoComplete={'new-password'}
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Card>
                </Form>
            </Container>
        </CircularLoading>
    );
};
