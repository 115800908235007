import {useEffect, useState} from 'react';
import {Box, Button, Grid, InputLabel, List, ListItem, ListItemText, Paper} from '@mui/material';
import {Permission} from '@generated/graphql';

function not(a: Permission[], b: Permission[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: Permission[], b: Permission[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export type TransferListProps = {
    leftList: Permission[];
    rightList: Permission[];
    setChosenPermissions: (list: Permission[]) => void;
};
const TransferList = ({leftList, rightList, setChosenPermissions}: TransferListProps) => {
    const [selected, setSelected] = useState<Permission[]>([]);
    const [left, setLeft] = useState<Permission[]>(leftList);
    const [right, setRight] = useState<Permission[]>(
        rightList.filter((x) => !leftList.map((i) => i.id).includes(x.id))
    );

    useEffect(() => {
        setChosenPermissions(left);
    }, [left]);

    const leftSelected = intersection(selected, left);
    const rightSelected = intersection(selected, right);

    const handleToggle = (value: Permission) => () => {
        const currentIndex = selected.indexOf(value);
        const newSelected = [...selected];

        if (currentIndex === -1) {
            newSelected.push(value);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        setSelected(newSelected);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleSelectedRight = () => {
        setRight(right.concat(leftSelected));
        setLeft(not(left, leftSelected));
        setSelected(not(selected, leftSelected));
    };

    const handleSelectedLeft = () => {
        setLeft(left.concat(rightSelected));
        setRight(not(right, rightSelected));
        setSelected(not(selected, rightSelected));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items: Permission[], title: string) => (
        <>
            <InputLabel sx={{mb: 0.5}}>{title}</InputLabel>
            <Paper variant={'outlined'} sx={{width: 400, height: 250, overflow: 'auto'}}>
                <List dense component='div' role='list' key={leftList.length}>
                    {items.map((item) => (
                        <ListItem
                            key={item.id}
                            role='listitem'
                            button
                            onClick={handleToggle(item)}
                            selected={selected.indexOf(item) !== -1}>
                            <ListItemText id={`transfer-list-item-${item}-label`} primary={item.displayName} />
                        </ListItem>
                    ))}
                </List>
            </Paper>
        </>
    );

    return (
        <Box>
            <Grid container columnSpacing={2} alignItems='center'>
                <Grid item>{customList(left, 'Выбранные права')}</Grid>
                <Grid item>
                    <Grid container direction='column' alignItems='center'>
                        <Button
                            sx={{my: 0.5}}
                            variant='outlined'
                            size='small'
                            onClick={handleAllRight}
                            disabled={left.length === 0}
                            aria-label='move all right'>
                            &gt;&gt;
                        </Button>
                        <Button
                            sx={{my: 0.5}}
                            variant='outlined'
                            size='small'
                            onClick={handleSelectedRight}
                            disabled={leftSelected.length === 0}
                            aria-label='move selected right'>
                            &gt;
                        </Button>
                        <Button
                            sx={{my: 0.5}}
                            variant='outlined'
                            size='small'
                            onClick={handleSelectedLeft}
                            disabled={rightSelected.length === 0}
                            aria-label='move selected left'>
                            &lt;
                        </Button>
                        <Button
                            sx={{my: 0.5}}
                            variant='outlined'
                            size='small'
                            onClick={handleAllLeft}
                            disabled={right.length === 0}
                            aria-label='move all left'>
                            &lt;&lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList(right, 'Доступные права')}</Grid>
            </Grid>
        </Box>
    );
};
export default TransferList;
