import {FieldValues, FormProvider, UseFormReturn} from 'react-hook-form';
import {FormHTMLAttributes, ReactNode} from 'react';

export type FormProps<T extends FieldValues> = {
    form: UseFormReturn<T>;
    children?: ReactNode;
};

export const Form = <T extends FieldValues>(props: FormHTMLAttributes<HTMLFormElement> & FormProps<T>) => {
    const {form, children, onSubmit, ...rest} = props;
    return (
        <FormProvider {...form}>
            <form
                noValidate
                onSubmit={(e) => {
                    onSubmit && onSubmit(e);
                    e.preventDefault();
                }}
                {...rest}>
                {children}
            </form>
        </FormProvider>
    );
};
