import {Chip, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import {Edit as EditIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {PermissionName, TopicsItemFragment, useCategoriesQuery} from '@generated/graphql';
import React from 'react';
import {FILTER_TYPES, INPUT_TYPES} from 'components/generators';
import {useAuthContext} from 'context/authContext';

export const tableConfig = (): Column<TopicsItemFragment>[] => {
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();
    return [
        {
            header: 'Название темы',
            name: 'name',
            render: (topic) => (
                <Tooltip title={topic?.name}>
                    <Typography maxWidth={300} noWrap variant={'body2'}>
                        {topic?.name}
                    </Typography>
                </Tooltip>
            )
        },
        {
            header: 'Категории',
            render: (topic) => (
                <Stack direction={'row'} gap={2} alignItems={'center'} flexWrap={'wrap'}>
                    {topic?.categories.map((category) => (
                        <Chip label={category.name} key={category?.id} />
                    ))}
                </Stack>
            )
        },
        {
            header: 'Действие',
            hide: !hasPermission(PermissionName.EditTopics),
            render: (topic) => (
                <Tooltip title={'Редактировать'}>
                    <IconButton
                        color={'primary'}
                        onClick={() => {
                            navigate(topic?.id);
                        }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            )
        }
    ];
};

export const filterFields = () => {
    const {data: dataCategories} = useCategoriesQuery();
    const categories = dataCategories?.categories?.items || [];
    return [
        {
            name: 'name',
            label: 'Название темы',
            inputType: INPUT_TYPES.TEXT,
            filterType: FILTER_TYPES.STRING_FILTER
        },
        {
            name: 'categories.some.id',
            label: 'Категория',
            inputType: INPUT_TYPES.AUTOCOMPLETE,
            options: categories.map((category) => ({
                id: category.id,
                displayName: category.name
            })),
            filterType: FILTER_TYPES.ENUM_FILTER
        }
    ];
};
