import {Button, Container, Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import TableLayout from 'components/table/TableLayout';
import {filterFields, tableConfig} from './config';
import {ParticipantsItemFragment, PermissionName, useParticipantsQuery} from '@generated/graphql';
import ParticipantModal from 'pages/Participant/list/ParticipantModal';
import {useAuthContext} from 'context/authContext';

export const Participants = () => {
    const [participant, setParticipant] = useState<ParticipantsItemFragment | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const filters = filterFields();
    const {data, loading, error, refetch} = useParticipantsQuery();
    const {hasPermission} = useAuthContext();
    const handleOpen = (participant: ParticipantsItemFragment | null) => {
        setOpen(true);
        setParticipant(participant);
    };
    const handleClose = () => {
        setOpen(false);
        setParticipant(null);
        refetch();
    };

    return (
        <Container>
            <Stack direction={{md: 'row'}} alignItems={{md: 'center'}} justifyContent={'space-between'} mb={5}>
                <Typography variant={'h2'}>Список аттестуемых</Typography>
                {hasPermission(PermissionName.EditParticipants) && (
                    <Button variant={'contained'} onClick={() => handleOpen(null)}>
                        Добавить аттестуемого
                    </Button>
                )}
            </Stack>
            <TableLayout
                loading={loading || !!error}
                data={data?.participants}
                columns={tableConfig(handleOpen)}
                filters={filters}
                refetch={refetch}
            />
            {open && <ParticipantModal participant={participant} open={open} handleClose={handleClose} />}
        </Container>
    );
};
