import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {useNavigate} from 'react-router';
import {Visibility as VisibilityIcon} from '@mui/icons-material';
import {Column} from 'components/table/TableLayout';
import {CategoriesItemFragment} from '@generated/graphql';

export const tableConfig = (): Column<CategoriesItemFragment>[] => {
    const navigate = useNavigate();
    return [
        {
            header: 'Категория',
            render: (category) => category?.name
        },
        {
            header: 'Название',
            render: (category) => (
                <Tooltip title={category?.description}>
                    <Typography maxWidth={800} noWrap variant={'body2'}>
                        {category?.description}
                    </Typography>
                </Tooltip>
            )
        },
        {
            header: 'Действие',
            render: (category) => (
                <Stack direction={'row'} spacing={1}>
                    <Tooltip title={'Перейти к темам вопросов категории'}>
                        <IconButton
                            color={'primary'}
                            onClick={() => {
                                navigate(category?.id);
                            }}>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )
        }
    ];
};
