import {Box, Card, Container, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router';
import {CircularLoading} from 'components';
import {ExamType, PermissionName, Topic, useCategoryQuery} from '@generated/graphql';
import {Edit as EditIcon, InfoOutlined as InfoIcon} from '@mui/icons-material';
import {formatNumber} from 'helpers';
import {ROUTES} from 'constants/routes';
import {useAuthContext} from 'context/authContext';

const TopicComponent = ({topic}: {topic: Topic}) => {
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();

    return (
        <Card sx={{width: '100%'}}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                <Typography>{topic.name}</Typography>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                    <Typography>{formatNumber(topic.questions.length, ['вопрос', 'вопроса', 'вопросов'])}</Typography>
                    {hasPermission(PermissionName.EditTopics) && (
                        <Tooltip title={'Перейти к изменению вопросов темы'}>
                            <IconButton
                                color={'primary'}
                                onClick={() => {
                                    navigate(ROUTES.ZUN_TOPICS + '/' + topic?.id);
                                }}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            </Stack>
        </Card>
    );
};

export const Category = () => {
    const {categoryId} = useParams();
    const {data, loading, error} = useCategoryQuery({variables: {where: {id: {eq: categoryId}}}});
    const category = data?.category;
    const psy = data?.category?.topics.filter((i) => i.type === ExamType.Psychology) || [];
    const zun = data?.category?.topics.filter((i) => i.type === ExamType.KnowledgeSkillTest) || [];

    return (
        <CircularLoading loading={!!categoryId && (loading || !!error)}>
            <Container>
                <Stack direction={'row'} spacing={1} mb={2}>
                    <Typography variant={'h2'}>{category?.name}</Typography>
                    <Tooltip title={category?.description}>
                        <IconButton color={'primary'}>
                            <InfoIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
                <Card sx={{mb: 2}}>
                    <Box mb={2}>
                        <Typography variant={'h3'}>Темы вопросов проверки знаний, умений и навыков</Typography>
                    </Box>
                    <Stack spacing={2}>
                        {zun.length
                            ? zun?.map((topic) => <TopicComponent topic={topic as Topic} key={topic.id} />)
                            : 'Нет тем'}
                    </Stack>
                </Card>
                <Card>
                    <Box mb={2}>
                        <Typography variant={'h3'}>Проверки психофизиологических качеств</Typography>
                    </Box>
                    <Stack spacing={2}>
                        {psy.length
                            ? psy?.map((topic) => (
                                  <Card sx={{width: '100%'}} key={topic.id}>
                                      <Typography>{topic.name}</Typography>
                                  </Card>
                              ))
                            : 'Нет проверок'}
                    </Stack>
                </Card>
            </Container>
        </CircularLoading>
    );
};
