import {
    Box,
    ClickAwayListener,
    InputAdornment,
    InputLabel,
    Paper,
    Popper,
    Stack,
    styled,
    TextField
} from '@mui/material';
import TextMaskCustom from './TextMaskCustom';
import {CalendarMonth} from '@mui/icons-material';
import React, {useEffect, useRef, useState} from 'react';
import {useController, UseControllerProps, useFormContext} from 'react-hook-form';
import {DayPicker} from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/dist/style.css';
import ru from 'date-fns/locale/ru';

export const FormDatePicker = (
    props: UseControllerProps & {
        label?: string;
        placeholder?: string;
    }
) => {
    const {name, rules, placeholder, label} = props;
    const {
        field,
        fieldState: {error}
    } = useController({name, rules, defaultValue: null});
    const {clearErrors} = useFormContext();
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen((prevState) => !prevState);

    const [value, setValue] = useState('');
    const inputRef = useRef();

    useEffect(() => {
        setValue(moment(field.value).isValid() ? moment(field.value).format('DD.MM.YYYY') : '');
    }, [field.value]);

    useEffect(() => {
        clearErrors(name);
        if (!value) {
            field.onChange(null);
        }
    }, [value]);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Box>
                <InputLabel error={!!error}>{label}</InputLabel>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    <TextField
                        inputRef={inputRef}
                        error={!!error}
                        helperText={error?.message}
                        value={value}
                        onClick={handleClose}
                        onChange={(e) => setValue(e.target.value)}
                        placeholder={placeholder || label}
                        sx={{width: '100%'}}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                            inputProps: {
                                mask: Date,
                                onComplete: (val: string) => field.onChange(moment(val, 'DD.MM.YYYY').toDate())
                            },
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <CalendarMonth />
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
                <StylePopover
                    id={open ? 'simple-popover' : undefined}
                    open={open}
                    placement={'bottom-end'}
                    anchorEl={inputRef.current}>
                    <Paper sx={{p: 1}}>
                        <DayPicker
                            key={field.value}
                            locale={ru}
                            captionLayout={'dropdown-buttons'}
                            fromYear={1900}
                            toYear={2025}
                            mode={'single'}
                            selected={field.value}
                            onSelect={(day) => {
                                field.onChange(day);
                                handleClose();
                            }}
                            defaultMonth={field.value}
                        />
                    </Paper>
                </StylePopover>
            </Box>
        </ClickAwayListener>
    );
};

const StylePopover = styled(Popper)(({theme}) => ({
    zIndex: 1300,
    '& .rdp': {
        '--rdp-cell-size': '30px',
        '--rdp-accent-color': theme.palette.primary.main,
        fontSize: 14,
        '.rdp-day': {
            borderRadius: '5px !important',
            margin: 1
        }
    }
}));
