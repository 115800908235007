import {Box, Button, darken, Stack} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {getColor, Question} from './Question';
import {ParticipantExamStatus, QuestionType} from '@generated/graphql';
import moment from 'moment';
import {formatTime} from 'helpers';
import {TextRow} from '../components/TextRow';
import {ParticipantExamStatusMap} from 'constants/index';
import {useParticipantExamContext} from '../index';

export type ZUNBlockResult = {
    FirstBlockStartTime: string;
    FirstBlockFinishTime: string;
    SecondBlockStartTime: string;
    SecondBlockFinishTime: string;
    Decision: ParticipantExamStatus.Approved | ParticipantExamStatus.NotApproved;
    Result: {
        Score: number;
        MaxScore: number;
        ScoreByType: {[K in QuestionType]: number};
        UserAnswers: [
            {
                QuestionId: string;
                AnswersIds: string[];
                IsCorrect: boolean;
            }
        ];
    };
};

export const ZUNResults = () => {
    const {participantExam} = useParticipantExamContext();
    const [questionIndex, setQuestionIndex] = useState(0);

    const data = (JSON.parse(participantExam?.result || '{}').Data as ZUNBlockResult) || {};
    const result = data?.Result;
    const questions = useMemo(() => {
        return (
            participantExam?.examPaperQuestions.map((i) => {
                const userAnswer = result?.UserAnswers?.find((answer) => answer.QuestionId === i.question?.id);
                return {...i.question, isCorrect: userAnswer?.IsCorrect, userAnswersIds: userAnswer?.AnswersIds};
            }) || []
        );
    }, []);

    return (
        <Box>
            <TextRow
                text={'Соответствие требованиям законодательства'}
                value={ParticipantExamStatusMap[data?.Decision]?.text}
            />
            <TextRow text={'Общее количество баллов'} value={result?.Score + ' / ' + result?.MaxScore} />
            <TextRow
                text={'Количество баллов за тестовые вопросы'}
                value={result.ScoreByType[QuestionType.TestQuestion]}
            />
            <TextRow
                text={'Количество баллов за тематические вопросы'}
                value={result.ScoreByType[QuestionType.ThematicQuestion]}
            />
            <TextRow
                text={'Количество баллов за практические задачи'}
                value={result.ScoreByType[QuestionType.AppliedTask]}
            />

            <TextRow
                text={'Время прохождения тестовых вопросов'}
                value={formatTime(
                    moment.duration(moment(data.FirstBlockFinishTime).diff(moment(data.FirstBlockStartTime)))
                )}
            />
            <TextRow
                text={'Время прохождения тематических вопросов и практических задач'}
                value={formatTime(
                    moment.duration(moment(data.SecondBlockFinishTime).diff(moment(data.SecondBlockStartTime)))
                )}
            />
            <Stack direction={'row'} gap={1} mt={2} mb={3} flexWrap={'wrap'}>
                {questions.map((item, index) => (
                    <Button
                        key={index}
                        variant={'contained'}
                        onClick={() => setQuestionIndex(index)}
                        sx={{
                            backgroundColor: getColor(item, questionIndex === index),
                            '&:hover': {
                                backgroundColor: darken(getColor(item, questionIndex === index), 0.2)
                            },
                            padding: 0,
                            width: 40,
                            height: 40,
                            minWidth: 'auto',
                            flexShrink: 0
                        }}>
                        {index + 1}
                    </Button>
                ))}
            </Stack>
            {!!questions.length && <Question questions={questions} questionIndex={questionIndex} />}
        </Box>
    );
};
