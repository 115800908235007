import {Card, Container, Grid, Stack, Typography} from '@mui/material';
import React, {createContext, useContext} from 'react';
import {LoadingButton} from '@mui/lab';
import {useNavigate, useParams} from 'react-router';
import {
    ExamQuery,
    ExamType,
    PermissionName,
    useCreateOrUpdateExamMutation,
    useExamQuery,
    useExportExamMutation,
    useExportParticipantExamsDocumentsMutation
} from '@generated/graphql';
import {Form, FormAutocomplete, FormDatePicker, FormTextField} from 'components/form';
import {ExamStatusMap, ExamTypeMap, ExamTypeShortMap} from 'constants/index';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {date, InferType, object, ref, string} from 'yup';
import Participants from './components/Participants';
import {NotificationType, useModal, useNotify} from 'hooks';
import CircularLoading from 'components/CircularLoading';
import {getEnumArray} from 'helpers';
import {saveAs} from 'file-saver';
import {UploadResultsModal} from './components/UploadResultsModal';
import {Label} from 'components/Label';
import {ROUTES} from 'constants/routes';
import {useAuthContext} from 'context';
import moment from "moment";

type ExamContextType = {
    exam?: ExamQuery['exam'] | null;
    refetch: () => Promise<any>;
};

export const ExamContext = createContext({} as ExamContextType);

export const useExamContext = () => useContext(ExamContext);

const schema = object({
    title: string().required(' '),
    number: string().required(' '),
    type: string<ExamType>().required(' '),
    place: string().required(' '),
    plannedStartDate: date().required(' '),
    plannedEndDate: date().required(' ').min(ref('plannedStartDate'), 'Дата окончания должна быть больше даты начала')
});

export const Exam = () => {
    const {examId} = useParams();
    const notify = useNotify();
    const navigate = useNavigate();
    const {hasPermission} = useAuthContext();
    const {open: openUploadResultsModal, handleToggle: handleToggleUploadResultsModal} = useModal();

    const [createOrUpdateExam, {loading: loadingCreateOrUpdateExam}] = useCreateOrUpdateExamMutation();
    const [exportDocuments, {loading: loadingExportParticipantExamsDocuments}] =
        useExportParticipantExamsDocumentsMutation();
    const [exportExam, {loading: exportExamLoading}] = useExportExamMutation();
    const {data, loading, error, refetch} = useExamQuery({
        variables: {where: {id: {eq: examId}}},
        skip: !examId
    });

    const exam = data?.exam;

    const form = useForm({
        values: exam ? schema.cast(exam, {stripUnknown: true}) : undefined,
        resolver: yupResolver(schema)
    });
    const {handleSubmit} = form;

    const handleExportExam = () => {
        exportExam({variables: {examId}})
            .then((res) => {
                res.data?.exportExam &&
                    saveAs(
                        res.data?.exportExam,
                        `Аттестация_${ExamTypeShortMap[exam?.type as ExamType]}_${exam?.number}.zip`
                    );
                notify({type: NotificationType.SUCCESS, text: 'Сведения успешно выгружены'});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };

    const handleExportDocuments = () => {
        exportDocuments({variables: {examId}})
            .then((res) => {
                saveAs(
                    res.data?.exportParticipantExamsDocuments as string,
                    `Решения_${ExamTypeShortMap[exam?.type as ExamType]}_${exam?.number}.zip`
                );
                notify({type: NotificationType.SUCCESS, text: 'Решения успешно выгружены'});
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };

    const onSubmit = (data: InferType<typeof schema>) => {
        createOrUpdateExam({
            variables: {
                examModel: {
                    ...data,
                    plannedStartDate: moment(exam?.plannedStartDate).format('YYYY-MM-DD'),
                    plannedEndDate: moment(exam?.plannedEndDate).format('YYYY-MM-DD'),
                    id: exam?.id
                }
            }
        })
            .then((res) => {
                notify({type: NotificationType.SUCCESS});
                !exam && navigate(ROUTES.EXAMS + '/' + res.data?.createOrUpdateExam.id);
            })
            .catch(() => notify({type: NotificationType.ERROR}));
    };
    return (
        <ExamContext.Provider value={{exam, refetch}}>
            <CircularLoading loading={!!examId && (loading || !!error)}>
                <Container>
                    <Form form={form} onSubmit={handleSubmit(onSubmit)}>
                        <Stack
                            direction={{xs: 'column', md: 'row'}}
                            mb={2}
                            justifyContent={'space-between'}
                            alignItems={{md: 'center'}}>
                            <Stack
                                direction={{xs: 'column', md: 'row'}}
                                justifyContent={'space-between'}
                                spacing={1}
                                alignItems={{md: 'center'}}>
                                <Typography variant={'h2'}>Аттестация</Typography>
                                {exam?.status && (
                                    <Label color={ExamStatusMap[exam.status].color}>
                                        {ExamStatusMap[exam.status].text}
                                    </Label>
                                )}
                            </Stack>
                            {examId && !!exam?.participantExams.length && (
                                <Stack direction={{xs: 'column', md: 'row'}} spacing={2}>
                                    <LoadingButton
                                        variant={'contained'}
                                        onClick={handleExportDocuments}
                                        loading={loadingExportParticipantExamsDocuments}>
                                        Печать решений
                                    </LoadingButton>
                                    {exam?.type !== ExamType.Physical && (
                                        <>
                                            <LoadingButton
                                                variant={'contained'}
                                                onClick={handleExportExam}
                                                loading={exportExamLoading}>
                                                Выгрузить сведения
                                            </LoadingButton>
                                            <LoadingButton
                                                variant={'contained'}
                                                onClick={handleToggleUploadResultsModal}>
                                                Загрузить результаты
                                            </LoadingButton>
                                        </>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                        <Card>
                            <Stack direction={{xs: 'column', md: 'row'}} justifyContent={'space-between'} mb={1}>
                                <Typography variant={'h3'}>Общая информация</Typography>
                                {hasPermission(PermissionName.EditExams) && (
                                    <LoadingButton
                                        variant={'contained'}
                                        type={'submit'}
                                        loading={loadingCreateOrUpdateExam}>
                                        Сохранить
                                    </LoadingButton>
                                )}
                            </Stack>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={2}>
                                        <FormTextField label={'Номер аттестации'} name={'number'} />
                                        <FormTextField label={'Название аттестации'} name={'title'} />
                                        <FormTextField label={'Площадка'} name={'place'} />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Stack spacing={2}>
                                        <FormDatePicker label={'Дата начала аттестации'} name={'plannedStartDate'} />
                                        <FormDatePicker label={'Дата окончания аттестации'} name={'plannedEndDate'} />
                                        <FormAutocomplete
                                            name={'type'}
                                            options={getEnumArray(ExamTypeMap)}
                                            label={'Тип аттестации'}
                                            disabled={!!examId}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Card>
                    </Form>
                    {!!examId && <Participants />}
                    {openUploadResultsModal && (
                        <UploadResultsModal
                            handleToggle={handleToggleUploadResultsModal}
                            open={openUploadResultsModal}
                        />
                    )}
                </Container>
            </CircularLoading>
        </ExamContext.Provider>
    );
};
