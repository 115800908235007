import {ToastContext} from 'context';
import {useContext} from 'react';

export enum NotificationType {
    ERROR = 'error',
    SUCCESS = 'success'
}

export const useNotify = () => {
    const {setOpen, setToast} = useContext(ToastContext);
    return ({text, type}: {text?: string; type: NotificationType}) => {
        if (!text) {
            switch (type) {
                case NotificationType.SUCCESS: {
                    setToast({text: 'Успешно сохранено', type});
                    break;
                }
                case NotificationType.ERROR: {
                    setToast({text: 'Произошла ошибка, попробуйте еще раз позже', type});
                    break;
                }
            }
        } else {
            setToast({text, type});
        }
        setOpen(true);
    };
};
