import MainLayout from 'layouts/MainLayout';
import {Navigate, RouteObject} from 'react-router';
import {ROUTES} from 'constants/routes';
import {Participants} from 'pages/Participant';
import Login from 'pages/Login';
import {Role, Roles} from 'pages/Role';
import {Categories, Category} from 'pages/Category';
import {Exam, Exams} from 'pages/Exam';
import {PsyTopics} from 'pages/PsyTopic';
import {ZunTopic, ZunTopics} from 'pages/ZunTopic';
import {User, Users} from 'pages/User';
import {UpdateSignatory} from 'pages/Signatory';

export const routes: RouteObject[] = [
    {
        path: 'login',
        element: <Login />
    },
    {
        element: <MainLayout />,
        children: [
            {
                element: <Navigate to={ROUTES.EXAMS} replace />,
                index: true
            },
            {
                path: ROUTES.EXAMS,
                children: [
                    {
                        index: true,
                        element: <Exams />
                    },
                    {
                        path: 'create',
                        element: <Exam />
                    },
                    {
                        path: ':examId',
                        element: <Exam />
                    }
                ]
            },
            {
                path: ROUTES.PARTICIPANTS,
                element: <Participants />
            },
            {
                path: ROUTES.CATEGORIES,
                children: [
                    {
                        index: true,
                        element: <Categories />
                    },
                    {path: ':categoryId', element: <Category />}
                ]
            },
            {
                path: ROUTES.USERS,
                children: [
                    {
                        index: true,
                        element: <Users />
                    },
                    {
                        path: 'create',
                        element: <User />
                    },
                    {
                        path: ':userId',
                        element: <User />
                    }
                ]
            },
            {
                path: ROUTES.ROLES,
                children: [
                    {
                        index: true,
                        element: <Roles />
                    },
                    {
                        path: 'create',
                        element: <Role />
                    },
                    {
                        path: ':roleId',
                        element: <Role />
                    }
                ]
            },
            {
                path: ROUTES.ZUN_TOPICS,
                children: [
                    {
                        index: true,
                        element: <ZunTopics />
                    },
                    {
                        path: ':topicId',
                        element: <ZunTopic />
                    },
                    {
                        path: 'create',
                        element: <ZunTopic />
                    }
                ]
            },
            {
                path: ROUTES.PSY_TOPICS,
                children: [
                    {
                        index: true,
                        element: <PsyTopics />
                    }
                ]
            },
            {
                path: ROUTES.SIGNATORY,
                children: [
                    {
                        index: true,
                        element: <UpdateSignatory />
                    }
                ]
            }
        ]
    }
];
